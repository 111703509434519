import { ErrorHandler as AngularErrorHandler, Injectable, Injector } from '@angular/core';
import { MainService } from '@app/services/main.service';
import { SilentError } from '@app/error-handler/silent-error';
import { ErrorDialogService } from '@app/error-handler/error-dialog.service';
import { Timeout } from '@feathersjs/errors';
import { datadogRum } from '@datadog/browser-rum';

@Injectable({ providedIn: 'root' })
export class CustomErrorHandler implements AngularErrorHandler {
  private err = {
    displayInfo: false,
    displayError: false,
    api: false,
    name: '',
    message: '',
    stack: null,
    status: null,
    statusText: null,
    url: null,
    error: null,
  };

  public constructor(private injector: Injector) {}

  public handleError(error: any): void {
    if (error instanceof SilentError) {
      return;
    }

    const mainService = this.injector.get(MainService);
    mainService.hideLoading();

    const extractedError = this.extractor(error) || 'Handled unknown error';

    if (this.err.displayInfo || this.err.displayError) {
      const dialogService = this.injector.get(ErrorDialogService);

      if (this.err.displayInfo) {
        console.info('APP info:', extractedError);
        dialogService.infoDialog({
          message: this.err.message,
        });
      }

      if (this.err.displayError) {
        console.error('APP error:', extractedError);
        console.error('APP error name:', extractedError.name);
        console.error('APP error message:', extractedError.message);
        datadogRum.addError(extractedError);
        if (this.err.api) {
          dialogService.errorDialog({
            type: 'A',
            status: this.err.status,
            title: `${this.err.statusText} - ${this.err.status}`,
            url: this.err.url,
            message: this.err.message,
            stack: '',
          });
        } else {
          dialogService.errorDialog({
            type: 'A',
            status: this.err.status,
            title: `${this.err.name}`,
            url: '',
            message: this.err.message,
            stack: this.err.stack,
          });
        }
      }
    }
  }

  protected extractor(errorCandidate: any): any {
    this.err = {
      displayInfo: false,
      displayError: false,
      api: false,
      name: '',
      message: '',
      stack: null,
      status: null,
      statusText: null,
      url: null,
      error: null,
    };

    let error = errorCandidate;

    if (error instanceof Timeout) {
      // TODO opravit ignore. halsilo mi to ze timeout, method a path neexistujou na data: unknown
      // @ts-ignore
      const limit = Math.round(error.data?.timeout / 1000);
      // @ts-ignore
      this.err.message = `Server neodpověděl v časovém limitu <strong>${limit}</strong> sekund. Zkuste to prosím znovu.<br><br><div class="text-sm text-gray-400">API ${error.data?.method}:${error.data?.path}</div>`;
      this.err.displayInfo = true;
      return error;
    }

    if (error && (error as { ngOriginalError: Error }).ngOriginalError) {
      error = (error as { ngOriginalError: Error }).ngOriginalError;
    }

    if (error instanceof TypeError) {
      this.err.stack = error.stack ? error.stack : null;
      this.err.name = error.name;
      this.err.message = error.message;
      this.err.displayError = true;
    } else if (error instanceof Error) {
      this.err.stack = error.stack ? error.stack : null;
      this.err.name = error.name;
      this.err.message = error.message ?? this.getFirstLineOfStack(error);
      this.err.displayError = true;
    }

    if (this.err.message?.includes('Timeout:')) {
      this.err.message = 'Server neodpověděl v časovém limitu. Zkuste to prosím znovu.';
      this.err.displayInfo = true;
      this.err.displayError = false;
      return error;
    }

    // Ignore this error: NG0100:ExpressionChangedAfterItHasBeenCheckedError
    if (this.err.message?.includes('NG0100:')) {
      this.err.displayError = false;
      this.err.displayInfo = false;
      return null;
    }

    // This is not error, dont show message
    if ((this.err.message?.includes('NotAuthenticated')) || (error.name === 'NotAuthenticated') || (error.message?.includes('NotAuthenticated'))) {
      this.err.displayError = false;
      this.err.displayInfo = false;
      return null;
    }

    if (typeof error === 'string' || error instanceof Error) {
      return error;
    }

    return null;
  }

  private getFirstLineOfStack(error: Error): string {
    if (error.stack) {
      const stackArray = error.stack.split('\n');
      return stackArray[0];
    }
    return '';
  }
}
