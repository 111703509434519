<div class="timeline">
  <div class="timeline-item filter-item">
    <div class="timeline-left"></div>
    <div class="timeline-right">
      <mat-form-field class="filter">
        <mat-select [(ngModel)]="timelineFilter" (ngModelChange)="filterTimeline()">
          <mat-option *ngFor="let option of timeLineOptions" [value]="option.value" [class]="option.klass">
            {{ option.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <ng-container *ngIf="((timelineItems$ | async) || []) as timeline">

    <ng-container *ngIf="timeline | timelineIsFuture : true as items">
      <ng-container *ngIf="items.length > 0">
        <app-timeline-item [ngClass]="{future: item.isFutureActivity}"
                           *ngFor="let item of items"
                           [item]="item"
                           [deals]="deals"
                           (itemChange)="itemChanged($event)"
                           (viewMessage)="viewMessage($event)">
        </app-timeline-item>

        <div class="timeline-item timeline-item-small">
          <div class="timeline-left">
          </div>
          <div class="timeline-right text-lg font-semibold">
            Budoucí události ↑
          </div>
        </div>

        <mat-divider></mat-divider>

        <div class="timeline-item timeline-item-small">
          <div class="timeline-left">
          </div>
          <div class="timeline-right text-lg font-semibold">
            Již proběhlé události ↓
          </div>
        </div>
      </ng-container>
    </ng-container>

    <app-timeline-item [ngClass]="{future: item.isFutureActivity}"
                       *ngFor="let item of timeline | timelineIsFuture : false"
                       [item]="item"
                       [deals]="deals"
                       (itemChange)="itemChanged($event)"
                       (viewMessage)="viewMessage($event)">
    </app-timeline-item>

  </ng-container>
  <div class="timeline-item first-item">
    <div class="timeline-left">
      <div class="timeline-date">
        <b>{{createdAt | dateTimeline}}</b>
        <span>{{createdAt | date:'shortDate':undefined:'cs-CS'}} {{createdAt | date:'HH:mm:ss'}}</span>
      </div>
      <span class="timeline-icon">
      <mat-icon>add</mat-icon>
    </span>
    </div>
    <div class="timeline-right">
      <div class="timeline-title" *ngIf="contactTimeline">Vytvoření kontaktu</div>
      <div class="timeline-title" *ngIf="!contactTimeline">Vytvoření případu</div>
    </div>
  </div>
</div>

<app-message-preview [(visible)]="previewVisible" [message]="previewMessage"></app-message-preview>
