<div class="deals-content">
  <div class="card-title">
    <span>Hypotéky <button mat-icon-button [routerLink]="'/deals/list'"><mat-icon>table_rows</mat-icon></button> <button mat-icon-button [routerLink]="'/deals/board'"><mat-icon>view_column</mat-icon></button></span>
    <div>
      <button mat-raised-button color="accent" title="Nová hypotéka" (click)="dealsService.newDeal()" *ngIf="['admin', 'specialist', 'support'].includes(usersService.user.role)"><span>Nová hypotéka</span><mat-icon>add</mat-icon></button>
    </div>
  </div>
    <div class="card card-list">
      <table class="list">
        <thead>
        <tr class="header">
          <!--            <th class="fitcontent"><span (click)="dealsService.sort('numberId')"><span>Případ</span><mat-icon *ngIf="!dealsService.isSorting('numberId')"></mat-icon><mat-icon *ngIf="dealsService.isSorting('numberId', 1)">arrow_drop_down</mat-icon><mat-icon *ngIf="dealsService.isSorting('numberId', -1)">arrow_drop_up</mat-icon></span></th>-->
          <th class="fitcontent"><span>Případ</span></th>
          <th class="dynamic"><span>Název</span></th>
          <th class="fitcontent"><span>Částka</span></th>
          <th class="fitcontent"><span>Stav</span></th>
          <th class="fitcontent"><span>Zdroj</span></th>
          <th class="fitcontent"><span>Vlastník</span></th>
          <th class="fitcontent"><span>Vytvořeno</span></th>
          <th class="fitcontent"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let deal of dealsService.items$ | async;trackBy: dealsService.trackByFn;" [ngClass]="{'activated': deal._isActivated}">
          <td><a class="nowrap-container" [routerLink]="['/deals', deal._id,'offers']"><ng-container [ngTemplateOutlet]="priorityTmpl" [ngTemplateOutletContext]="deal"></ng-container>{{ deal | caseNumber }}</a></td>
          <td><span class="nowrap-container"><span>{{ deal.name }}</span> <mat-icon class="list-icon isAfter" *ngIf="deal._isDuplicity">theater_comedy</mat-icon> <mat-icon class="list-icon isAfter" *ngIf="deal.isWatchdog">alarm</mat-icon></span></td>
          <td><span class="nowrap-container">{{ mainService.maskCurrency(deal.amount) }}</span></td>
          <td><span class="nowrap-container">{{ settingsService.settings.stages[deal.stage] }} <span *ngIf="deal.stage === 'postponed'">({{ deal.postponeAlert || 0 }})</span></span></td>
          <td><span class="nowrap-container" *ngIf="deal.affiliateId">{{ affiliatesService.items$ | affiliateLabel:deal.affiliateId | async }}</span></td>
          <td><span class="nowrap-container" *ngIf="deal.ownerId && (deal.ownerId | getOwner | async) as user">{{ user.firstName }} {{ user.lastName }}</span></td>
          <td>{{ deal.createdAt | date:'shortDate':undefined:'cs-CS' }}</td>
          <td class="menu"><button mat-icon-button [matMenuTriggerFor]="dealMenu" [matMenuTriggerData]="{deal: deal}"><mat-icon>more_vert</mat-icon></button></td>
        </tr>
        <tr *ngIf="!(dealsService.isLoading$ | async) && !(dealsService.items$ | async).length"><td colspan="7">Pro zadané parametry mi to nic nenašlo.</td><td></td></tr>
        </tbody>
      </table>
      <div class="list-load-more" *ngIf="(dealsService.isLoading$ | async) || (dealsService.canLoadMore$ | async)">
        <button mat-button color="primary" (click)="dealsService.load()" [disabled]="dealsService.isLoading$ | async">
          <mat-spinner *ngIf="dealsService.isLoading$ | async" [diameter]="30"></mat-spinner>
          <span *ngIf="!(dealsService.isLoading$ | async)">Načíst další</span>
        </button>
      </div>
    </div>
</div>
<div class="deals-filter">
  <form>
    <div class="card-title">
      <span>Filtrace</span>
      <div>
        <button mat-button color="warn" (click)="defaultFilters()" type="button"><mat-icon>settings_backup_restore</mat-icon></button>
        <button mat-icon-button type="button" *ngIf="['admin'].includes(usersService.user.role)" [matMenuTriggerFor]="casesMenu"><mat-icon>more_vert</mat-icon></button>
      </div>
    </div>
    <div class="form-row-label">Vytvořeno</div>
    <div class="form-row">
      <mat-form-field>
        <input matInput placeholder="Od" [matDatepicker]="createdAtDateFrom" [(ngModel)]="filters.createdAtFrom" (ngModelChange)="filter()" name="createdAtFrom" />
        <mat-datepicker-toggle matSuffix [for]="createdAtDateFrom"></mat-datepicker-toggle>
        <mat-datepicker #createdAtDateFrom></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Do" [matDatepicker]="createdAtDateTo" [(ngModel)]="filters.createdAtTo" (ngModelChange)="filter()" name="createdAtTo" />
        <mat-datepicker-toggle matSuffix [for]="createdAtDateTo"></mat-datepicker-toggle>
        <mat-datepicker #createdAtDateTo></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="form-row">
      <mat-form-field>
        <input matInput placeholder="Hledaný výraz" type="text" [(ngModel)]="filters.search" name="search" (ngModelChange)="onSearchChanged($event)">
      </mat-form-field>
    </div>
    <div class="form-row-label">Výše úvěru</div>
    <div class="form-row">
      <mat-form-field>
        <input matInput placeholder="Od" [(ngModel)]="filters.amountMin" name="amountMin" (ngModelChange)="onSearchChanged($event)" />
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Do" [(ngModel)]="filters.amountMax" name="amountMax" (ngModelChange)="onSearchChanged($event)" />
      </mat-form-field>
    </div>
    <div class="form-row">
      <mat-form-field>
        <mat-chip-list #chipListStage>
          <mat-chip *ngFor="let chip of filters.stage || []" [selectable]="false" (removed)="filterChipRemoved(chip, 'stage')" [value]="chip" color="default">{{ mainService.optionLabel(stages, chip) }}<mat-icon matChipRemove>cancel</mat-icon></mat-chip>
        </mat-chip-list>
        <input
          #chipInputStage
          placeholder="Stav případu"
          [matChipInputSeparatorKeyCodes]="mainService.separatorKeysCodes"
          [matChipInputFor]="chipListStage"
          [matAutocomplete]="matAutoChipStage"
          [matChipInputAddOnBlur]="false"
        />
        <mat-autocomplete #matAutoChipStage="matAutocomplete" (optionSelected)="filterChipSelected($event, 'stage');chipInputStage.value = ''">
          <mat-option *ngFor="let item of filterChipItems(stages, chipInputStage.value, 'stage')" [value]="item.value">
            {{ item.label }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="form-row">
      <mat-form-field>
        <mat-chip-list #chipListPostpone>
          <mat-chip *ngFor="let chip of filters.postponeReason || []" [selectable]="false" (removed)="filterChipRemoved(chip, 'postponeReason')" [value]="chip" color="default">{{ mainService.optionLabel(settingsService.options['postponeReason'], chip) }}<mat-icon matChipRemove>cancel</mat-icon></mat-chip>
        </mat-chip-list>
        <input
          #chipInputPostpone
          placeholder="Důvod odložena"
          [matChipInputSeparatorKeyCodes]="mainService.separatorKeysCodes"
          [matChipInputFor]="chipListPostpone"
          [matAutocomplete]="matAutoChipPostpone"
          [matChipInputAddOnBlur]="false"
        />
        <mat-autocomplete #matAutoChipPostpone="matAutocomplete" (optionSelected)="filterChipSelected($event, 'postponeReason');chipInputPostpone.value = ''">
          <mat-option *ngFor="let item of filterChipItems(settingsService.options['postponeReason'], chipInputPostpone.value, 'postponeReason')" [value]="item.value">
            {{ item.label }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="form-row-label">Odloženo do</div>
    <div class="form-row">
      <mat-form-field>
        <input matInput placeholder="Od" [matDatepicker]="postponedUntilDateFrom" [(ngModel)]="filters.postponedUntilFrom" (ngModelChange)="filter()" name="postponedUntilFrom" />
        <mat-datepicker-toggle matSuffix [for]="postponedUntilDateFrom"></mat-datepicker-toggle>
        <mat-datepicker #postponedUntilDateFrom></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Do" [matDatepicker]="postponedUntilDateTo" [(ngModel)]="filters.postponedUntilTo" (ngModelChange)="filter()" name="postponedUntilTo" />
        <mat-datepicker-toggle matSuffix [for]="postponedUntilDateTo"></mat-datepicker-toggle>
        <mat-datepicker #postponedUntilDateTo></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="form-row-label">Datum podpisu</div>
    <div class="form-row">
      <mat-form-field>
        <input matInput placeholder="Od" [matDatepicker]="signatureDateFrom" [(ngModel)]="filters.signedAtFrom" (ngModelChange)="filter()" name="signedAtFrom" />
        <mat-datepicker-toggle matSuffix [for]="signatureDateFrom"></mat-datepicker-toggle>
        <mat-datepicker #signatureDateFrom></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Do" [matDatepicker]="signatureDateTo" [(ngModel)]="filters.signedAtTo" (ngModelChange)="filter()" name="signedAtTo" />
        <mat-datepicker-toggle matSuffix [for]="signatureDateTo"></mat-datepicker-toggle>
        <mat-datepicker #signatureDateTo></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="form-row" *ngIf="usersService.isPrivileged('deals/all')">
      <mat-form-field>
        <mat-chip-list #chipListOwner>
          <mat-chip *ngFor="let chip of filters.ownerId || []" [selectable]="false" (removed)="filterChipRemoved(chip, 'ownerId')" [value]="chip" color="default">{{ mainService.optionLabel(owners, chip) }}<mat-icon matChipRemove>cancel</mat-icon></mat-chip>
        </mat-chip-list>
        <input
          #chipInputOwner
          placeholder="Vlastník"
          [matChipInputSeparatorKeyCodes]="mainService.separatorKeysCodes"
          [matChipInputFor]="chipListOwner"
          [matAutocomplete]="matAutoChipOwner"
          [matChipInputAddOnBlur]="false"
        />
        <mat-autocomplete #matAutoChipOwner="matAutocomplete" (optionSelected)="filterChipSelected($event, 'ownerId');chipInputOwner.value = ''">
          <mat-option *ngFor="let item of filterChipItems(owners, chipInputOwner.value, 'ownerId')" [value]="item.value">
            {{ item.label }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="form-row" *ngIf="usersService.isPrivileged('deals/all')">
      <mat-form-field>
        <mat-chip-list #chipListAffiliate>
          <mat-chip *ngFor="let chip of filters.affiliateId || []" [selectable]="false" (removed)="filterChipRemoved(chip, 'affiliateId')" [value]="chip" color="default">{{ mainService.optionLabel(affiliates, chip) }}<mat-icon matChipRemove>cancel</mat-icon></mat-chip>
        </mat-chip-list>
        <input
          #chipInputAffiliate
          placeholder="Zdroj"
          [matChipInputSeparatorKeyCodes]="mainService.separatorKeysCodes"
          [matChipInputFor]="chipListAffiliate"
          [matAutocomplete]="matAutoChipAffiliate"
          [matChipInputAddOnBlur]="false"
        />
        <mat-autocomplete #matAutoChipAffiliate="matAutocomplete" (optionSelected)="filterChipSelected($event, 'affiliateId');chipInputAffiliate.value = ''">
          <mat-option *ngFor="let item of filterChipItems(affiliates, chipInputAffiliate.value, 'affiliateId')" [value]="item.value">
            {{ item.label }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </form>
</div>
<mat-menu #dealMenu="matMenu">
  <ng-template matMenuContent let-deal="deal">
    <button mat-menu-item (click)="bankingService.openBanking(deal)" *ngIf="usersService.isPrivileged('banking:get')"><mat-icon>visibility</mat-icon>Zobrazit bankovnictví</button>
    <app-dial-button [menuItem]="true" [collectionId]="deal._id" [collectionName]="'deals'" [phoneNumber]="deal.phones?.[0]?.number" [countryCode]="deal.phones?.[0]?.countryCode" [contactName]="deal.name" (clickDial)="dialPhone($event, deal)"></app-dial-button>
    <button mat-menu-item (click)="postponeDeal(deal)" *ngIf="usersService.isPrivileged('deals/postpone:patch')"><mat-icon>pause_circle_outline</mat-icon>Odložit případ</button>
    <button mat-menu-item (click)="changePriority(deal)" *ngIf="usersService.isPrivileged('deals/change-priority:patch')"><mat-icon>height</mat-icon>Změnit prioritu</button>
    <button mat-menu-item (click)="changeDrawer(deal)" *ngIf="usersService.isPrivileged('deals/change-drawer:patch') || deal.ownerId === this.usersService.user._id"><mat-icon>forward</mat-icon>Změnit čerpatele</button>
    <button mat-menu-item (click)="watchdog(deal)"><mat-icon>alarm</mat-icon>Hlídač hypotéky</button>
    <button mat-menu-item (click)="dealsService.changeOwner([deal])" *ngIf="usersService.isPrivileged('deals/change-owner:patch') || deal.ownerId === this.usersService.user._id"><mat-icon>forward</mat-icon>Změnit vlastníka</button>
    <button mat-menu-item (click)="cancelDeal(deal)" *ngIf="usersService.isPrivileged('deals/cancel:patch') && deal.stage !== 'canceled'"><mat-icon>delete</mat-icon>Zamítnout případ</button>
    <button mat-menu-item (click)="restoreDeal(deal)" *ngIf="usersService.isPrivileged('deals/restore:patch') && ['canceled','postponed'].includes(deal.stage)"><mat-icon>refresh</mat-icon>Obnovit případ</button>
    <a *ngIf="deal.hubspot && deal.hubspot.dealId" mat-menu-item target="_blank" [href]="'https://app.hubspot.com/contacts/3999209/deal/' + deal.hubspot.dealId"><mat-icon>call_made</mat-icon>Hubspot</a>
  </ng-template>
</mat-menu>
<ng-template #priorityTmpl let-priority="priority">
  <mat-icon *ngIf="priority === 2" color="warn" class="priority" svgIcon="priority-highest"></mat-icon>
  <mat-icon *ngIf="priority === 1" color="warn" class="priority" svgIcon="priority-high"></mat-icon>
  <mat-icon *ngIf="priority === 0" color="primary" class="priority" svgIcon="priority-medium"></mat-icon>
  <mat-icon *ngIf="priority === -1" color="accent" class="priority" svgIcon="priority-low"></mat-icon>
  <mat-icon *ngIf="priority === -2" color="accent" class="priority" svgIcon="priority-lowest"></mat-icon>
</ng-template>
<mat-menu #casesMenu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item (click)="changeOwners()"><mat-icon color="primary">manage_accounts</mat-icon>
      <span>Hromadná změna vlastníka</span>
    </button>
  </ng-template>
</mat-menu>
