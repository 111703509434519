import { Injectable } from '@angular/core';
import { DataService } from './data.service';
@Injectable({providedIn: 'root'})
export class TipsterCommisionService extends DataService<any> {
  constructor(
  ) {
    super('deals/tipster');
    this.query = {
    };
  }
  public async getCommision(pipelineUrl: string, sourceType: string, tipsterRole: string): Promise<number> {
    const commission = await this.patch('0', { pipelineUrl, sourceType, tipsterRole });
    if (commission && commission.commission) {
      return commission.commission;
    } else {
      return 0;
    }
  }
}
