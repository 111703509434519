<h2 mat-dialog-title><span>Nové pojištění</span></h2>
<mat-dialog-content>
  <form #newDealForm="ngForm">
    <ng-container *ngIf="!copyContactsId[0]">
      <div class="form-row">
        <mat-form-field>
          <mat-select placeholder="Předvolba"
                      [(ngModel)]="demand.contacts.phoneCountryCode"
                      (ngModelChange)="filterContacts(newDealForm.control)"
                      name="contactPhoneCountryCode">
            <mat-option *ngFor="let option of options['phoneCountryCode']" [value]="option.value">{{ option.label }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <input matInput type="number"
                 placeholder="Telefon"
                 [(ngModel)]="demand.contacts.phoneNumber"
                 (ngModelChange)="filterContacts(newDealForm.control)"
                 name="contactPhoneNumber" required/>
        </mat-form-field>
      </div>
      <div class="form-row">
        <mat-form-field>
          <input matInput placeholder="Email"
                 [(ngModel)]="demand.contacts.email"
                 (ngModelChange)="filterContacts(newDealForm.control)"
                 name="contactEmail" email/>
        </mat-form-field>
      </div>
      <div class="form-row" *ngIf="(filteredContacts | async).length && !contactSelectionDone">
        <mat-form-field>
          <mat-select placeholder="Existující kontakty"
                      [(ngModel)]="selectedContact"
                      name="existingContacts">
            <ng-container *ngFor="let contact of filteredContacts | async">
              <mat-option [value]="contact._id">
                <div class="flex flex-col">
                  <span style="line-height: 1.5em;">{{contact.label}}&nbsp;</span>
                  <small style="line-height: 1.5em;">{{contact.sublabel}}</small>
                </div>
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>
    <ng-container *ngIf="copyContactsId[0] || contactSelectionDone">
      <div class="form-row" *ngIf="!copyContactsId[0]">
        <mat-form-field>
          <input matInput placeholder="Jméno" [(ngModel)]="demand.contacts.name" name="contactName" required/>
        </mat-form-field>
        <mat-form-field>
          <app-input-mask placeholder="PSČ"
                          [(ngModel)]="demand.contacts.zipCode"
                          name="contactZipCode" mask="999 99" inputmode="numeric">
          </app-input-mask>
        </mat-form-field>
      </div>
      <div class="form-row">
        <mat-form-field>
          <mat-select placeholder="Vlastník případu"
                      [(ngModel)]="demand.ownerId"
                      name="ownerId">
            <mat-option [value]="''">Přiřadit automaticky</mat-option>
            <mat-option [value]="owner._id" *ngFor="let owner of pipelineOwners">{{ owner.firstName }} {{ owner.lastName }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-select placeholder="Typ pojištění" [(ngModel)]="demand.pipelineId" name="pipelineId" required>
            <mat-option [value]="pipeline._id" *ngFor="let pipeline of pipelines | pipelineProduct : 'insurance'">{{ pipeline.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-row">
        <mat-form-field class="w50">
          <mat-select placeholder="Priorita"
                      [(ngModel)]="demand.priority"
                      name="priority">
            <mat-select-trigger style="display: flex; align-items: center;">
              <ng-container [ngTemplateOutlet]="priorityTmpl" [ngTemplateOutletContext]="{priority: demand.priority}"></ng-container>
              <span>{{ priorityText(demand.priority) }}</span>
            </mat-select-trigger>
            <ng-container *ngFor="let option of priorities">
              <mat-option [value]="option.value"><ng-container [ngTemplateOutlet]="priorityTmpl" [ngTemplateOutletContext]="{priority: option.value}"></ng-container>{{ option.label }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="w50" *ngIf="demand.pipelineId === pipelineLifeInsurance || demand.pipelineId === pipelineRealEstateInsurance">
          <mat-select placeholder="Povinná pojišťovna"
                      [(ngModel)]="demand.providerId"
                      name="provider">
            <mat-option [value]="''">Žádná</mat-option>
            <mat-option *ngFor="let provider of (providers | async)" [value]="provider._id">{{provider.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-row">
        <mat-form-field>
          <mat-select placeholder="Původ obchodu"
                      [(ngModel)]="demand.sourceType"
                      (selectionChange)="sourceTypeChanged($event)"
                      name="sourceType">
            <mat-option [value]="'hnm'">Firemní klient</mat-option>
            <mat-option [value]="'hnmrecommendation'">Doporučení od firemního klienta</mat-option>
            <mat-option [value]="'affiliate'">Affiliate partner</mat-option>
            <mat-option [value]="'personal'">Osobní</mat-option>
            <mat-option [value]="'employee'">Hypo zaměstnanec</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="demand.sourceType === 'affiliate'">
          <mat-select placeholder="Partner" [(ngModel)]="demand.affiliateId" name="affiliateId" required>
            <mat-option [value]="''">Není vybrán</mat-option>
            <mat-option [value]="affiliate._id" *ngFor="let affiliate of affiliatesService.items$ | async">{{ affiliate.name }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="['hnmrecommendation', 'personal', 'employee'].includes(demand.sourceType)">
          <mat-select placeholder="Tipař" [(ngModel)]="demand.tipsterId" name="tipsterId" (selectionChange)="tipsterChanged($event)" required>
            <mat-option [value]="tipster._id" *ngFor="let tipster of tipsters">{{ tipster.firstName }} {{ tipster.lastName }}</mat-option>
          </mat-select>
<!--          <input matInput placeholder="Email tipaře"-->
<!--                 [(ngModel)]="demand.authorEmail"-->
<!--                 [matAutocomplete]="autoUser"-->
<!--                 (ngModelChange)="filterUsers($event)"-->
<!--                 name="tipsterEmail" required/>-->
<!--          <mat-autocomplete #autoUser="matAutocomplete" panelWidth="auto">-->
<!--            <mat-option *ngFor="let user of filteredUsers | async" [value]="user.email" (onSelectionChange)="tipsterChanged(user)">-->
<!--              {{ user.email }}-->
<!--            </mat-option>-->
<!--          </mat-autocomplete>-->
        </mat-form-field>
      </div>
      <div *ngIf="demand.tipsterCommission" class="flex flex-row justify-end gap-2 pb-3 text-sm">
        <div>Tipařská provize</div>
        <div class="font-semibold">{{demand.tipsterCommission | number}}%</div>
      </div>
      <div class="form-row">
        <mat-form-field>
          <textarea placeholder="Poznámka"
                    matInput cdkTextareaAutosize cdkAutosizeMinRows="1"
                    [(ngModel)]="demand.note"
                    name="note">
          </textarea>
        </mat-form-field>
      </div>
    </ng-container>
    <pre *ngIf="false">{{ demand | json }}</pre>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button type="button" mat-button color="warn" (click)="dialogRef.close(null)">Zrušit</button>
  <ng-container *ngIf="!copyContactsId[0] && !contactSelectionDone">
    <button type="button" mat-raised-button color="accent" (click)="selectContact(true)" [disabled]="!selectedContact">Použít kontakt</button>
    <button type="button" mat-raised-button color="accent" (click)="selectContact(false)" [disabled]="newDealForm.control.get('contactPhoneNumber')?.invalid">Nový kontakt</button>
  </ng-container>
  <ng-container *ngIf="copyContactsId[0] || contactSelectionDone">
    <button type="button" mat-raised-button color="primary" (click)="backToContactSelection()">Zpět</button>
    <button type="button" mat-raised-button color="accent" (click)="confirmDialog()" [disabled]="newDealForm.invalid">Vytvořit</button>
  </ng-container>
</mat-dialog-actions>
<ng-template #priorityTmpl let-priority="priority">
  <mat-icon *ngIf="priority === 2" color="warn" class="priority" svgIcon="priority-highest"></mat-icon>
  <mat-icon *ngIf="priority === 1" color="warn" class="priority" svgIcon="priority-high"></mat-icon>
  <mat-icon *ngIf="priority === 0" color="primary" class="priority" svgIcon="priority-medium"></mat-icon>
  <mat-icon *ngIf="priority === -1" color="accent" class="priority" svgIcon="priority-low"></mat-icon>
  <mat-icon *ngIf="priority === -2" color="accent" class="priority" svgIcon="priority-lowest"></mat-icon>
</ng-template>
