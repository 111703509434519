<div class="search-bar flex flex-col border-solid border-b border-gray-300">
  <div class="flex pr-4 h-12 flex-grow justify-center items-center">
    <input placeholder="Hledání..." [attr.autocomplete]="'off'" name="search" [(ngModel)]="filters.search" (ngModelChange)="onSearchChanged($event)">
    <button mat-icon-button
            matTooltip="Hledat v ověřených kontaktech na klienty"
            [color]="filters.scope === 'contacts' ? 'primary' : null"
            (click)="onScopeChanged('contacts')">
      <mat-icon>contacts</mat-icon>
    </button>
    <button mat-icon-button
            matTooltip="Hledat kontaktech na banky, pojišťovny"
            [color]="filters.scope === 'providers' ? 'primary' : null"
            (click)="onScopeChanged('providers')">
      <mat-icon>account_balance</mat-icon>
    </button>
    <button mat-icon-button
            matTooltip="Hledat v Hyponamíru"
            [color]="filters.scope === 'employees' ? 'primary' : null"
            (click)="onScopeChanged('employees')">
      <mat-icon>group</mat-icon>
    </button>
    <span class="mx-2 h-4 border-solid border-r border-r-slate-300">&nbsp;</span>
    <button mat-icon-button matTooltip="Smazat podmínky hledání" (click)="clearSearch()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div *ngIf="parsedPhoneNumber && !(contactsService.$isLoading | async)"
       class="flex justify-items-center items-center pr-4 h-12 justify-between">
    <div class="foundPhone text-black bg-green-100 rounded p-2 m-2" [ngClass]="{warning: parsedPhoneNumber.warning}">
      {{(parsedPhoneNumber.countryCode + parsedPhoneNumber.phoneNumber) | phoneNumber}}
    </div>
    <app-dial-button [widget]="true" [compact]="true" (clickDial)="dialPhone($event)" [countryCode]="parsedPhoneNumber.countryCode" [phoneNumber]="parsedPhoneNumber.phoneNumber"></app-dial-button>
  </div>
</div>
<mat-list>
  <cdk-virtual-scroll-viewport #viewport itemSize="48" class="virtual-scroll-container" minBufferPx="800" maxBufferPx="1000">
    <mat-list-item *cdkVirtualFor="let contact of contactsService.items$">
      <div class="flex flex-row items-center justify-between w-full leading-none">
        <div class="flex flex-col leading-none">
          <div class="font-semibold">{{ contact.firstName }} {{ contact.lastName }}</div>
          <div>{{(contact.phone[0].countryCode + contact.phone[0].number) | phoneNumber}}</div>
          <div *ngIf="contact._provider" class="text-sm opacity-75">
            <mat-icon class="provider-icon">account_balance</mat-icon>{{ contact._provider.name }}
          </div>
        </div>
        <div>
          <app-dial-button [widget]="true"
                           [compact]="true"
                           [phoneNumber]="contact.phone[0].number"
                           [countryCode]="contact.phone[0].countryCode"
                           [contactId]="contact._id"
                           (clickDial)="dialPhone($event)">
          </app-dial-button>
        </div>
      </div>
    </mat-list-item>

    <mat-list-item *ngIf="!(contactsService.items$ | async).length && !(contactsService.isLoading$ | async)">
      <div class="flex flex-col">
        <div>
          Pro zadaný výraz nebylo nic nalezeno.
        </div>
        <div *ngIf="filters.scope !== 'employees'">
          Nechtěl jsi hledat kolegu z Hyponamíru?
        </div>
      </div>
    </mat-list-item>

    <div class="flex justify-center items-center h-full p-4" *ngIf="!(contactsService.items$ | async).length && (contactsService.isLoading$ | async)">
      <mat-spinner></mat-spinner>
    </div>

    <app-infinite-scroll *ngIf="(contactsService.items$ | async).length" (endReached)="endReached()"></app-infinite-scroll>
  </cdk-virtual-scroll-viewport>
</mat-list>
