<div class="documents" [ngClass]="{hidden: selectingTemplates}">
  <div class="documents-wrap" [formGroup]="offersService.documentsForm">

    <!-- DEAL RELATED DOCUMENTS -->
    <ng-container *ngFor="let group of groups">
      <div class="documents-group">
        <div class="card-title">
          <span class="documents-title">{{ group }}</span>
          <div>
            <button mat-icon-button
                    color="primary"
                    matTooltip="Přidat dokumenty"
                    (click)="selectTemplates(group, false, true)">
              <mat-icon>add</mat-icon>
            </button>

            <button mat-icon-button
                    color="primary"
                    *ngIf="group !== 'Úvěrová dokumentace' && usersService.isPrivileged('documents:get')"
                    [disabled]="offersService.offerForm.dirty || !offersService.hasDocuments(offer, group)"
                    (click)="offersService.getDocuments(deal, offer, group)"
                    matTooltip="Stáhnout dokumenty">
              <mat-icon>cloud_download</mat-icon>
            </button>

            <button mat-icon-button
                    color="primary"
                    *ngIf="group === 'Úvěrová dokumentace'"
                    [matMenuTriggerFor]="groupMenu"
                    [matMenuTriggerData]="{group: group}">
              <mat-icon>more_vert</mat-icon>
            </button>
          </div>
        </div>
        <ng-container *ngFor="let document of offersService.documents.controls | documentsOrder:group:offer;let dIndex = index" formArrayName="documents">
          <mat-accordion *ngIf="document.get('_template').value && document.get('_template').value.group === group" [formGroupName]="dIndex">
              <mat-expansion-panel [ngClass]="{blue: document.get('_template').value?.isDeliveredByUs}">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="rows">
                      <div class="elipsis">{{ document.get('_template').value.name }} ({{ document.get('files').value.length }})</div>
                      <div *ngIf="document.get('_template').value.isApplicantRelated || document.get('_template').value.isDrawdownRelated || document.get('_template').value.isProviderRelated">
                        <div *ngIf="document.get('_template').value.isApplicantRelated"><span *ngIf="document.get('type').value">{{ document.get('type').value }}&nbsp;-&nbsp;</span>Žadatel {{ applicantIndex(document.get('contactId').value) + 1 }}</div>
                        <div *ngIf="document.get('_template').value.isDrawdownRelated"><span *ngIf="document.get('type').value">{{ document.get('type').value }}&nbsp;-&nbsp;</span>Čerpání {{ document.get('drawdownIndex').value + 1 }}</div>
                        <div *ngIf="document.get('_template').value.isProviderRelated"><span *ngIf="document.get('type').value">{{ document.get('type').value }}&nbsp;-&nbsp;</span>{{ providersService.getById(document.get('providerId').value)?.shortName }}</div>
                        <div *ngIf="!document.get('_template').value.isDrawdownRelated && !document.get('_template').value.isProviderRelated && !document.get('_template').value.isApplicantRelated && document.get('type').value">{{ document.get('type').value }}</div>
                      </div>
                    </div>
                    <span class="status" [ngClass]="document.get('status').value">
                      <span *ngIf="document.get('isHidden').value"><mat-icon color="warn">visibility_off</mat-icon>&nbsp;</span>
                      {{ mainService.optionLabel(document.get('_template').value?.statuses, document.get('status').value, true) }}
                      <button mat-icon-button color="warn" (click)="$event.stopPropagation();removeDocument(document.get('_index').value);">
                        <mat-icon>remove_circle_outline</mat-icon>
                      </button>
                    </span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                  <div class="form-section">
                    <div class="form-section-title">Nastavení</div>
                    <div class="form-section-content">
                      <div class="form-row">
                        <mat-form-field class="w50">
                          <input matInput placeholder="Doručit do" [matDatepicker]="deliverUntilDate" formControlName="deliverUntil" />
                          <mat-datepicker-toggle matSuffix [for]="deliverUntilDate"></mat-datepicker-toggle>
                          <mat-datepicker #deliverUntilDate></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field class="w50">
                          <input matInput placeholder="Doručeno" [matDatepicker]="deliveredAtDate" formControlName="deliveredAt" />
                          <mat-datepicker-toggle matSuffix [for]="deliveredAtDate"></mat-datepicker-toggle>
                          <mat-datepicker #deliveredAtDate></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field class="w50">
                          <input matInput placeholder="Podepsat do" [matDatepicker]="signatureUntilDate" formControlName="signatureUntil" />
                          <mat-datepicker-toggle matSuffix [for]="signatureUntilDate"></mat-datepicker-toggle>
                          <mat-datepicker #signatureUntilDate></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field class="w50">
                          <input matInput placeholder="Podepsáno" [matDatepicker]="signedAtDate" formControlName="signedAt" />
                          <mat-datepicker-toggle matSuffix [for]="signedAtDate"></mat-datepicker-toggle>
                          <mat-datepicker #signedAtDate></mat-datepicker>
                        </mat-form-field>
                      </div>
                      <div class="form-row">
                        <mat-form-field>
                          <textarea placeholder="Interní poznámka" matInput cdkTextareaAutosize formControlName="note"></textarea>
                        </mat-form-field>
                        <mat-form-field class="w50">
                          <mat-select placeholder="Stav" formControlName="status" >
                            <mat-option *ngFor="let option of (document.get('_template').value?.statuses)" [value]="option.value">{{ option.internalLabel }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-slide-toggle class="w30" color="warn" formControlName="isHidden">Skrytý</mat-slide-toggle>
                      </div>
                    </div>
                  </div>

                  <div class="form-section-content">
                    <app-file-list [documentControl]="document"
                                   [refresh]="refresh"
                                   class="w-full"
                                   (selectFiles)="changeFiles($event)"
                                   (moveFiles)="moveFiles($event)"
                                   (refreshFiles)="refreshFiles($event)">
                    </app-file-list>
                  </div>

                </ng-template>
              </mat-expansion-panel>
          </mat-accordion>
        </ng-container>
      </div>
    </ng-container>

    <!-- INTERNAL DOCUMENTS -->
    <div class="flex flex-row justify-between items-center">
      <span class="page-title text-blue">Interní dokumenty</span>
      <div>
        <button mat-icon-button (click)="selectTemplates(null, true, true)" color="primary"><mat-icon>add</mat-icon></button>
        <!--button mat-icon-button color="primary" [matMenuTriggerFor]="groupMenu" [matMenuTriggerData]="{group: group}"><mat-icon>more_vert</mat-icon></button-->
      </div>
    </div>
    <div class="text-blue">Tyto dokumenty jsou pouze pro interní použití a nezobrazují se klientovi.</div>

    <ng-container *ngFor="let document of offersService.documents.controls| documentsOrder:'internal':offer; let dIndex = index" formArrayName="documents">
      <ng-container *ngIf="document.get('isInternal')?.value === true" [formGroupName]="dIndex">
        <app-document-item [document]="document"
                           [documentIndex]="dIndex"
                           [refresh]="refresh"
                           [isInternal]="true"
                           [isMortgage]="true"
                           (removeDocument)="removeDocument($event)"
                           (moveFiles)="moveFiles($event)"
                           (changeFiles)="changeFiles($event)"
                           (refreshFiles)="refreshFiles($event)">
        </app-document-item>
      </ng-container>
    </ng-container>
  </div>
</div>


<!-- DOCUMENTS TEMPLATES SELECT -->
<div class="documents-selection" *ngIf="selectingTemplates">
  <div class="card-title">
    <span>{{ !filter.isInternal ? selectingDocumentsGroup : 'Interní dokumenty' }}</span>
    <div>
      <button mat-raised-button color="accent" (click)="addDocuments()"><span>Vložit vybrané dokumenty</span><mat-icon>add</mat-icon></button>
      <button class="close" mat-icon-button color="warn" title="Zavřít" (click)="selectTemplates(null, false, false)"><mat-icon>close</mat-icon></button>
    </div>
  </div>
  <div class="card card-list">
    <table class="list">
      <thead>
      <tr class="header" *ngIf="!filter.isInternal">
        <th class="dynamic"><span><span>Název</span><mat-icon></mat-icon></span></th>
        <th class="dynamic"><span><span>Poskytovatel</span><mat-icon></mat-icon></span></th>
        <th class="dynamic"><span><span>Typ</span><mat-icon></mat-icon></span></th>
        <th class="fitcontent"></th>
      </tr>
      <tr class="filter" *ngIf="!filter.isInternal">
        <td>
          <mat-form-field>
            <input matInput autocomplete="off" [(ngModel)]="filter.name" (ngModelChange)="filterTemplates()"/>
            <button mat-button matSuffix mat-icon-button (click)="filter.name = null;filterTemplates();"><mat-icon>close</mat-icon></button>
          </mat-form-field>
        </td>
        <td>
          <mat-form-field>
            <mat-select [(ngModel)]="filter.providerId" (ngModelChange)="filterTemplates()">
              <mat-option [value]="null"></mat-option>
              <mat-option *ngFor="let provider of providersService.items$ | async" [value]="provider._id">{{ provider.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
        <td>
          <mat-form-field>
            <mat-select [(ngModel)]="filter.type" (ngModelChange)="filterTemplates()">
              <mat-option [value]="null"></mat-option>
              <mat-option *ngFor="let type of selectingDocumentsGroupTypes" [value]="type">{{ type }}</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
        <td>
          <mat-form-field>
            <input matInput autocomplete="off" disabled />
          </mat-form-field>
        </td>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let template of templates$ | async;trackBy: trackByFn;">
        <tr [ngClass]="{checked: selectedTemplate(template._id).templateId}" (click)="selectTemplate(template)">
          <td class="filter-padding" colspan="3"><span class="overflow-container"><span class="overflow-elipsis">{{ template.name }}</span></span></td>
          <td class="toggle-buttons">
            <div>
              <mat-button-toggle-group [(ngModel)]="selectedTemplate(template._id).providers" multiple color="primary" *ngIf="template.isProviderRelated">
                <mat-button-toggle [disabled]="!selectedTemplate(template._id).templateId" [value]="provider._id" *ngFor="let provider of confirmedProviders()" (click)="$event.stopPropagation()">{{ provider.stringCode }}</mat-button-toggle>
              </mat-button-toggle-group>
              <mat-button-toggle-group [(ngModel)]="selectedTemplate(template._id).applicants" multiple color="primary" *ngIf="template.isApplicantRelated">
                <mat-button-toggle [disabled]="!selectedTemplate(template._id).templateId" [value]="contactId" *ngFor="let contactId of offer.applicants;let aIndex = index" (click)="$event.stopPropagation()">Ž{{ aIndex + 1 }}</mat-button-toggle>
              </mat-button-toggle-group>
              <mat-button-toggle-group [(ngModel)]="selectedTemplate(template._id).drawdowns" multiple color="primary" *ngIf="template.isDrawdownRelated">
                <mat-button-toggle [disabled]="!selectedTemplate(template._id).templateId" [value]="drIndex" *ngFor="let drawdown of offer.drawdowns;let drIndex = index" (click)="$event.stopPropagation()">Č{{ drIndex + 1 }}</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </div>
</div>

<mat-menu #groupMenu="matMenu">
  <ng-template matMenuContent let-group="group">
    <button mat-menu-item
            (click)="offersService.changeAllDocumentsStatus(deal, offer, group, 'done')"
            *ngIf="group === 'Úvěrová dokumentace'">
      <mat-icon>published_with_changes</mat-icon>
      Změnit stav všech dokumentů na finální stav
    </button>
    <button mat-menu-item
            *ngIf="usersService.isPrivileged('documents:get')"
            [disabled]="offersService.offerForm.dirty || !offersService.hasDocuments(offer, group)"
            (click)="offersService.getDocuments(deal, offer, group)">
      <mat-icon>cloud_download</mat-icon>
      Stáhnout dokumenty
    </button>
  </ng-template>
</mat-menu>
