<mat-icon class="call-icon missed-outgoing"
          *ngIf="call.direction === 'out' && call.finishedAt !== undefined && !call.answeredAt"
          matTooltip="Volaný nezvedl">
  call_missed_outgoing
</mat-icon>

<mat-icon class="call-icon made"
          *ngIf="call.direction === 'out' && call.answeredAt"
          matTooltip="Odchozí hovor">
  call_made
</mat-icon>

<mat-icon class="call-icon missed"
          *ngIf="call.direction === 'in' && call.finishedAt !== undefined && !call.answeredAt"
          matTooltip="Zmeškaný hovor">
  call_missed
</mat-icon>

<mat-icon class="call-icon received"
          *ngIf="call.direction === 'in' && call.answeredAt"
          matTooltip="Přijatý hovor">
  call_received
</mat-icon>


