import {Component, OnInit} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Call, DialNumberParam} from '@app/models/calls.model';
import {map} from 'rxjs/operators';
import {CallsService} from '@app/services/calls.service';
import {PipelinesService} from '@app/services/pipelines.service';
import {MainService} from '@app/services/main.service';
import {CallingWidgetService} from '@app/components/callingwidget/callingwidget.service';
import {CallsPhoneNumberPipe} from '@app/pipes/calls-phone-number.pipe';
import {BackendService} from '@app/services/backend.service';
import {UsersService} from '@app/services/users.service';

@Component({
  selector: 'app-calling-widget-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {
  $groupedCalls: BehaviorSubject<{ [key: string]: Call[] }> = new BehaviorSubject<{ [key: string]: Call[] }>({});
  groupedCalls$: Observable<{ [key: string]: Call[] }> = this.$groupedCalls.asObservable();
  groups$: Observable<string[]>;
  callsDisabled = false;
  selectedCall: Call | null;

  constructor(
    public callsService: CallsService,
    public pipelinesService: PipelinesService,
    public mainService: MainService,
    public callingWidgetService: CallingWidgetService,
    private callsPhoneNumberPipe: CallsPhoneNumberPipe,
    private backendService: BackendService,
    private usersService: UsersService,
  ) {
    this.callingWidgetService.callsDisabled$.subscribe(disabled => this.callsDisabled = disabled);
  }

  ngOnInit(): void {
    this.callsService.items$.subscribe(calls => {
      const groups = new Set(calls.filter((c => c.talkingTime !== undefined)).map(call => new Date(call.createdAt).toDateString()));
      const groupedCalls = {};
      groups.forEach(dateString => {
        groupedCalls[dateString] = calls.filter(call =>
          call.talkingTime !== undefined && new Date(call.createdAt).toDateString() === dateString
        ).sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      });
      this.$groupedCalls.next(groupedCalls);
    });
    this.groups$ = this.groupedCalls$.pipe(
      map(groups => Object.keys(groups).sort((a: string, b: string) => new Date(b).getTime() - new Date(a).getTime()))
    );
    this.callsService.load(0);
    this.backendService.connected$.subscribe(connected => {
      if (connected) {
        this.callsService.load(0);
      }
    });
  }

  public async dialPhone(call: Call) {
    const param: DialNumberParam = {
      phoneNumber: this.callsPhoneNumberPipe.transform(call),
      contactId: call.contactId,
      origin: 'WidgetHistoryComponent'
    };
    if (!this.callsDisabled) {
      await this.callsService.initiateCall(param);
    }
  }

  public endReached() {
    if (!this.callsService.$isLoading.getValue()) {
      this.callsService.load();
    }
  }

  public selectCall(call: Call) {
    this.selectedCall = call;
    if (this.selectedCall) {
      if (call._contact?._id) {
        this.callsService.filter = {
          contactId: this.selectedCall._contact._id
        };
      } else {
        const phoneNumber= call.direction === 'in' ? call.caller.phoneNumber : call.peer.phoneNumber;
        this.callsService.filter = {
          $or: [
            { direction: 'out', 'peer.phoneNumber': phoneNumber },
            { direction: 'in', 'caller.phoneNumber': phoneNumber },
          ]
        };
      }
      this.callsService.load(0);
    } else {
      this.callsService.filter = {};
      this.callsService.load(0);
    }
  }
}
