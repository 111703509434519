import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Task } from '../models/task.model';
import { UsersService } from './users.service';
import { DialogsService } from './dialogs.service';
import { SettingsService } from './settings.service';
import { SnackbarService } from './snackbar.service';
import { UserGroupsService } from '@app/services/user-groups.service';
import { Offer } from '@app/models/offer.model';
import { PipelinesService } from '@app/services/pipelines.service';
import { Note } from '@app/models/note.model';
import { NotesService } from '@app/services/notes.service';
import { TasksListOptions } from '@app/components/tasks-list/tasks-list.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MainService } from '@app/services/main.service';

export interface TasksFilter {
  dueAt?: Date;
  due?: string;
  assignedToId?: string;
  priority?: number;
  color?: string;
  search?: string;
  completed?: boolean;
  hideOverdued?: boolean;
  reverseOrder?: boolean;
}

@Injectable({ providedIn: 'root' })
export class TasksService extends DataService<Task> {
  public palette: { label: string, value: string }[] = [
    { label: 'Červená', value: 'red' },
    { label: 'Růžová', value: 'pink' },
    { label: 'Fialová', value: 'purple' },
    { label: 'Modrá', value: 'blue' },
    { label: 'Zelená', value: 'green' },
    { label: 'Žlutá', value: 'yellow' },
    { label: 'Oranžová', value: 'orange' },
  ];

  public incompletedTotal$: Observable<number>;
  public storedFilter: TasksFilter = {};
  private static reverseOrder = false;

  constructor(
    private usersService: UsersService,
    private userGroupsService: UserGroupsService,
    private dialogsService: DialogsService,
    private settingsService: SettingsService,
    private snackbarService: SnackbarService,
    private pipelinesService: PipelinesService,
    private notesService: NotesService,
    private mainService: MainService,
  ) {
    super('tasks');
    this.refreshStrategy = 'merge';
    this.pagination = 50;
    this.setSort();
    this.query = {
      $or: [
        {
          startAt: { $lte: Date.now() },
        },
        {
          startAt: null,
        },
      ],
      deletedAt: { $exists: false },
    };

    this.incompletedTotal$ = this.$items.pipe(
      map((items: Task[]) => items.filter((i: Task) => !i.completedAt).length)
    );
  }

  public loadFromStorage() {
    const filters = JSON.parse(localStorage.getItem('tasksFilter')) || {};
    if (!this.usersService.isPrivileged('tasks/all')) {
      filters.assignedToId = this.usersService.user._id;
    }
    this.setFilter(filters);
    return filters;
  }

  public loadOptionsFromStorage(options: TasksListOptions): TasksListOptions {
    const config = JSON.parse(localStorage.getItem('tasksOption')) || {};
    return { ...options, ...config };
  }

  public saveOptionsToStorage(options: TasksListOptions): void {
    const config = { expandNotes: options.expandNotes, compactView: options.compactView };
    localStorage.setItem('tasksOption', JSON.stringify(config));
  }

  public setSort(reverse = false) {
    if (reverse) {
      this.sort = { isPinned: -1, dueAt: -1 };
    } else {
      this.sort = { isPinned: -1, completedAt: 1, dueAt: 1 };
    }
    TasksService.reverseOrder = reverse;
  }

  public async setFilter(filters: TasksFilter, withoutLoad = false) {
    let dealsId: string[] = [];
    const filter: any = {$or: []};
    filters = Object.assign({}, filters);
    this.storedFilter = filters;
    if (filters && Object.keys(filters).length > 0) {
      if (filters.search) {
        const query: any = {
          $text: { $search: filters.search },
          $select: ['dealId'],
          $limit: 100
        };
        if (this.usersService.isPrivileged('tasks/all')) {
          if (filters.assignedToId) {
            query.userId = {$in: [filters.assignedToId]};
          }
        } else {
          query.userId = {$in: [this.usersService.user._id]};
        }
        try {
          const res = await this.notesService.find({query});
          dealsId = res.map((note: any) => note.dealId);
        } catch (e) {
          dealsId = [];
        }
      }
      filter.joins = ['deals', 'offers', 'notes', 'properties', 'info']; // SPEEDUP
      filter.omitfields = ['_deal', '_offer', '_properties']; // SPEEDUP
      if (this.usersService.isPrivileged('tasks/all')) {
        if (filters.assignedToId) {
          filter.assignedToId = {$in: [filters.assignedToId]};
        }
      } else {
        if (this.usersService.user.groupId) {
          filter.assignedToId = {$in: [this.usersService.user._id, this.usersService.user.groupId]};
        } else {
          filter.assignedToId = {$in: [this.usersService.user._id]};
        }
      }
      if (filters.assignedToId === '') {
        filters.assignedToId = null;
      }
      if (filters.priority !== null && filters.priority !== undefined) {
        filter.priority = filters.priority;
      }
      if (filters.color !== null && filters.color !== undefined) {
        filter.color = filters.color;
      }

      if (filters.due === 'justtoday') { // chceme pouze dnesek, zobrazime prosle i ke splneni
        const date1 = new Date();
        date1.setHours(0, 0, 0);
        const date2 = new Date();
        date2.setHours(23, 59, 59);
        filter.dueAt = {$gt: date1, $lte: date2};
      } else if (filters.due && filters.hideOverdued) { // hledame podle splneni, zobrazime pouze ke splneni
        const dueAt = this.getDueDate(filters.due);
        if (dueAt !== null) {
          const date1 = new Date();
          dueAt.setDate(dueAt.getDate() + 1);
          dueAt.setHours(0, 0, 0, 0);
          filter.dueAt = {$gt: date1, $lt: dueAt};
        }
      } else if (filters.due) { // hledame podle splneni, zobrazime prosle i ke splneni
        const dueAt = this.getDueDate(filters.due);
        if (dueAt !== null) {
          dueAt.setDate(dueAt.getDate() + 1);
          dueAt.setHours(0, 0, 0, 0);
          filter.dueAt = {$lt: dueAt};
        }
      } else if (filters.hideOverdued) { // zobrazime pouze ke splneni
        const date1 = new Date();
        filter.dueAt = {$gt: date1};
      }

      if (filters.search) {
        filter.$or = [
          {
            search: {
              $regex: filters.search
                .normalize('NFD')
                .replace(/\+|\(|\)|\-|\\|\//gi, '')
                .replace(/[\u0300-\u036f]/g, ''),
              $options: 'i',
            },
          },
          {
            title: { $regex: filters.search.replace(/\+|\(|\)|\-|\\|\//gi, ''), $options: 'i'}
          },
          {
            text: { $regex: filters.search.replace(/\+|\(|\)|\-|\\|\//gi, ''), $options: 'i'}
          },
          {
            dealId: { $in: dealsId }
          }
        ];
      }
      if (!filters.completed) {
        filter.completedAt = null;
      }
      delete filters.dueAt;
      localStorage.setItem('tasksFilter', JSON.stringify(filters));
    } else {
      localStorage.removeItem('tasksFilter');
    }
    if (!filter.$or.length) {
      delete filter.$or;
    }
    this.setSort(filters.reverseOrder);
    this.filter = filter;
    if (!withoutLoad) {
      this.loadData(0);
    }
  }

  public setFilterToDealId(dealsId: string): void {
    this.filter = {
      dealId: dealsId,
      joins: ['deals', 'offers', 'notes', 'properties', 'info'],
      omitfields: ['_deal', '_offer', '_properties'],
    };
    this.setSort(false);
  }

  get users() {
    return this.usersService.items.filter(
      u => u.role !== 'user' && !u.isBlocked
    );
  }

  get userGroups() {
    return this.userGroupsService.$items.getValue();
  }

  public beforeSetItems() {
    this.items.sort((a: Task, b: Task) => {
      return a.completedAt && !b.completedAt
        ? 1
        : !a.completedAt && b.completedAt
          ? -1
          : new Date(a.dueAt).getTime() - new Date(b.dueAt).getTime();
    });
  }

  finishTask(task: any) {
    this.patch(task._id, { completedAt: new Date(), completedById: this.usersService.user._id });
  }

  removeTask(task: any) {
    this.dialogsService.confirm('Odstranit úkol', 'Chcete opravdu odstranit tento úkol?').subscribe(confirm => {
      if (confirm) {
        this.patch(task._id, { deletedAt: new Date(), remindAt: null });
      }
    });
  }

  restoreTask(task: any) {
    this.patch(task._id, { completedAt: null, completedById: null });
  }

  editTask(task: Task) {
    if (
      !task.completedAt &&
      ([this.usersService.user._id, this.usersService.user.groupId].includes(task.assignedToId) ||
        this.usersService.isPrivileged('tasks/all'))
    ) {
      this.dialogsService
        .task(task, this.usersService.user, this.users, this.userGroups, this.settingsService.options)
        .subscribe(async editedTask => {
          if (editedTask === false) {
            await this.removeTask(task);
          } else if (editedTask) {
            await this.patch(task._id, editedTask);
            this.snackbarService.showSuccess('Změny uloženy.');
          }
        });
    }
  }

  addTask(deal: any, offer?: Offer) {
    const dueAt = new Date();
    dueAt.setHours(18, 0, 0);
    this.dialogsService
      .task(
        {
          type: 'to-do',
          priority: deal.priority,
          dealId: deal._id,
          assignedToId: this.usersService.user._id,
          _deal: deal,
          offerId: offer?._id || null,
          _offer: offer || null,
          dueAt,
        },
        this.usersService.user,
        this.users,
        this.userGroups,
        this.settingsService.options
      )
      .subscribe(async newTask => {
        if (newTask) {
          await this.create(newTask);

          await this.refreshNote(deal._id);

          this.snackbarService.showSuccess('Úkol byl vytvořen.');
        }
      });
  }

  async addCaseTask(deal: any) {
    const pipeline = this.pipelinesService.getPipeline(deal._pipelineUrl);
    const dueAt = new Date();
    dueAt.setHours(18, 0, 0);
    this.dialogsService
      .task(
        {
          type: 'to-do',
          priority: deal.priority,
          dealId: deal._id,
          assignedToId: this.usersService.user._id,
          _deal: deal,
          pipelineId: pipeline._id,
          dueAt,
        },
        this.usersService.user,
        this.users,
        this.userGroups,
        this.settingsService.options
      )
      .subscribe(async newTask => {
        if (newTask) {
          await this.create(newTask);
          this.snackbarService.showSuccess('Úkol byl vytvořen.');
        }
      });
  }

  public changeAssignedTo(taskId: string, userId: string) {
    this.patch(taskId, { assignedToId: userId });
  }

  public async pinTask(task: Task, isPinned = true) {
    await this.patch(task._id, { isPinned });
  }

  public async changeColor(task: Task, color: string | null) {
    await this.patch(task._id, { color });
  }

  public async changePriority(task: Task, priority: number) {
    await this.patch(task._id, { priority });
  }

  public async changeTitle(task: Task, title: string) {
    await this.patch(task._id, { title });
  }

  public async changeText(task: Task, text: string) {
    await this.patch(task._id, { text });
  }

  public getPalette(color: string): any {
    return this.palette.find(o => o.value === color);
  }

  public getDueDate(due: string): Date | null {
    if (due !== '') {
      const date = new Date();
      switch (due) {
        case 'yesterday':
          date.setDate(date.getDate() - 1);
          break;
        case 'tomorrow':
          date.setDate(date.getDate() + 1);
          break;
        case 'week':
          const first = date.getDate() - date.getDay();
          date.setDate(first + 7);
          break;
        case 'month':
          date.setMonth(date.getMonth() + 1);
          date.setDate(0);
          break;
      }
      return date;
    } else {
      return null;
    }
  }

  public refreshTaskNote(items: Task[], dealId: string, note: Note): void {
    if (items) {
      const userId = note.userId;
      items.forEach((t: Task) => {
        if (t.dealId === dealId) {
          if (t._notes && t._notes.length > 0) {
            let found = false;
            t._notes.forEach((n: Note, i: number) => {
              if (n.userId === userId) {
                t._notes[i] = note;
                found = true;
              }
            });
            if (!found) {
              t._notes.push(note);
            }
          } else {
            t._notes = [note];
          }
          this.updated(t);
        }
      });
    }
  }

  public isTaskPristine(task: Task): boolean {
    return (
      !(task.tmpTitle !== undefined && task.title !== task.tmpTitle) &&
      !(task.tmpText !== undefined && task.text !== task.tmpText) &&
      !(task.tmpNote !== undefined && task.note !== task.tmpNote)
    );
  }

  public isPristine(tasks: Task[] | null): boolean {
    if (tasks === null) {
      return this.items.every(task => this.isTaskPristine(task));
    } else {
      return tasks.every(task => this.isTaskPristine(task));
    }
  }

  public loadData(fromIndex?: number): Promise<void> {
    return this.load(fromIndex);
  }

  private sortTaskFnc(a: Task, b: Task): number {
    const isPinnedA = a.isPinned ?? false;
    const isPinnedB = b.isPinned ?? false;
    const completedAtA = a.completedAt ? new Date(a.completedAt).getTime() : 0;
    const completedAtB = b.completedAt ? new Date(b.completedAt).getTime() : 0;
    const dueAtA = new Date(a.dueAt).getTime();
    const dueAtB = new Date(b.dueAt).getTime();

    if (isPinnedA !== isPinnedB) {
      return isPinnedA ? -1 : 1;
    }
    if ((completedAtA === 0) && (completedAtB === 0)) {
      return TasksService.reverseOrder ? dueAtB - dueAtA : dueAtA - dueAtB;
    }
    if (completedAtA === 0) {
      return -1;
    }
    if (completedAtB === 0) {
      return 1;
    }
    return TasksService.reverseOrder ? dueAtB - dueAtA : dueAtA - dueAtB;
  }

  public sortTasks(tasks: Task[]): Task[] {
    return tasks.sort(this.sortTaskFnc);
  }

  public async refreshNote(dealId: string): Promise<void> {
    const notes = await this.notesService.find({ query: { dealId: dealId }});
    if (notes && notes.length > 0) {
      notes.forEach(note => {
        this.refreshTaskNote(this.items, dealId, note);
      });
    }
  }

  public async refreshNotes(): Promise<void> {
    const dealIds = [ ...new Set(this.items.map(task => task.dealId)) ];
    if (dealIds.length > 0) {
      this.mainService.showLoading();
      try {
        const notes = await this.notesService.find({query: {dealId: {$in: dealIds}}});
        if (notes && notes.length > 0) {
          notes.forEach(note => {
            this.refreshTaskNote(this.items, note.dealId, note);
          });
        }
      } finally {
        this.mainService.hideLoading();
      }
    }
  }
}
