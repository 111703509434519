import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'timelinePermission' })
export class TimelinePermissionPipe implements PipeTransform {
  transform(item: any): string {
    if (!item) {
      return '';
    }
    const label = !item.value ? 'Zapnuto' : 'Vypnuto';
    switch (item.key) {
      case 'disabledSystemMsg':
        return `Systémová komunikace (SMS i email): ${label}`;
      case 'disabledTransactionMsg':
        return `Procesní komunikace (SMS i email): ${label}`;
      case 'disabledCasesSms':
        return `Odloženo a storno (SMS): ${label}`;
      case 'disabledCasesEmail':
        return `Odloženo a storno (email): ${label}`;
      case 'disabledMarketingSms':
        return `Marketingové informace (SMS): ${label}`;
      case 'disabledMarketingEmail':
        return `Marketingové informace (email): ${label}`;
    }
    return item.key;
  }
}
