<div class="task" [ngClass]="ngClass" (animationend)="animationEnd($event)">

  <!-- TOP LINE -->
  <div class="flex flex-row justify-between items-center">

    <div class="flex-none flex flex-row justify-start items-center w-48">
      <button mat-icon-button
              matTooltip="Splněný úkol"
              *ngIf="task.completedAt"
              class="done mr-2"
              [ngClass]="{'is-warning': task.isOverdue, 'is-completed': task.completedAt}"
              (click)="checkTask(task)">
        <mat-icon class="task-icon">check_circle</mat-icon>
      </button>
      <button mat-icon-button
              [matTooltip]="task.isOverdue ? 'Nesplněný úkol - po termínu' : 'Nesplněný úkol'"
              *ngIf="!task.completedAt"
              class="done waiting mr-2"
              [ngClass]="{'is-warning': task.isOverdue,'is-completed': task.completedAt}"
              (click)="checkTask(task)">
        <mat-icon class="task-icon">check_circle_outline</mat-icon>
      </button>

      <app-priority-button matTooltip="Priorita úkolu"
                           [priority]="task.priority"
                           [enableMenu]="true"
                           (priorityChange)="changePriority($event)">
      </app-priority-button>

      <mat-icon *ngIf="task.isPinned" class="task-icon ml-2 mr-2" style="font-size: 28px" matTooltip="Přišpendlený úkol">
        🦄
      </mat-icon>

      <mat-icon *ngIf="(task._pipeline?.url === 'mortgage') && (!task._dealsInfo?.isPlatformChange)" class="task-icon ml-2 text-slate-600" matTooltip="Autoklient">
        directions_car_filled
      </mat-icon>
    </div>

    <ng-container *ngIf="!options.caseView">
      <div *ngIf="task._pipeline?.url === 'mortgage'"
           class="flex-none justify-between items-center mr-2 w-48">
        <div class="flex-col">
          <a *ngIf="task.dealId" [routerLink]="['/deals', task.dealId]">
            {{ task.dealName }}
          </a>
        </div>
      </div>

      <div *ngIf="task._pipeline?.url !== 'mortgage'"
           class="flex-none justify-between items-center mr-2 w-48">
        <div class="flex-col">
          <a *ngIf="task._pipeline.url" [routerLink]="['/cases', task._pipeline.url, task.dealId, 'edit']">
            {{ task.dealName }}
          </a>
<!--          <span>-->
<!--            {{ task.phones?.[0]?.countryCode }}&nbsp;{{ task.phones?.[0]?.number }}-->
<!--          </span>-->
        </div>
      </div>
    </ng-container>

    <div class="flex-none mr-2 w-96" [ngClass]="{'truncate-to-2-lines': !options.expandNotes}">
      <p-inplace #inpTitle (onActivate)="titleEditing()">
        <ng-template pTemplate="display">
          <div [ngClass]="{'line-through': task.completedAt, 'truncate-to-2-lines': !options.expandNotes}"
               class="font-bold wrap-anywhere">
            {{ task.title }}
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <div class="flex flex-row inplace-wrapper w-full">
              <textarea matInput
                        name="text"
                        class="inplace-text"
                        cdkTextareaAutosize
                        [(ngModel)]="task.tmpTitle"
                        (keydown.enter)="$event.preventDefault()">
              </textarea>
            <mat-icon (click)="saveTitle(inpTitle, task.tmpTitle)"
                      *ngIf="task.title !== task.tmpTitle"
                      color="accent">save</mat-icon>
            <mat-icon (click)="discardTitle(inpTitle)">close</mat-icon>
          </div>
        </ng-template>
      </p-inplace>
    </div>

    <div class="flex w-full mr-2 display-webkitbox" [ngClass]="{'truncate-to-2-lines': !options.expandNotes}">
      <p-inplace #inpText (onActivate)="textEditing()">
        <ng-template pTemplate="display">
          <div *ngIf="task.text" class="flex w-full wrap-anywhere" [ngClass]="{'truncate-to-2-lines': !options.expandNotes}">
            {{ task.text }}
          </div>
          <div *ngIf="!task.text" class="flex text-slate-400">Popis úkolu</div>
        </ng-template>
        <ng-template pTemplate="content">
          <div class="flex flex-row inplace-wrapper w-full">
              <textarea matInput
                        name="text"
                        class="inplace-text"
                        cdkTextareaAutosize
                        [(ngModel)]="task.tmpText">
              </textarea>
            <mat-icon (click)="saveText(inpText, task.tmpText)"
                      *ngIf="task.text !== task.tmpText"
                      color="accent">save</mat-icon>
            <mat-icon (click)="discardText(inpText)">close</mat-icon>
          </div>
        </ng-template>
      </p-inplace>
    </div>


    <div class="dueAt flex-none"
         [ngClass]="{ 'is-warning': task.isOverdue, 'cursor-pointer': !isReadOnly }"
         (click)="tasksService.editTask(task)">
      splnit do:
      <span class="font-bold">{{ task.dueAt | dateWord }}</span>
    </div>

    <div *ngIf="options.compactView && !task.expanded" [matTooltip]="assignedName" class="ml-2 flex-none" (click)="changeOwner()">
      <img *ngIf="assignedAvatar" class="avatar mr-2" [src]="assignedAvatar"/>
      <mat-icon *ngIf="assignedIcon" class="avatar mr-2">{{ assignedIcon }}</mat-icon>
    </div>

    <div *ngIf="options.compactView && !task.expanded" class="flex-none flex flex-row justify-end items-center">
      <button mat-icon-button
              (click)="task.expanded = !task.expanded">
        <mat-icon class="task-icon">keyboard_arrow_down</mat-icon>
      </button>
    </div>
  </div>
  <!-- TOP LINE -->



  <!-- EXPANDED VIEW - DEAL / CASE DETAILS -->
  <div *ngIf="(!options.compactView || task.expanded) && !options.caseView"
       class="flex flex-row justify-between items-center mt-2">

    <!-- mortgage info -->
    <div *ngIf="task._pipeline?.url === 'mortgage'" class="w-full flex flex-row justify-between items-center mb-2">
      <div class="flex-none w-48">
        <span *ngIf="task._offersInfo?.amount" class="font-bold">
          {{ task._offersInfo.amount | number : '1.0-0' : 'cs-CS' }} Kč
        </span>
      </div>

      <div class="flex-none mr-2 w-48">
        <span *ngIf="task._dealsInfo?.affiliateId" class="italic text-slate-500">
          {{ affiliatesService.items$ | affiliateLabel:task._dealsInfo?.affiliateId | async }}
        </span>
        <span *ngIf="!task._dealsInfo?.affiliateId" class="italic text-slate-500">
          Hyponamiru.cz
        </span>
      </div>

      <div class="grow">
        <span *ngIf="task._offersInfo?.purpose" class="text-slate-500">
          {{task._offersInfo.purpose | optionsLabel : mainService.options('purpose') }}
        </span>
      </div>

      <div class="flex-none text-right w-48">
        <span *ngIf="task.createdAt" class="text-slate-500" matTooltip="Datum vytvoření úkolu" matTooltipPosition="above">
          {{ task.createdAt | dateWord }}
        </span>
      </div>
    </div>

    <!-- insurance info -->
    <div *ngIf="task._pipeline?.url !== 'mortgage'" class="w-full flex flex-row justify-between items-center mb-2">
      <div class="flex-none w-48">
        <span *ngIf="task._pipeline?.name" class="font-bold">{{ task._pipeline?.name }}</span>
      </div>

      <div class="flex-none mr-2 w-48">
        <span *ngIf="task._dealsInfo?.providerName" class="text-slate-500">
          {{ task._dealsInfo?.providerName }}
        </span>
      </div>

      <div class="grow">
        <span *ngIf="task._pipeline?.url === 'car-insurance' && task._dealsInfo?.vehicleLicencePlate" class="text-slate-500">
          {{ task._dealsInfo?.vehicleLicencePlate }}
        </span>
        <span *ngIf="task._pipeline?.url === 'real-estate-insurance' && task._dealsInfo?.address" class="text-slate-500">
          {{ task._dealsInfo?.[0]?.address | fullAddress }}
        </span>
      </div>

      <div class="flex-none text-right w-48">
        <span *ngIf="task.createdAt" class="text-slate-500" matTooltip="Datum vytvoření úkolu" matTooltipPosition="above">
          {{ task.createdAt | dateWord }}
        </span>
      </div>
    </div>
  </div>
  <!-- EXPANDED VIEW - DELA / CASE DETAILS -->

  <!-- AVATARS / NOTE -->
  <div *ngIf="!options.compactView || task.expanded"
       class="flex flex-row justify-between items-center mt-4">

    <div class="flex-none w-48">
      <span *ngIf="options.displayAvatar" class="flex flex-row items-center cursor-pointer" (click)="changeOwner()">
        <img *ngIf="assignedAvatar" class="avatar mr-2" [src]="assignedAvatar"/>
        <mat-icon *ngIf="assignedIcon" class="avatar mr-2">{{ assignedIcon }}</mat-icon>
        <div *ngIf="assignedName">{{ assignedName }}</div>
      </span>
    </div>

    <div class="flex flex-col grow w-full" *ngIf="options.displayNotes === 'all' && !options.expandNotes">
      <div class="note-title text-slate-400">{{ noteLabel }}</div>
      <div #htmlNote
           class="grow w-full ql-editor rich-text note"
           [ngClass]="{ 'note-shrink': !options.expandNotes, 'text-slate-400': !task.note, 'cursor-pointer': !noteReadOnly }"
           [innerHTML]="task.note ? task.note : 'Poznámka k případu'"
           (click)="noteDisplay = !noteReadOnly">
      </div>
    </div>

    <div class="grow w-full" *ngIf="options.displayNotes === 'all' && options.expandNotes">
      <div class="note-title text-slate-400">{{ noteLabel }}</div>
      <p-inplace #inpNote styleClass="grow w-full" [preventClick]="true">
        <ng-template pTemplate="display">
          <div (click)="inpNoteClicked()"
               class="grow w-full ql-editor rich-text note"
               [ngClass]="{ 'note-shrink': !options.expandNotes, 'text-slate-400': !task.note }"
               [innerHTML]="task.note ? task.note : 'Poznámka k případu'">
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <p-editor [(ngModel)]="task.tmpNote"
                    (onInit)="initEditor($event)">
            <ng-template pTemplate="header">
              <span class="ql-formats">
                <select class="ql-size"></select>
              </span>
              <span class="ql-formats">
                <button type="button" class="ql-bold"></button>
                <button type="button" class="ql-italic"></button>
                <button type="button" class="ql-underline"></button>
                <!--select class="ql-color"></select-->
              </span>
              <span class="ql-formats">
                <button type="button" class="ql-list" value="ordered"></button>
                <button type="button" class="ql-list" value="bullet"></button>
                <button type="button" class="ql-indent" value="-1"></button>
                <button type="button" class="ql-indent" value="+1"></button>
              </span>
              <span class="ql-formats">
                <button type="button" class="ql-image"></button>
              </span>
            </ng-template>
          </p-editor>

          <button mat-raised-button color="accent" class="btn-save"
                  (click)="saveNote(this.task.tmpNote, inpNote)"
                  [disabled]="task.note === task.tmpNote">
            Uložit
          </button>

          <button mat-raised-button class="btn-close" (click)="discardNote(inpNote)">
            Zrušit
          </button>
        </ng-template>
      </p-inplace>
    </div>

    <div class="flex flex-none gap-2">
      <button mat-icon-button
              [matMenuTriggerFor]="colorMenu"
              matTooltip="Změnit barvu">
        <mat-icon>palette</mat-icon>
      </button>

      <button mat-icon-button
              [ngClass]="{'button-pinned': task.isPinned}"
              class="ml-2 mr-2"
              (click)="changePin(task, !task.isPinned)"
              [matTooltip]="task.isPinned ? 'Odšpendlit' : 'Přišpendlit'">
        <mat-icon>push_pin</mat-icon>
      </button>

      <app-dial-button class="dial-button-icon inline-flex"
                       [menuItem]="false"
                       [compact]="true"
                       [phoneNumber]="task.phones?.[0]?.number"
                       [countryCode]="task.phones?.[0]?.countryCode"
                       [dealName]="task.dealName"
                       (clickDial)="dialPhone($event, task)">
      </app-dial-button>

      <button mat-icon-button
              class="ml-2"
              (click)="editTask(task)"
              [disabled]="isReadOnly"
              [matTooltip]="isReadOnly ? 'Splněné úkoly nelze upravovat' : 'Upravit'">
        <mat-icon>edit</mat-icon>
      </button>

      <button mat-icon-button
              class="ml-2"
              (click)="task.expanded = !task.expanded"
              *ngIf="options.compactView">
        <mat-icon>keyboard_arrow_up</mat-icon>
      </button>

    </div>
  </div>
  <!-- AVATARS / NOTE -->

</div>

<mat-menu #colorMenu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item *ngFor="let color of tasksService.palette" (click)="changeColor(color.value)">
      <mat-icon [class]="'rounded-full bg-solid-' + color.value"></mat-icon>
      {{ color.label }}
    </button>
    <button mat-menu-item *ngIf="task.color" (click)="changeColor(null)">
      <mat-icon [class]="'rounded-full'">close</mat-icon>
      Zrušit podbarvení
    </button>
  </ng-template>
</mat-menu>

<app-rich-editor [(visible)]="noteDisplay" [text]="task.note" (saveText)="saveNote($event, null)">
</app-rich-editor>
