import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UserInterface } from '@app/interfaces/user.interface';
import { Task} from '@app/models/task.model';
import { DialNumberParam } from '@app/models/calls.model';
import { CallsService } from '@app/services/calls.service';
import { MainService } from '@app/services/main.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { DealsService } from '@app/services/deals.service';
import { CasesService } from '@app/services/cases.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './task-dialog.component.html',
})
export class TaskDialogComponent implements OnInit {

  public user: UserInterface;
  public task: Task;
  public filteredOwners: any[];
  public owners: any[];
  public groups: any[];
  public options: any;
  public hourOptions: number[] = Array.from(Array(23).keys());
  public minuteOptions: number[] = [0, 15, 30, 45];
  public reminder = '';
  public dueAtHours = 0;
  public dueAtMinutes = 0;
  public remindAtHours = 9;
  public remindAtMinutes = 0;
  public assigned: any;

  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;

  constructor(
    public dialogRef: MatDialogRef<TaskDialogComponent>,
    public callsService: CallsService,
    protected mainService: MainService,
    protected dealsService: DealsService,
    protected casesService: CasesService,
  ) {
  }

  ngOnInit() {
    if (this.task.dueAt) {
      this.task.dueAt = new Date(this.task.dueAt);
      this.dueAtHours = this.task.dueAt.getHours() || this.dueAtHours;
      this.dueAtMinutes = this.task.dueAt.getMinutes() || this.dueAtMinutes;
    }
    if (this.task.remindAt) {
      this.task.remindAt = new Date(this.task.remindAt);
      this.remindAtHours = this.task.remindAt.getHours();
      this.remindAtMinutes = this.task.remindAt.getMinutes();
    }
    this.filterOwners('');
    if (this.task.assignedToId) {
      this.assigned = this.filteredOwners.find((item) => item._id === this.task.assignedToId);
    } else {
      this.assigned = null;
    }
  }

  priorityText(priority) {
    return this.options.priority.find(o => o.value === priority)?.label;
  }

  public pad(num: number, size: number): string {
    return num.toString().padStart(size, '0');
  }

  dueDateAtChanged($event: any, hoursObject?: any, minutesObject?: any) {
    if ($event) {
      const dateObject = new Date($event);
      dateObject.setHours(hoursObject);
      dateObject.setMinutes(minutesObject);
      this.task.dueAt = dateObject;
    }
  }

  remindDateAtChanged($event: any, hoursObject?: any, minutesObject?: any) {
    if ($event) {
      const dateObject = new Date($event);
      dateObject.setHours(hoursObject);
      dateObject.setMinutes(minutesObject);
      this.task.remindAt = dateObject;
    }
  }

  hoursChanged($event: any, dateObject?: any, hoursObject?: any) {
    dateObject.setHours(hoursObject);
  }

  minutesChanged($event: any, dateObject?: any, minutesObject?: any) {
    dateObject.setMinutes(minutesObject);
  }

  reminderChanged(days: any) {
    if (days >= 0) {
      this.task.remindAt = new Date((new Date()).setDate((new Date()).getDate() + days));
      this.task.remindAt.setHours(this.remindAtHours);
      this.task.remindAt.setMinutes(this.remindAtMinutes);
    }
  }

  public async dialPhone(event: any, task: Task) {
    if (task._pipeline.url === 'mortgage') {
      const deal = await this.dealsService.get(task.dealId);
      const param: DialNumberParam = {
        countryCode: event.countryCode,
        number: event.phoneNumber,
        contactName: event.contactName,
        contactId: deal._offers[deal._offers.length - 1].applicants[0],
        origin: 'TaskDialogComponent',
        dealId: deal._id,
        pipelineId: task.pipelineId,
        dealOwnerId: deal.ownerId,
        stage: deal.stage,
      };
      await this.callsService.initiateCall(param);
    } else {
      const deal = await this.casesService.getCaseByPipelineId(task.dealId, task.pipelineId);
      const param: DialNumberParam = {
        countryCode: event.countryCode,
        number: event.phoneNumber,
        contactName: event.contactName,
        contactId: deal._contacts[0]?.contactId,
        origin: 'TaskDialogComponent',
        dealId: deal._id,
        pipelineId: task.pipelineId,
        dealOwnerId: deal.ownerId,
        stage: deal.stage,
      };
      await this.callsService.initiateCall(param);
    }
  }

  public userDisplayFn(user: any): string {
    return user?.name ?? '';
  }

  public filterOwners(event: any): void {
    if (event._id) {
      this.task.assignedToId = event._id;
    } else {
      const filterValue = this.mainService.removeDiacritics(event.toLowerCase().trim());
      const users = this.owners
        .map((user) => { return { _id: user._id, name: (user.firstName ?? '') + ' ' + (user.lastName ?? '') }; } )
        .filter(option => this.mainService.removeDiacritics(option.name.toLowerCase()).includes(filterValue))
        .sort((a, b) => a.name.localeCompare(b.name, 'cs', { sensitivity: 'base' } ));
      const groups = this.groups
        .filter(option => this.mainService.removeDiacritics(option.name.toLowerCase()).includes(filterValue))
        .sort((a, b) => a.name.localeCompare(b.name, 'cs', { sensitivity: 'base' } ));
      this.filteredOwners = [...users, ...groups];
    }
  }

  public openPanel() {
    this.filterOwners('');
    this.autocompleteTrigger.openPanel();
  }
}
