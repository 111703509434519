import {Injectable} from '@angular/core';
import {DataService} from './data.service';
import {Contact} from '@app/models/contact.model';
import {SettingsService} from '@app/services/settings.service';
import {MainService} from '@app/services/main.service';
import {DialogsService} from '@app/services/dialogs.service';
import {ContactsService} from '@app/services/contacts.service';
import {DemandsService} from '@app/services/demands.service';
import {UsersService} from '@app/services/users.service';
import {PipelinesService} from '@app/services/pipelines.service';
import {Router} from '@angular/router';
import {CarInsurancesService} from '@app/services/car-insurances.service';
import {LifeInsurancesService} from '@app/services/life-insurances.service';
import {RealEstateInsurancesService} from '@app/services/real-estate-insurances.service';
import {DialNumberParam} from '@app/models/calls.model';
import {CallsService} from '@app/services/calls.service';
import {RealEstateInsurance} from '@app/models/real-estate-insurance.model';
import {LifeInsurance} from '@app/models/life-insurance.model';
import {CarInsurance} from '@app/models/car-insurance.model';
import {TimelineActivityService} from '@app/services/timeline-activity.service';
import {SnackbarService} from '@app/services/snackbar.service';
import { constPipelineLifeInsurance, constPipelineCarInsurance, constPipelineRealEstateInsurance } from '@app/app.constants';

@Injectable({providedIn: 'root'})
export class CasesService extends DataService<any> {

  public storedFilter = {};
  public isLoaded = false;
  constructor(
    private settingsService: SettingsService,
    private mainService: MainService,
    private dialogsService: DialogsService,
    private contactsService: ContactsService,
    private demandsService: DemandsService,
    private usersService: UsersService,
    private pipelinesService: PipelinesService,
    private router: Router,
    private carInsurancesService: CarInsurancesService,
    private lifeInsurancesService: LifeInsurancesService,
    private realEstateInsurancesService: RealEstateInsurancesService,
    public callsService: CallsService,
    private activityService: TimelineActivityService,
    private snackbarService: SnackbarService,
  ) {
    super('cases');
    this.pagination = 50;
    this.query = {
      stage: {$nin: [null, 'init']},
      $select: [ '_id', 'priority', 'numberId', 'name', 'isCanceled', 'isPostponed', 'postponeAlert', 'postponeReason', 'postponedUntil', 'cancelReason', 'stage', 'affiliateId', 'ownerId', 'createdAt', 'missedCalls', 'phones', 'contacts', '_pipelineUrl' ],
      nojoins: [ 'affiliate' ], // SPEEDUP
      omitfields: [ 'modelations', 'participants', '_contacts' ], // SPEEDUP
    };
    this.sort = {
      createdAt: -1
    };

    this.lifeInsurancesService.updated$.subscribe(item => {
      this.updated(item);
    });
    this.realEstateInsurancesService.updated$.subscribe(item => {
      this.updated(item);
    });
    this.carInsurancesService.updated$.subscribe(item => {
      this.updated(item);
    });
    this.lifeInsurancesService.removed$.subscribe(item => {
      this.removed(item);
    });
    this.realEstateInsurancesService.removed$.subscribe(item => {
      this.removed(item);
    });
    this.carInsurancesService.removed$.subscribe(item => {
      this.removed(item);
    });
    this.setFilter(JSON.parse(localStorage.getItem('casesFilter')) || {});
  }

  public setFilter(filters, withoutLoad = false) {
    const filter: any = {};
    this.storedFilter = filters;
    if (filters && Object.keys(filters).length > 0) {
      localStorage.setItem('casesFilter', JSON.stringify(filters));
      const orConditions = [];
      // Check if 'canceled' or 'postponed' stage is included
      const isCanceled = filters.stage.includes('canceled');
      const isPostponed = filters.stage.includes('postponed');
      // Check privileged access
      if (this.usersService.isPrivileged('cases/all')) {
        if (filters.ownerId.length) {
          filter.ownerId = { $in: filters.ownerId };
        }
      } else {
        filter.ownerId = { $in: [this.usersService.user._id] };
      }
      if (filters.affiliateId?.length) {
        filter.affiliateId = { $in: filters.affiliateId };
      }
      // Pipeline URL
      if (filters._pipelineUrl.length) {
        filter._pipelineUrl = { $in: filters._pipelineUrl };
      }

      // Conditions for other stages
      const otherStages = filters.stage.filter(s => !['canceled', 'postponed'].includes(s));
      if (otherStages.length) {
        const stageConditions: any = { stage: { $in: otherStages } };
        if (!isCanceled) {
          stageConditions.isCanceled = {$ne: true};
        }
        if (!isPostponed) {
          stageConditions.isPostponed = {$ne: true};
        }
        orConditions.push(stageConditions);
      }

      // Check if 'canceled' stage is included
      if (filters.stage.includes('canceled')) {
        orConditions.push({ isCanceled: true });
      }

      // Check if 'postponed' stage is included
      if (filters.stage.includes('postponed')) {
        orConditions.push({ isPostponed: true });
      }

      // Search condition
      if (filters.search) {
        const searchRegex = filters.search.normalize('NFD').replace(/\+|\(|\)|\-|\\|\//gi, '').replace(/[\u0300-\u036f]/g, '');
        const searchConditions = {
          $or: [
            { search: { $regex: searchRegex, $options: 'i' } },
            { 'modelations.variants.products.contractNumber': { $regex: searchRegex, $options: 'i' } },
          ]
        };
        orConditions.push(searchConditions);
      }

      // Constructing final filter
      if (orConditions.length > 0) {
        filter.$and = orConditions.map(condition => ({ $or: [condition] }));
      }

    } else {
      localStorage.removeItem('casesFilter');
    }
    this.filter = filter;
    if (!withoutLoad) {
      this.load(0);
    }
  }

  async changeStage(deal, stageId: string) {
    const stage = await this.pipelinesService.getStage(deal._pipelineUrl, stageId);
    if (stage.checkPriority) {
      this.dialogsService.priority('Změna stavu', 'Před změnou stavu zkontroluj prioritu.', this.settingsService.options['priority'], deal.priority).subscribe(async (priority) => {
        if (priority !== null) {
          this.mainService.showLoading();
          await this.getService(deal._pipelineUrl).patch(deal._id, {stage: stageId, priority});
          this.mainService.hideLoading();
        }
      });
    } else {
      this.mainService.showLoading();
      await this.getService(deal._pipelineUrl).patch(deal._id, {stage: stageId});
      this.mainService.hideLoading();
      this.snackbarService.showSuccess('Stav případu byl změněn.');
    }
  }

  public missedCall(deal?) {
    this.dialogsService.confirm('Nedovoláno', 'Skutečně se nepovedlo klientovi dovolat?').subscribe((confirmed) => {
      if (confirmed) {
        this.getService(deal._pipelineUrl).patch(deal._id, {$inc: {missedCalls: 1}});
      }
    });
  }

  public async getCase(dealId: string, pipelineUrl: string) {
    if (pipelineUrl === 'car-insurance') {
      return await this.carInsurancesService.get(dealId);
    }
    if (pipelineUrl === 'life-insurance') {
      return await this.lifeInsurancesService.get(dealId);
    }
    if (pipelineUrl === 'real-estate-insurance') {
      return await this.realEstateInsurancesService.get(dealId);
    }
    return null;
  }

  public async getCaseByPipelineId(dealId: string, pipelineId: string) {
    switch (pipelineId) {
      case constPipelineCarInsurance:
        return await this.carInsurancesService.get(dealId);
        break;
      case constPipelineLifeInsurance:
        return await this.lifeInsurancesService.get(dealId);
        break;
      case constPipelineRealEstateInsurance:
        return await this.realEstateInsurancesService.get(dealId);
        break;
    }
    return null;
  }

  public async cancelCase(deal) {
    this.dialogsService.cancelCase(this.pipelinesService.getPipeline(deal._pipelineUrl)?.cancelReasons).subscribe(async (reasons) => {
      if (reasons) {
        this.mainService.showLoading();
        await this.getService(deal._pipelineUrl).patch(deal._id, {isCanceled: true, cancelReason: reasons});
        this.mainService.hideLoading();
        this.snackbarService.showSuccess('Případ byl stornován.');
      }
    });
  }

  public async postponeCase(deal) {
    deal = await this.get(deal._id);
    this.dialogsService.postponeCase(this.pipelinesService.getPipeline(deal._pipelineUrl)?.postponeReasons, deal.postponedUntil, deal.postponeReason || []).subscribe(async (result) => {
      if (result) {
        this.mainService.showLoading();
        await this.getService(deal._pipelineUrl).patch(deal._id, {
          isPostponed: true,
          postponeReason: result.reasons,
          postponedUntil: result.postponeDate,
          postponeTask: result.task,
          postponeTaskDate: result.taskDate,
          postponeTaskTitle: result.taskTitle,
          postponeTaskText: result.taskText,
          postponeTaskHours: result.taskHours,
          postponeTaskMinutes: result.taskMinutes
        });
        this.mainService.hideLoading();
        this.snackbarService.showSuccess('Případ byl odložen.');
      }
    });
  }

  public restoreCase(deal) {
    this.dialogsService.confirm('Obnovit případ', 'Obnovit tento případ?').subscribe(async (confirmed) => {
      if (confirmed) {
        const data: any = {
        };
        if (deal.isCanceled) {
          data.isCanceled = false;
        }
        if (deal.isPostponed) {
          data.isPostponed = false;
        }
        this.mainService.showLoading();
        await this.getService(deal._pipelineUrl).patch(deal._id, data);
        this.mainService.hideLoading();
      }
    });
  }

  public async addCase(deal?: any, pipelineUrl?: string) {
    this.dialogsService.newDemand({
      deal,
      pipelineId: pipelineUrl,
      priorities: this.settingsService.options['priority'],
      pipelines: this.pipelinesService.pipelines,
      user: this.usersService.user,
      owners: this.usersService.items
        .filter(u => u.role === 'specialist' && !u.cases?.isDefaultOwner && !u.isBlocked)
        .sort((a, b) => ((a.firstName ?? '') + ' ' + (a.lastName ?? '')).localeCompare((b.firstName ?? '') + ' ' + (b.lastName ?? ''), 'cs', { sensitivity: 'base' } )),
      tipsters: this.usersService.items
        .filter(u => u.role !== 'user' && u.role !== 'affiliate' && !u.isBlocked)
        .sort((a, b) => ((a.firstName ?? '') + ' ' + (a.lastName ?? '')).localeCompare((b.firstName ?? '') + ' ' + (b.lastName ?? ''), 'cs', { sensitivity: 'base' } )),
      affiliates: this.usersService.items
        .filter(u => u.role === 'affiliate')
        .sort((a, b) => ((a.firstName ?? '') + ' ' + (a.lastName ?? '')).localeCompare((b.firstName ?? '') + ' ' + (b.lastName ?? ''), 'cs', { sensitivity: 'base' } )),
    }).subscribe(async (newDemand) => {
      if (newDemand) {
        this.mainService.showLoading();
        const demand = await this.demandsService.create(newDemand);

        if (newDemand.tipsterCommission) {
          const casesService = this.getService(demand._pipeline?.url);
          const entitlements = { userId: newDemand.tipsterId, commissionShare: newDemand.tipsterCommission, note: '' };
          await casesService.patch(demand.dealId, { $push: {commissionsEntitlements: entitlements }});
        }

        if ((demand.ownerId && demand.ownerId !== demand._deal?.ownerId) || (demand.authorId && demand.authorId !== demand._deal?.ownerId)) {
          const newOwner = await this.usersService.get(demand._deal?.ownerId);
          this.dialogsService.alert('Případ má jiného vlastníka', 'Tento případ byl přiřazen jinému vlastníkovi: ' + newOwner.firstName + ' ' + newOwner.lastName);
        }
        this.router.navigate(['/cases', demand._pipeline?.url, demand.dealId]);
        this.mainService.hideLoading();
      }
    });
  }

  public async changePriority(deal) {
    this.dialogsService.priority('Změna priority', '', this.settingsService.options['priority'], deal.priority).subscribe(async (priority) => {
      if (priority !== null && deal.priority !== priority) {
        this.mainService.showLoading();
        await this.getService(deal._pipelineUrl).patch(deal._id, {priority});
        this.mainService.hideLoading();
      }
    });
  }

  public async changeOwner(deals: any[]) {
    const _ids = deals.map((deal) => deal._id);
    const _deals = await this.find( { query: { _id: { $in: _ids }, $limit: 1000 } });

    const contacts = _deals.data.map(deal => deal._contacts.find((c: Contact) => c._id === deal.participants[0].contactId));
    const stages = this.pipelinesService.pipelines.reduce((acc, value) => {
      acc[value.url] = value.stages;
      return acc;
    }, {});
    await this.contactsService.changeOwner(contacts, stages, deals[0]._pipelineUrl);
  }

  private getService(pipelineUrl) {
    if (pipelineUrl === 'car-insurance') {
      return this.carInsurancesService;
    }
    if (pipelineUrl === 'life-insurance') {
      return this.lifeInsurancesService;
    }
    if (pipelineUrl === 'real-estate-insurance') {
      return this.realEstateInsurancesService;
    }
  }

  public async dialPhone(event: any, deal: any, origin: string) {
    const param: DialNumberParam = {
      countryCode: event.countryCode,
      number: event.phoneNumber,
      contactName: event.contactName,
      contactId: deal.contacts[0],
      origin,
      dealId: deal._id,
      pipelineId: deal._pipelineId,
      dealOwnerId: deal.ownerId,
      stage: deal.stage
    };
    await this.callsService.initiateCall(param);
  }

  public async videoCall(deal: CarInsurance | RealEstateInsurance | LifeInsurance) {
    this.dialogsService.videoCall().subscribe(async (actType) => {
      if (actType) {
        const activity = {
          'pipelineId': deal._pipelineId,
          'dealId': deal._id,
          'userId': this.usersService.user._id,
          'type': actType,
        };
        await this.activityService.create(activity);
        this.snackbarService.showSuccess('Úspěšně zaznamenáno.');
      }
    });
  }
}
