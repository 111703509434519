import { Pipe, PipeTransform } from '@angular/core';
import {DatePipe} from '@angular/common';

@Pipe({
  name: 'dateDesc'
})
export class DateDescPipe implements PipeTransform {
  constructor(
    private datePipe: DatePipe,
  ) {
  }
  transform(value: string | Date, includeDate = false): string {
    const date = new Date(value);
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    const daysAgo = Math.floor((today.getTime() - date.getTime()) / (24 * 60 * 60 * 1000));

    if (this.isSameDay(date, today)) {
      return 'Dnes';
    } else if (this.isSameDay(date, yesterday)) {
      return 'Včera' + (includeDate ? `, ${this.datePipe.transform(date, 'dd.MM.yyyy')}` : '');
    } else if (daysAgo > 1 && daysAgo < 8) {
      return this.getDayOfWeek(date) + (includeDate ? `, ${this.datePipe.transform(date, 'dd.MM.yyyy')}` : '');
    } else {
      return `Před ${daysAgo} dny` + (includeDate ? `, ${this.datePipe.transform(date, 'dd.MM.yyyy')}` : '');
    }
  }

  private isSameDay(date1: Date, date2: Date): boolean {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  private getDayOfWeek(date: Date): string {
    const days = ['Neděle', 'Pondělí', 'Úterý', 'Středa', 'Čtvrtek', 'Pátek', 'Sobota'];
    return days[date.getDay()];
  }
}
