import {BrowserModule, Title} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {PlatformModule} from '@angular/cdk/platform';
import {ServiceWorkerModule} from '@angular/service-worker';
import {CoreModule} from '@app/modules/core.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {environment} from '@env/environment';

import {ROUTES} from './app.routes';

import {UipathService} from '@app/services/uipath.service';
import {SidenavComponent} from '@app/components/sidenav/sidenav.component';
import {LoginComponent} from '@app/components/login/login.component';
import {DashboardComponent} from '@app/components/dashboard/dashboard.component';
import {ProductsComponent} from '@app/components/products/products.component';
import {SalesComponent} from '@app/components/sales/sales.component';
import {SettingsComponent} from '@app/components/settings/settings.component';
import {OfferFormComponent} from '@app/components/deals/offer-form.component';
import {DealComponent} from '@app/components/deals/deal.component';
import {OfferComponent} from '@app/components/deals/offer.component';
import {OfferDocumentsComponent} from '@app/components/deals/offer-documents.component';
import {DealOfferPanelComponent} from '@app/components/deals/deal-offer-panel.component';
import {DealsComponent} from '@app/components/deals/deals.component';
import {MainComponent} from '@app/components/main.component';
import {OfferModelationsComponent} from '@app/components/deals/offer-modelations.component';
import {CalculationsComponent} from '@app/components/calculations/calculations.component';
import {UniqueOptionPipe} from '@app/pipes/unique-option.pipe';
import {MaxOfferParamPipe} from '@app/pipes/max-offer-param.pipe';
import {OfferVariantsCountPipe} from '@app/pipes/offer-variants-count.pipe';
import {OfferProvidersPipe} from '@app/pipes/offer-providers.pipe';
import {DocumentsOrderPipe} from '@app/pipes/documents-order.pipe';
import {CanDeactivateGuard} from '@app/guards/deactivate.guard';
import {LoginGuard} from '@app/guards/login.guard';
import {AdminGuard} from '@app/guards/admin.guard';
import {CompositeRouteGuard} from '@app/guards/composite.guard';
import {BackendService} from '@app/services/backend.service';
import {MainService} from '@app/services/main.service';
import {UsersService} from '@app/services/users.service';
import {UserGroupsService} from '@app/services/user-groups.service';
import {TitleService} from '@app/services/title.service';
import {SnackbarService} from '@app/services/snackbar.service';
import {SettingsService} from '@app/services/settings.service';
import {DialogsService} from '@app/services/dialogs.service';
import {DealsService} from '@app/services/deals.service';
import {DealResolver} from '@app/resolvers/deal.resolver';
import {OfferResolver} from '@app/resolvers/offer.resolver';
import {ProductsService} from '@app/services/products.service';
import {FilesService} from '@app/services/files.service';
import {DealProductsService} from '@app/services/dealproducts.service';
import {TokensService} from '@app/services/tokens.service';
import {OffersService} from '@app/services/offers.service';
import {AresService} from '@app/services/ares.service';
import {RuianService} from '@app/services/ruian.service';
import {ContactResolver} from '@app/resolvers/contact.resolver';
import {CampaignResolver} from '@app/resolvers/campaign.resolver';
import {AppComponent} from '@app/app.component';
import {ModalInfoDialogComponent} from '@app/components/dialogs/modalinfo-dialog.component';
import {ConfirmDialogComponent} from '@app/components/dialogs/confirm-dialog.component';
import {PriorityDialogComponent} from '@app/components/dialogs/priority-dialog.component';
import {DeactivateDialogComponent} from '@app/components/dialogs/deactivate-dialog.component';
import {CancelDialogComponent} from '@app/components/dialogs/cancel-dialog.component';
import {AlertDialogComponent} from '@app/components/dialogs/alert-dialog.component';
import {PostponeDialogComponent} from '@app/components/dialogs/postpone-dialog.component';
import {RestoreDialogComponent} from '@app/components/dialogs/restore-dialog.component';
import {OwnerDialogComponent} from '@app/components/dialogs/owner-dialog.component';
import {ScoreDialogComponent} from '@app/components/dialogs/score-dialog.component';
import {StageDialogComponent} from '@app/components/dialogs/stage-dialog.component';
import {RefreshDialogComponent} from '@app/components/dialogs/refresh-dialog.component';
import {DrawerDialogComponent} from '@app/components/dialogs/drawer-dialog.component';
import {PasswordDialogComponent} from '@app/components/dialogs/password-dialog.component';
import {AutoLogoutDialogComponent} from '@app/components/dialogs/autologout-dialog.component';
import {TemplateDialogComponent} from '@app/components/dialogs/template-dialog.component';
import {UipathDialogComponent} from '@app/components/dialogs/uipath-dialog.component';
import {DealDialogComponent} from '@app/components/dialogs/deal-dialog.component';
import {TaskDialogComponent} from '@app/components/dialogs/task-dialog.component';
import {DealTimelineComponent} from '@app/components/deals/deal-timeline.component';
import {DealTasksComponent} from '@app/components/deals/deal-tasks.component';
import {OfferCommissionsComponent} from '@app/components/deals/offer-commissions.component';
import {FeesDialogComponent} from '@app/components/dialogs/fees-dialog.component';
import {WatchdogDialogComponent} from '@app/components/dialogs/watchdog-dialog.component';
import {RestoreCaseDialogComponent} from '@app/components/dialogs/restore-case-dialog.component';
import {CancelCaseDialogComponent} from '@app/components/dialogs/cancel-case-dialog.component';
import {PostponeCaseDialogComponent} from '@app/components/dialogs/postpone-case-dialog.component';
import {NewDemandDialogComponent} from '@app/components/dialogs/new-demand-dialog.component';
import {FindUserDialogComponent} from '@app/components/dialogs/find-user-dialog.component';
import {CommPreferencesDialogComponent} from '@app/components/dialogs/comm-preferences-dialog.component';
import {PipelinesResolver} from '@app/resolvers/pipelines.resolver';
import {DialButtonModule} from '@app/components/dialbutton/dial-button.module';
import {CanDeactivateFormGuard} from '@app/guards/deactivate-form.guard';
import {InputMaskModule} from '@app/components/input-mask/input-mask.module';
import {CallingWidgetModule} from '@app/components/callingwidget/callingwidget.module';
import {FilePreviewModule} from '@app/components/file-preview/file-preview.module';
import {FileListModule} from '@app/components/file-list/file-list.module';
import {FileOperationsDialogComponent} from '@app/components/dialogs/file-operations-dialog.component';
import {FileChooseGroupDialogComponent} from '@app/components/dialogs/file-choose-group-dialog.component';
import {OfferPolicyListComponent} from './components/deals/offer-policy-list.component';
import {InputNumberModule} from '@app/components/input-number/input-number.module';
import {TimelineModule} from '@app/components/timeline/timeline.module';
import {TasksListModule} from '@app/components/tasks-list/tasks-list.module';
import {EmailDuplicityDialogComponent} from '@app/components/dialogs/email-duplicity-dialog.component';
import {PipesModule} from '@app/modules/pipes.module';
import {DocumentItemModule} from '@app/components/document-item/document-item.module';
import {ErrorDialogModule} from '@app/error-handler/error-dialog.module';
import {InfoDialogModule} from '@app/error-handler/info-dialog.module';
import {VideoCallDialogComponent} from '@app/components/dialogs/video-call-dialog.component';
import {BackendRestService} from '@app/services/backend-rest.service';
import {MagicLinksService} from '@app/services/magic-links-service';
import {RecaptchaV3Module} from 'ng-recaptcha';
import {RECAPTCHA_V3_SITE_KEY} from 'ng-recaptcha';
import {RecaptchaService} from '@app/services/recaptcha-service';
import {InterventionDialogComponent} from '@app/components/dialogs/intervention-dialog.component';
import {ChooseOwnerDialogComponent} from '@app/components/dialogs/choose-owner-dialog.component';
import { AvatarModule } from '@app/components/avatar/avatar.module';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    SidenavComponent,
    LoginComponent,
    DashboardComponent,
    ProductsComponent,
    SalesComponent,
    SettingsComponent,
    DealsComponent,
    DealComponent,
    OfferComponent,
    DealTimelineComponent,
    DealTasksComponent,
    DealOfferPanelComponent,
    OfferFormComponent,
    OfferDocumentsComponent,
    OfferModelationsComponent,
    OfferCommissionsComponent,
    CalculationsComponent,
    ModalInfoDialogComponent,
    ConfirmDialogComponent,
    PriorityDialogComponent,
    DeactivateDialogComponent,
    CancelDialogComponent,
    DealDialogComponent,
    FeesDialogComponent,
    AlertDialogComponent,
    PostponeDialogComponent,
    RestoreDialogComponent,
    TaskDialogComponent,
    OwnerDialogComponent,
    WatchdogDialogComponent,
    ScoreDialogComponent,
    StageDialogComponent,
    RefreshDialogComponent,
    DrawerDialogComponent,
    PasswordDialogComponent,
    AutoLogoutDialogComponent,
    TemplateDialogComponent,
    UipathDialogComponent,
    FileOperationsDialogComponent,
    FileChooseGroupDialogComponent,
    CommPreferencesDialogComponent,
    UniqueOptionPipe,
    MaxOfferParamPipe,
    OfferVariantsCountPipe,
    OfferProvidersPipe,
    DocumentsOrderPipe,
    RestoreCaseDialogComponent,
    CancelCaseDialogComponent,
    PostponeCaseDialogComponent,
    NewDemandDialogComponent,
    FindUserDialogComponent,
    CommPreferencesDialogComponent,
    OfferPolicyListComponent,
    EmailDuplicityDialogComponent,
    VideoCallDialogComponent,
    InterventionDialogComponent,
    ChooseOwnerDialogComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    PlatformModule,
    RouterModule.forRoot(ROUTES, {
      useHash: false,
      onSameUrlNavigation: 'reload',
      relativeLinkResolution: 'legacy',
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    DialButtonModule,
    FilePreviewModule,
    FileListModule,
    CallingWidgetModule,
    InputMaskModule,
    InputNumberModule,
    TimelineModule,
    TasksListModule,
    PipesModule,
    ErrorDialogModule,
    InfoDialogModule,
    DocumentItemModule,
    RecaptchaV3Module,
    AvatarModule,
  ],
  providers: [
    Title,
    CanDeactivateGuard,
    CanDeactivateFormGuard,
    LoginGuard,
    AdminGuard,
    CompositeRouteGuard,
    BackendService,
    BackendRestService,
    MainService,
    UsersService,
    UserGroupsService,
    TitleService,
    SnackbarService,
    SettingsService,
    DialogsService,
    DealsService,
    DealResolver,
    OfferResolver,
    ProductsService,
    DealProductsService,
    FilesService,
    OffersService,
    TokensService,
    AresService,
    RuianService,
    CampaignResolver,
    ContactResolver,
    UipathService,
    PipelinesResolver,
    MagicLinksService,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.captchaSiteKey,
    },
    RecaptchaService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
