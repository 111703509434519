<ng-container *ngIf="message?.direction === 'out'">
  <div [ngSwitch]="message.msgState">
    <span *ngSwitchCase="'unsubscribed'" class="font-semibold text-red-600">
      Email nebyl odeslán - odhlášen z komunikace
    </span>

    <span *ngSwitchCase="'failed'" class="font-semibold text-red-600">
      Email se nepodařilo odeslat
    </span>

    <span *ngSwitchCase="'queued'">
      Naplánováno odeslání
      <span *ngIf="message.sendAt">{{ message.sendAt | date:'shortDate':undefined:'cs-CS' }}</span>
    </span>

    <span *ngSwitchCase="'sent'" class="font-semibold">
      Email odeslán {{ message.sentAt | date:'shortDate':undefined:'cs-CS' }} {{message.sentAt | date:'HH:mm:ss'}}
    </span>

    <span *ngSwitchCase="'delivered'" class="font-semibold text-green-600">
      Email odeslán {{ message.sentAt | date:'shortDate':undefined:'cs-CS' }} {{message.sentAt | date:'HH:mm:ss'}}
      v pořádku doručen<span *ngIf="message.opened > 0">, otevřen {{message.opened}} krát</span><span *ngIf="message.clicked > 0">, kliknuto {{message.clicked}} krát</span>
    </span>

    <span *ngSwitchCase="'notDelivered'" class="font-semibold text-red-600">
      Email odeslaný {{ message.sentAt | date:'shortDate':undefined:'cs-CS' }} {{message.sentAt | date:'HH:mm:ss'}}
      <span *ngIf="message.status === 'bounce'">byl vrácen jako nedoručitelný</span>
      <span *ngIf="message.status === 'dropped'">byl zablokován emailovým serverem příjemce</span>
    </span>

    <span *ngSwitchDefault class="font-semibold text-red-600">
      {{ message.msgState }}
    </span>
  </div>
  <div *ngIf="infoQueuedMail && message.msgState === 'queued'" class="flex items-center text-amber-500 font-semibold gap-2">
    <mat-icon class="text-amber-500">warning</mat-icon>
    Díváte se na draft emailu, před odesláním se mohou změnit údaje jako aktuální sazba a podobně.
  </div>
</ng-container>
