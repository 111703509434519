import { Injectable } from '@angular/core';
import { DataService } from '@app/services/data.service';
import { Call, DialNumberParam } from '@app/models/calls.model';
import { UsersService } from '@app/services/users.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class CallsService extends DataService<Call> {

  public activeCall: Call = null;
  public incomingCall: Call = null;
  public $activeCall: BehaviorSubject<Call> = new BehaviorSubject(this.activeCall);
  public activeCall$: Observable<Call> = this.$activeCall.asObservable();
  public $incomingCall: BehaviorSubject<Call> = new BehaviorSubject(this.incomingCall);
  public incomingCall$: Observable<Call> = this.$incomingCall.asObservable();
  public $callInitiating: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public callInitiating$: Observable<boolean> = this.$callInitiating.asObservable();

  constructor(
    private usersService: UsersService,
  ) {
    super('calls');
    this.pagination = 20;
    this.sort = {
      createdAt: -1
    };
    this.query = {
      userId: this.usersService.user._id,
    };
    this.$items.subscribe(items => {
      this.activeCall = items.find(item => item.answeredAt !== undefined && item.finishedAt === undefined);
      // console.log('Bezdaktela - activeCall', this.activeCall);
      this.$activeCall.next(this.activeCall);
      this.incomingCall = items.find(item => item._id !== this.activeCall?._id && item.answeredAt === undefined && item.finishedAt === undefined && !item.ignored );
      // console.log('Bezdaktela - incomingCall', this.incomingCall);
      this.$incomingCall.next(this.incomingCall);
      this.$callInitiating.next(false);
    });
  }

  public async initiateCall(param: DialNumberParam): Promise<any> {
    try {
      this.$callInitiating.next(true);
      const payload: any = {
        direction: 'out',
        callContext: {
          url: window.location.href,
          origin: param.origin,
          pipelineId: param.pipelineId,
          dealId: param.dealId,
          dealOwnerId: param.dealOwnerId,
          stage: param.stage
        }
      };

      if (param.contactId && param.number && param.countryCode) {
        payload.contactId = param.contactId;
        payload.peer = { phoneNumber: `${param.countryCode}${param.number}` };
      } else if (param.contactId) {
        payload.contactId = param.contactId;
      } else {
        if (param.phoneNumber) {
          payload.peer = { phoneNumber: param.phoneNumber };
        } else {
          payload.peer = { phoneNumber: `${param.countryCode}${param.number}` };
        }
      }
      // console.log('Bezdaktela - Initiate call params', payload);
      const res = await this.create(payload);
      // console.log('Bezdaktela - Initiate call response', res);
    } catch (e) {
      this.$callInitiating.next(false);
      console.error('Bezdaktela - Initiate call error', e);
      return false;
    }
    return true;
  }

  /*
   * Vrátí true, pokud uživatel používá kontaktní centrum
   */
  public isContactCentre(): boolean {
    return true;
  }

  /*
   * Vrátí true, pokud uživatel používá kontaktní centrum a je připojen
   */
  public isConnected(): boolean {
    return true;
  }

  /*
   * Vrátí true, pokud právě probíhá hovor přes kontaktní centrum
   */
  public isOngoingCall(): boolean {
    return false;
  }

  /*
   * Pokud uživatel nepoužívá Daktelu, vrátí true -> dialNumber probíhá přes <a href="tel:123456789">
   * Pokud uživatel používá Daktelu, tak vrátí true pokud je připojen v komunikátoru a zároveň právě neprobíhá hovor
   */
  public canDialNumber(): boolean {
    if (this.isContactCentre()) {
      return (this.isConnected() && !this.isOngoingCall());
    } else {
      return true;
    }
  }

  /*
   * Odmítnutí příchozího hovoru
   */
  async hangUpCall(call: Call) {
    // console.log('Bezdaktela - Hang up params', call);
    try {
      const res = await this.patch(call._id, {hangup: true});
      // console.log('Bezdaktela - Hang up result', res);
    } catch (e) {
      console.error('Bezdaktela - Hang up error', e);
    }
  }

  async ignoreCall(call: Call) {
    // console.log('Bezdaktela - Ignore call params', call);
    try {
      const res = await this.patch(call._id, {ignored: true});
      // console.log('Bezdaktela - Ignore call result', res);
    } catch (e) {
      console.error('Bezdaktela - Ignore call error', e);
    }
  }
}
