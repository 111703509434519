import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { UsersService } from '@app/services/users.service';
import { UserInterface } from '@app/interfaces/user.interface';
import { AffiliatesService } from '@app/services/affiliates.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ContactsService } from '@app/services/contacts.service';
import { map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { TipsterCommisionService } from '@app/services/tipster-commision.service';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-dialog',
  templateUrl: './deal-dialog.component.html',
})
export class DealDialogComponent implements OnInit {

  public user: UserInterface;
  public existingContact = null;
  public deal: any;
  public offerId: string;
  public owners: any[];
  public tipsters: any[];
  public affiliates: any[];
  public options: any;
  public newDeal: any;
  public filteredUsers: BehaviorSubject<any> = new BehaviorSubject([]);
  public filteredContacts: Observable<any> = new Observable<any>();
  public contactSelectionDone = false;
  public selectedContact = null;

  constructor(
    public dialogRef: MatDialogRef<DealDialogComponent>,
    public affiliatesService: AffiliatesService,
    private usersService: UsersService,
    public contactsService: ContactsService,
    public tipsterCommisionService: TipsterCommisionService,
    private datePipe: DatePipe
  ) {
    this.filteredContacts = this.contactsService.items$.pipe(
      map((contacts: any[]) => contacts.map((contact: any) => {
        contact.phoneVerified = contact.phone.some((p: any) => p.isVerified);
        return contact;
      }).sort((a: any, b: any) => {
        if (a.emailVerified && a.phoneVerified && !b.emailVerified && !b.phoneVerified) {
          return -1;
        }
        if (!a.emailVerified && !a.phoneVerified && b.emailVerified && b.phoneVerified) {
          return 1;
        }

        // Secondary sorting: At least one of them is true
        if ((a.emailVerified || a.phoneVerified) && !(b.emailVerified || b.phoneVerified)) {
          return -1;
        }
        if (!(a.emailVerified || a.phoneVerified) && (b.emailVerified || b.phoneVerified)) {
          return 1;
        }
      }).map((contact: any) => {
        let fields = [];
        let subfields = [];
        fields.push(((contact.firstName || '') + ' ' + (contact.lastName || '')).trim());
        fields.push(contact.birthDate ? (new Date (contact.birthDate)).getFullYear() : '');
        fields.push(contact.email || '');
        subfields.push(contact.address?.permanent?.city || '');
        subfields.push(`(${this.datePipe.transform(contact.createdAt, 'shortDate')})`);
        fields = fields.filter((field: string) => field);
        subfields = subfields.filter((field: string) => field);
        return {
          _id: contact._id,
          label: fields.join(', ') || `K${contact.numberId}`,
          sublabel: subfields.join(', '),
          emailVerified: contact.emailVerified,
          phoneVerified: contact.phoneVerified
        };
      }).filter((contact: any) => contact.label || contact.sublabel))
    );
    this.filteredContacts.subscribe((contacts: any[]) => {
      if (contacts.length) {
        this.selectedContact = contacts[0]?._id;
      } else {
        this.selectedContact = null;
      }
    });
  }

  ngOnInit() {
    this.selectedContact = null;
    this.contactSelectionDone = false;
    this.contactsService.$items.next([]);
    this.existingContact = this.deal?._offers?.[0]._applicants?.[0] || this.deal?._contacts?.find(c => c._id === this.deal?.participants?.[0]?.contactId) || null;
    let newOwnerId = this.user.role === 'specialist' ? this.user._id : this.deal?.ownerId ? this.deal.ownerId : '';
    if (newOwnerId && !this.owners.find(o => o._id === newOwnerId)) {
      newOwnerId = '';
    }
    this.newDeal = {
      type: 'new',
      source: 'portal',
      affiliateId: null,
      authorId: null,
      ownerId: newOwnerId,
      amount: null,
      value: null,
      purpose: null,
      propertyType: null,
      fixation: 5,
      maturity: 360,
      maturityMonths: 0,
      maturityYears : 30,
      timeHorizon: null,
      solveOnline: true,
      pipeline: 'mortgage',
      note: '',
      contacts: {
        name: this.existingContact ? this.existingContact.firstName + ' ' + this.existingContact.lastName : null,
        zipCode: this.existingContact ? this.existingContact.address?.permanent?.zipCode : null,
        phoneCountryCode: this.existingContact ? this.existingContact.phone[0]?.countryCode : '+420',
        phoneNumber: this.existingContact ? this.existingContact.phone[0]?.number : null,
        email: this.existingContact ? this.existingContact.email : null,
      },
      whereDidHear: null,
      refinancedProduct: {
        bankCode: null,
        interestRate: null,
        amount: null,
        signatureDate: null,
        fixation: null,
        repayment: null
      },
      sourceType: 'hnm',
      tipsterId: null,
      tipsterRole: null,
      tipsterCommission: 0,
      isPlatform: true
    };
    if (this.deal) {
      this.newDeal.sourceDeal = {
        pipelineId: this.deal?._pipelineId,
        dealId: this.deal?._id,
        contactsId: this.existingContact ? [this.existingContact._id] : [],
        offerId: this.offerId,
        stage: this.deal?.stage
      };
    }
  }

  onTypeChange(type: string) {
    this.newDeal.purpose = type === 'refinancing' ? 'refinancing' : null;
  }

  updateMaturity() {
    const mYears = parseInt(this.newDeal.maturityYears, null) || 0;
    const mMonths = parseInt(this.newDeal.maturityMonths, null) || 0;
    this.newDeal.maturity = (mYears * 12) + mMonths;
  }

  // async filterUsers(search: string) {
  //   if (search.length > 0) {
  //     const users = await this.usersService.find({
  //       query: {
  //         $limit: 20,
  //         role: { $ne: 'user' },
  //         isBlocked: false,
  //         email: {$regex: search, $options: 'i'},
  //         $select: ['_id', 'email', 'cases'],
  //       }
  //     });
  //     this.filteredUsers.next(users);
  //   } else {
  //     this.filteredUsers.next([]);
  //   }
  //   this.newDeal.tipsterId = null;
  // }

  public filterContacts(control: any) {
    if (!this.contactSelectionDone) {
      const countryCode = control.get('contactPhoneCountryCode')?.value;
      const phoneNumber = control.get('contactPhoneNumber')?.value;
      const email = control.get('contactEmail')?.value;
      if (phoneNumber) {
        this.contactsService.filter = {
          phone: {
            $elemMatch: {
              countryCode,
              number: phoneNumber
            }
          },
        };
        if (email) {
          this.contactsService.filter.email = email;
        }
        this.contactsService.load(0);
      }
    }
  }

  public selectContact(fill = false) {
    if (fill) {
      this.existingContact = {_id: this.selectedContact};
      this.newDeal.sourceDeal = {
        contactsId: this.existingContact ? [this.existingContact._id] : [],
      };
    }
    this.contactSelectionDone = true;
  }

  backToContactSelection() {
    this.existingContact = null;
    this.newDeal.sourceDeal = {
      contactsId: [],
    };
    this.contactSelectionDone = false;
  }

  confirmDialog() {
    if (!this.newDeal.authorId) {
      this.newDeal.authorId = this.user._id;
    }
    if (!this.newDeal.ownerId) {
      this.newDeal.ownerId = null;
    }
    this.dialogRef.close(this.newDeal);
  }

  public async sourceTypeChanged(event: MatSelectChange) {
    let recalc = false;
    switch (event.value) {
      case 'hnm':
        this.newDeal = {...this.newDeal, sourceType: 'hnm', affiliateId: null, isOwnDeal: false, tipsterId: null, tipsterRole: null, tipsterCommission: null};
        break;
      case 'affiliate':
        this.newDeal = {...this.newDeal, sourceType: 'affiliate', tipsterId: null, tipsterRole: null, tipsterCommission: null, isOwnDeal: false};
        break;
      case 'hnmrecommendation':
        this.newDeal = {...this.newDeal, sourceType: 'hnmrecommendation', affiliateId: null, isOwnDeal: false};
        recalc = true;
        break;
      case 'personal':
        this.newDeal = {...this.newDeal, sourceType: 'personal', affiliateId: null, isOwnDeal: false};
        recalc = true;
        break;
      case 'employee':
        this.newDeal = {...this.newDeal, sourceType: 'employee', affiliateId: null, isOwnDeal: true};
        recalc = true;
        break;
    }

    if (recalc) {
      this.newDeal.tipsterCommission = await this.getCommission();
    }
  }

  public async tipsterChanged(event: MatSelectChange) {
    const tipster = this.tipsters.find(t => t._id === event.value);
    if (tipster.cases?.mortgage) {
      this.newDeal.tipsterRole = 'mortgage';
    } else if (tipster.cases?.['car-insurance'] || tipster.cases?.['life-insurance'] || tipster.cases?.['real-estate-insurance']) {
      this.newDeal.tipsterRole = 'insurance';
    } else {
      this.newDeal.tipsterRole = 'other';
    }
    this.newDeal.tipsterId = tipster._id;
    this.newDeal.tipsterCommission = await this.getCommission();
  }

  public async getCommission(): Promise<number | null> {
    return await this.tipsterCommisionService.getCommision(this.newDeal.pipeline, this.newDeal.sourceType, this.newDeal.tipsterRole);
  }

}
