import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@app/modules/core.module';
import { EmailStatusComponent } from '@app/components/email-status/email-status.component';

@NgModule({
  declarations: [EmailStatusComponent],
  imports: [CommonModule, CoreModule],
  exports: [EmailStatusComponent],
})
export class EmailStatusModule {}
