import { Component, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { MessagesTextService } from '@app/services/messages-text.service';
import { Message } from '@app/models/message.model';

@Component({
  selector: 'app-message-preview',
  templateUrl: './message-preview.component.html',
  styleUrls: ['./message-preview.component.scss']
})
export class MessagePreviewComponent implements OnChanges {

  public htmlContent: SafeHtml;

  @Input() public message: Message;
  @Input() public visible = false;
  @Output() readonly visibleChange: EventEmitter<boolean> = new EventEmitter();
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(_event: KeyboardEvent) {
    if (this.visible) {
      this.closeDialog();
    }
  }

  constructor(private messagesTextService: MessagesTextService,
              private sanitizer: DomSanitizer) {
  }

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.visible) {
      const messageBody = await this.messagesTextService.get(this.message._id);
      this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(messageBody);
    }
  }

  public closeDialog(): void {
    this.htmlContent = '';
    this.visibleChange.emit(false);
  }

}
