import {
  AfterViewInit, Directive, ElementRef, EventEmitter, Output } from '@angular/core';

@Directive({
  selector: '[chat-item]',
})
export class ChatItemDirective implements AfterViewInit {

  @Output() readonly loaded: EventEmitter<ElementRef> = new EventEmitter<ElementRef>();

  constructor(
    private el: ElementRef
  ) {}

  public ngAfterViewInit() {
    this.loaded.emit(this.el);
  }
}
