import { Component, Input } from '@angular/core';

import { Message } from '@app/models/message.model';

@Component({
  selector: 'app-email-status',
  templateUrl: './email-status.component.html',
  styleUrls: ['./email-status.component.scss']
})
export class EmailStatusComponent {
  @Input() public message: Message;
  @Input() public infoQueuedMail = false;
}
