import { Pipe, PipeTransform } from '@angular/core';
import { Call } from '@app/models/calls.model';

@Pipe({ name: 'callsPhoneNumber'})
export class CallsPhoneNumberPipe implements PipeTransform {

  transform(call: Call) {
    if (call.direction === 'in') {
      return call.caller?.phoneNumber;
    } else {
      return call.peer?.phoneNumber;
    }
  }
}
