<ng-container *ngIf="visible">
  <p-sidebar [(visible)]="visible"
             [showCloseIcon]="false"
             [closeOnEscape]="true"
             [style]="{ width: '800px' }"
             (onHide)="closeDialog()"
             position="right">

    <div class="flex flex-row justify-between items-center pl-2 py-2">
      <div class="mat-dialog-title">{{message.subject}}</div>
      <button mat-icon-button
              class="close"
              color="warn"
              title="Zavřít"
              (click)="closeDialog()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div class="flex w-full flex-col gap-2 px-2 ">
      <div>
        <span>Adresát: </span>
        <span class="font-semibold ml-2">{{message.to}}</span>
      </div>
      <div>
        <app-email-status [message]="message" [infoQueuedMail]="true"></app-email-status>
      </div>
    </div>

    <div class="border-b border-b-slate-400 border-solid mt-4 mx-2"></div>

    <div [innerHTML]="htmlContent"></div>

  </p-sidebar>
</ng-container>
