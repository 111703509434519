import { Injectable } from '@angular/core';
import { DataService } from '@app/services/data.service';
import { UsersService } from '@app/services/users.service';
import { Conversation } from '@app/models/conversation.model';
import { ConversationsService } from '@app/services/conversations.service';

@Injectable({providedIn: 'root'})
export class MissedConversationsService extends DataService<Conversation> {
  constructor(
    private usersService: UsersService,
    private conversationsService: ConversationsService
  ) {
    super('conversations');
    this.pagination = 0;
    this.query = {
      userId: this.usersService.user._id,
      seen: { $ne: true },
    };
    this.load(0);
    this.conversationsService.updated$.subscribe(() => {
      this.load(0);
    });
  }
}
