import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Call } from '@app/models/calls.model';

@Component({
  selector: 'app-call-icon',
  templateUrl: './call-icon.component.html',
  styleUrls: ['./call-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CallIconComponent {
  @Input() call: Call;
}
