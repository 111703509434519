import { Injectable } from '@angular/core';
import { DataService } from '@app/services/data.service';

@Injectable({providedIn: 'root'})
export class MessagesTextService extends DataService<any> {

  constructor(
  ) {
    super('messages/text');
    this.pagination = 20;
  }

}
