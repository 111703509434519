import { Injectable } from '@angular/core';
import {DataService} from './data.service';
import {Intervention} from '@app/models/intervention.model';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MainService} from '@app/services/main.service';

@Injectable({providedIn: 'root'})
export class InterventionsService extends DataService<Intervention> {
  constructor(
    private fb: FormBuilder,
    public mainService: MainService
  ) {
    super('interventions');
    this.sort = {
      resolveAt: 1,
    };
  }

  public createForm(intervention): FormGroup {
    const form = this.fb.group({
      _id: [null],
      providerId: [null],
      intervention: this.fb.group({
        type: [null, [this.mainService.requireSelectionValidator('interventionType', null)]],
      }),
      counterProposal: this.fb.group({
        type: [null, [this.mainService.requireSelectionValidator('counterProposalType', null)]],
        amount: [0]
      }),
      isCounterProposal: [false],
      receivedAt: [null],
      resolveAt: [null],
      resolvedAt: [null],
      note: [null],
      createdAt: [null],
      updatedAt: [null],
    });
    form.get('intervention.type').disable();
    form.get('counterProposal.type').disable();
    if (intervention) {
      form.patchValue(intervention);
      form.get('isCounterProposal').patchValue(!!intervention.counterProposal);
    }
    return form;
  }

  public unmask(intervention) {
    intervention.counterProposal.amount = this.mainService.unmaskCurrency(intervention.counterProposal.amount);
    if (!intervention.isCounterProposal) {
      delete intervention.counterProposal;
    } else {
      delete intervention.intervention;
    }
    delete intervention.isCounterProposal;
    return intervention;
  }

  public async restartIntervention(intervention: Intervention) {

  }
  public async completeIntervention(intervention: Intervention) {

  }
}
