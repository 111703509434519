<div class="deal-toolbar" [ngClass]="{'activated': deal._isActivated}">
  <div class="title">
    <app-avatar [id]="deal.ownerId" class="mr-2"></app-avatar>
    <ng-container [ngTemplateOutlet]="priorityTmpl" [ngTemplateOutletContext]="deal"></ng-container>
    <div class="name"><a target="_blank" [href]="'https://app.hubspot.com/contacts/3999209/deal/' + deal.hubspot?.dealId">{{ deal | caseNumber }} {{ deal.name }} </a></div>
    <div class="status">{{ settingsService.settings.stages[deal.stage] }} <span *ngIf="deal.stage === 'postponed'">({{ deal.postponedUntil ? (deal.postponedUntil | date:'shortDate':undefined:'cs-CS') : 'neurčito' }})</span></div>
  </div>
  <button mat-icon-button *ngIf="deal.isWatchdog" (click)="dealsService.watchdog(deal)"><mat-icon>alarm</mat-icon></button>
  <button mat-icon-button [matMenuTriggerFor]="dealMenu"><mat-icon>more_vert</mat-icon></button>
</div>
<ng-template #priorityTmpl let-priority="priority">
  <mat-icon *ngIf="priority === 2" color="warn" class="priority" svgIcon="priority-highest"></mat-icon>
  <mat-icon *ngIf="priority === 1" color="warn" class="priority" svgIcon="priority-high"></mat-icon>
  <mat-icon *ngIf="priority === 0" color="primary" class="priority" svgIcon="priority-medium"></mat-icon>
  <mat-icon *ngIf="priority === -1" color="accent" class="priority" svgIcon="priority-low"></mat-icon>
  <mat-icon *ngIf="priority === -2" color="accent" class="priority" svgIcon="priority-lowest"></mat-icon>
</ng-template>
<div class="offer-toolbar card-title" [ngClass]="{canceled: offer.isCanceled}">
  <div class="title">
    <mat-icon *ngIf="offersService.hasCandidate(offer) && !offersService.hasConfirmed(offer)">how_to_reg</mat-icon>
    <mat-icon *ngIf="offersService.hasConfirmed(offer) && !offersService.hasFinal(offer)">done</mat-icon>
    <mat-icon *ngIf="offersService.hasFinal(offer)">done_all</mat-icon>
    <mat-icon *ngIf="!offersService.hasCandidate(offer) && !offersService.hasFinal(offer) && !offersService.hasConfirmed(offer)">notes</mat-icon>
    <div class="name" [matMenuTriggerFor]="selectOfferMenu">N{{ offer.numberId }} ({{ offer.fillingScore }})</div>
  </div>
  <div class="status">
    <span>{{ offersService.offerStageText(offer) }}</span>
  </div>
  <button mat-icon-button [matMenuTriggerFor]="offerMenu" *ngIf="!showComments"><mat-icon>more_vert</mat-icon></button>
  <button mat-icon-button (click)="toggleComments()" *ngIf="showComments"><mat-icon>close</mat-icon></button>
</div>
<div class="offer-info" [formGroup]="offersService.offerForm">
  <ng-container *ngIf="!showComments">
    <div class="form-row" *ngIf="deal._affiliate">
      <mat-form-field class="readonly">
        <input matInput placeholder="Zdroj obchodu" [value]="deal._affiliate.name" readonly/>
      </mat-form-field>
    </div>
    <div class="form-row" *ngIf="offersService.offerForm.get('note.specialist').value">
      <mat-form-field class="readonly">
        <textarea matInput placeholder="Poznámka - Specialista" cdkTextareaAutosize cdkAutosizeMinRows="1" [value]="offersService.offerForm.get('note.specialist').value" readonly></textarea>
      </mat-form-field>
    </div>
    <div class="form-row" *ngIf="offersService.offerForm.get('note.operator').value">
      <mat-form-field class="readonly">
        <textarea matInput placeholder="Poznámka - Nabídky" cdkTextareaAutosize cdkAutosizeMinRows="1" [value]="offersService.offerForm.get('note.operator').value" readonly></textarea>
      </mat-form-field>
    </div>
    <div class="form-row" *ngIf="offersService.offerForm.get('note.support').value">
      <mat-form-field class="readonly">
        <textarea matInput placeholder="Poznámka - Podpora" cdkTextareaAutosize cdkAutosizeMinRows="1" [value]="offersService.offerForm.get('note.support').value" readonly></textarea>
      </mat-form-field>
    </div>
    <div class="form-row" *ngIf="offersService.offerForm.get('note.drawer').value">
      <mat-form-field class="readonly">
        <textarea matInput placeholder="Poznámka - Čerpání" cdkTextareaAutosize cdkAutosizeMinRows="1" [value]="offersService.offerForm.get('note.drawer').value" readonly></textarea>
      </mat-form-field>
    </div>
    <div class="form-row" *ngIf="deal.stage === 'canceled'">
      <mat-form-field class="readonly">
        <input matInput placeholder="Důvod zamítnutí" [value]="dealsService.cancelReason()" readonly/>
      </mat-form-field>
    </div>
    <div class="form-row" *ngIf="deal.stage === 'postponed'">
      <mat-form-field class="readonly">
        <input matInput placeholder="Důvod odložení" [value]="dealsService.postponeReason()" readonly/>
      </mat-form-field>
    </div>
    <div class="form-row">
      <mat-form-field class="readonly">
        <mat-chip-list #chipListPurpose>
          <mat-chip *ngFor="let chip of offersService.offerForm.get('purpose').value" [selectable]="false" [removable]="false" [value]="chip" color="default">{{ mainService.optionLabel(options('purpose'), chip)}}</mat-chip>
        </mat-chip-list>
        <input placeholder="Účel hypotéky" [matChipInputFor]="chipListPurpose" [disabled]="true"/>
      </mat-form-field>
    </div>
    <div class="form-row" *ngIf="!deal.isPlatformChange && leadInfo.length">
      <mat-form-field class="readonly">
        <mat-label>Informace o leadu</mat-label>
        <mat-chip-list>
          <mat-chip *ngFor="let lead of leadInfo" [selectable]="false" [removable]="false" [value]="lead" color="default">{{lead}}</mat-chip>
        </mat-chip-list>
      </mat-form-field>
    </div>
    <div class="detail-cards">
      <div class="detail-card-title"><span>Nemovitosti ({{ offersService._properties.length }})</span></div>
      <div class="detail-card">
        <mat-accordion formArrayName="properties">
          <ng-container *ngFor="let property of offersService._properties; let pIndex = index;">
            <mat-expansion-panel *ngIf="offersService.propertyOfferControl(property.get('_id').value) as offerProperty" [formGroup]="property" [ngClass]="{'blue': offerProperty.get('status').value.includes('funded')}">
              <mat-expansion-panel-header>
                <mat-panel-title class="rows">
                  <div class="elipsis"><span>{{ mainService.optionLabel(options('propertyType'), property.get('type').value) }}</span></div>
                  <div>{{ mainService.maskCurrency(mainService.unmaskCurrency(property.get('value').value)) }} Kč</div>
                </mat-panel-title>
                <div>{{ offersService.propertyUsage(offerProperty, true) }}</div>
              </mat-expansion-panel-header>
              <ng-template matExpansionPanelContent>
                <div class="form-row">
                  <mat-form-field>
                    <mat-select placeholder="Typ nemovitosti" formControlName="type" >
                      <mat-option *ngFor="let option of options('propertyType')" [value]="option.value">{{ option.label }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="w50">
                    <input matInput placeholder="Hodnota" formControlName="value" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                  </mat-form-field>
                </div>
                <ng-container *ngIf="offersService.propertyHasOwner(offerProperty.get('_id')?.value)">
                  <div class="form-label">Vlastníci</div>
                  <ul class="form-list">
                    <ng-container *ngFor="let applicant of offersService._applicants; let aIndex = index;">
                      <li *ngIf="offersService.isPropertyOwner(offerProperty.get('_id')?.value, applicant.get('_id').value)"><span>{{ applicant.get('titleBefore').value }} {{ applicant.get('firstName').value }} {{ applicant.get('lastName').value }} {{ applicant.get('titleAfter').value }} (Žadatel {{ aIndex + 1 }})</span></li>
                    </ng-container>
                  </ul>
                </ng-container>
              </ng-template>
            </mat-expansion-panel>
          </ng-container>
        </mat-accordion>
      </div>
      <div class="detail-card-title"><span>Žadatelé ({{ offersService._applicants.length }})</span></div>
      <div class="detail-card">
        <mat-accordion>
          <mat-expansion-panel class="blue" *ngFor="let applicant of offersService._applicants; let aIndex = index;" [id]="'Rapplicant' + aIndex" (afterExpand)="mainService.scrollToId('Rapplicant' + aIndex)">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="elipsis"><mat-icon class="verification person-verification" [ngClass]="applicant.get('verifiedStatus').value">verified_user</mat-icon><span>{{ applicant.get('titleBefore').value }} {{ applicant.get('firstName').value }} {{ applicant.get('lastName').value }} {{ applicant.get('titleAfter').value }}</span></div>
                <div>{{ applicant.get('age').value }} let</div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
              <div class="card">
                <div class="card-header">Základní údaje</div>
                <div class="form-row">
                  <mat-form-field class="readonly">
                    <input matInput placeholder="Jméno" [value]="applicant.get('firstName').value" readonly/>
                  </mat-form-field>
                  <mat-form-field class="readonly">
                    <input matInput placeholder="Příjmení" [value]="applicant.get('lastName').value" readonly/>
                  </mat-form-field>
                </div>
                <div class="form-row">
                  <mat-form-field class="readonly">
                    <input matInput placeholder="Pohlaví" [value]="mainService.optionLabel(options('sex'), applicant.get('sex').value)" readonly/>
                  </mat-form-field>
                  <mat-form-field class="readonly">
                    <input matInput placeholder="Rodinný stav" [value]="mainService.optionLabel(options('familyStatus'), applicant.get('familyStatus').value)" readonly/>
                  </mat-form-field>
                  <mat-form-field class="readonly">
                    <input matInput placeholder="Datum narození" [value]="applicant.get('birthDate').value | date:'shortDate':undefined:'cs-CS'" readonly/>
                  </mat-form-field>
                </div>
                <div class="form-row">
                  <mat-form-field class="readonly">
                    <input matInput placeholder="Státní příslušnost" [value]="mainService.optionLabel(options('country'), applicant.get('nationality').value)" readonly/>
                  </mat-form-field>
                  <mat-form-field class="readonly">
                    <input matInput placeholder="Místo narození" [value]="mainService.optionLabel(options('country'), applicant.get('placeOfBirth.country').value)" readonly/>
                  </mat-form-field>
                </div>
                <div class="form-row">
<!--                  <mat-form-field class="readonly" *ngIf="aIndex !== 0">-->
<!--                    <input matInput placeholder="Vztah k prvnímu žadateli" [value]="mainService.optionLabel(options('relationToFirstApplicant'), applicant.get('relationToFirstApplicant').value)" readonly/>-->
<!--                  </mat-form-field>-->
                  <mat-form-field class="readonly">
                    <input matInput placeholder="Domovská banka" [value]="mainService.optionLabel(options('bank'), contactsService.getHomeBank(applicant))" readonly/>
                  </mat-form-field>
                </div>
              </div>
              <div class="card">
                <div class="card-header">Trvalé bydliště</div>
                <div class="form-row">
                  <mat-form-field class="readonly">
                    <input matInput type="number" placeholder="PSČ" [value]="applicant.get('address.permanent.zipCode').value" readonly/>
                  </mat-form-field>
                  <mat-form-field class="readonly">
                    <input matInput placeholder="Země" [value]="mainService.optionLabel(options('country'), applicant.get('address.permanent.country').value)" readonly/>
                  </mat-form-field>
                </div>
              </div>
              <div class="card">
                <div class="card-header">Příjmy</div>
                <mat-accordion>
                  <mat-expansion-panel *ngFor="let income of applicant.get('incomes').controls">
                    <mat-expansion-panel-header>
                      <mat-panel-title class="rows">
                        <div class="elipsis"><span>{{ mainService.optionLabel(options('incomeType'), income.get('type').value) }}</span></div>
                        <div>{{ mainService.maskCurrency(mainService.unmaskCurrency(income.get('amount').value)) }} {{ mainService.optionLabel(options('currency'), income.get('currency').value) }}</div>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                      <div class="form-row">
                        <mat-form-field class="readonly">
                          <input matInput placeholder="Typ příjmu" [value]="mainService.optionLabel(options('incomeType'), income.get('type').value)" readonly/>
                        </mat-form-field>
                      </div>
                      <div class="form-row">
                        <mat-form-field class="readonly">
                          <input matInput placeholder="Výše příjmu" [value]="mainService.maskCurrency(mainService.unmaskCurrency(income.get('amount').value))" readonly/>
                        </mat-form-field>
                      </div>
                      <div class="form-row">
                        <mat-form-field class="readonly">
                          <input matInput placeholder="Zaměstnán/a od" [value]="income.get('fromDate').value | date:'shortDate':undefined:'cs-CS'" readonly/>
                        </mat-form-field>
                        <mat-form-field class="readonly">
                          <input matInput placeholder="Lokace příjmu" [value]="mainService.optionLabel(options('incomeLocation'), income.get('location').value)" readonly/>
                        </mat-form-field>
                      </div>
                    </ng-template>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
              <div class="card" *ngIf="applicant.get('expenditures').value.length > 0">
                <div class="card-header">Výdaje</div>
                <mat-accordion>
                  <mat-expansion-panel *ngFor="let expenditure of applicant.get('expenditures').controls">
                    <mat-expansion-panel-header>
                      <mat-panel-title class="rows">
                        <div class="elipsis"><span>{{ mainService.optionLabel(options('expenditureType'), expenditure.get('type').value) }}</span></div>
                        <div *ngIf="expenditure.get('type').value !== 'overdraft' && expenditure.get('type').value !== 'creditCard'">{{ mainService.maskCurrency(mainService.unmaskCurrency(expenditure.get('payment').value)) }} {{ mainService.optionLabel(options('currency'), expenditure.get('currency').value) }}</div>
                        <div *ngIf="expenditure.get('type').value === 'overdraft' || expenditure.get('type').value === 'creditCard'">{{ mainService.maskCurrency(mainService.unmaskCurrency(expenditure.get('limit').value)) }} {{ mainService.optionLabel(options('currency'), expenditure.get('currency').value) }}</div>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                      <div class="form-row">
                        <mat-form-field class="readonly">
                          <input matInput placeholder="Typ výdaje" [value]="mainService.optionLabel(options('expenditureType'), expenditure.get('type').value)" readonly/>
                        </mat-form-field>
                      </div>
                      <div class="form-row">
                        <mat-form-field class="readonly">
                          <input *ngIf="expenditure.get('type').value !== 'overdraft' && expenditure.get('type').value !== 'creditCard'" matInput placeholder="Splátka" [value]="mainService.maskCurrency(mainService.unmaskCurrency(expenditure.get('payment').value))" readonly/>
                          <input *ngIf="expenditure.get('type').value === 'overdraft' || expenditure.get('type').value === 'creditCard'" matInput placeholder="Rámec" [value]="mainService.maskCurrency(mainService.unmaskCurrency(expenditure.get('limit').value))" readonly/>
                        </mat-form-field>
                        <mat-form-field class="readonly" *ngIf="expenditure.get('type').value !== 'overdraft' && expenditure.get('type').value !== 'creditCard' && expenditure.get('type').value !== 'alimony'">
                          <input matInput placeholder="Zůstatek" [value]="mainService.maskCurrency(mainService.unmaskCurrency(expenditure.get('balance').value))" readonly/>
                        </mat-form-field>
                      </div>
                      <div class="form-row">
                        <mat-form-field class="readonly">
                          <input matInput placeholder="Typ věřitele" [value]="mainService.optionLabel(options('creditorType'), expenditure.get('source.type').value)" readonly/>
                        </mat-form-field>
                        <mat-form-field class="readonly">
                          <input matInput placeholder="Název věřitele" [value]="expenditure.get('source.type').value === 'bank' ? mainService.optionLabel(options('bank'), expenditure.get('source.name').value) : expenditure.get('source.name').value" readonly/>
                        </mat-form-field>
                      </div>
                      <div class="form-row" *ngIf="expenditure.get('type').value !== 'alimony'">
                        <mat-form-field class="readonly">
                          <input matInput placeholder="Splacení závazku" [value]="mainService.optionLabel(options('expenditureRepayment'), expenditure.get('repayment').value)" readonly/>
                        </mat-form-field>
                      </div>
<!--                      <div class="form-row" *ngFor="let modelationId of (expenditure.get('modelationsId')?.value || [])">-->
<!--                        Modelace {{offersService.getModelationIndex(modelationId)}}-->
<!--                      </div>-->
                    </ng-template>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </ng-template>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="detail-card-title"><span>Domácnosti ({{ offersService._households.length }})</span></div>
      <div class="detail-card">
        <mat-accordion>
          <mat-expansion-panel *ngFor="let household of offersService._households; let hIndex = index;">
            <mat-expansion-panel-header>
              <mat-panel-title class="rows">
                <div class="elipsis"><span>{{ mainService.optionLabel(options('householdType'), household.get('type').value) }}</span></div>
                <div>Členů: {{ householdsService.householdMembersCount(household, offersService._applicants) }}</div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
              <div class="form-row">
                <mat-form-field class="readonly">
                  <input matInput placeholder="Typ domácnosti" [value]="mainService.optionLabel(options('householdType'), household.get('type').value)" readonly/>
                </mat-form-field>
              </div>
              <div class="form-label">Žadatelé</div>
              <ul class="form-list">
                <ng-container *ngFor="let applicant of offersService._applicants; let aIndex = index;">
                  <li *ngIf="offersService.householdHasApplicant(household.get('_id').value, applicant.get('_id').value)"><span>{{ applicant.get('titleBefore').value }} {{ applicant.get('firstName').value }} {{ applicant.get('lastName').value }} {{ applicant.get('titleAfter').value }} (Žadatel {{ aIndex + 1 }})</span></li>
                </ng-container>
              </ul>
              <ng-container *ngIf="householdsService.householdMembers(household).controls?.length > 0">
                <div class="form-label">Ostatní členové</div>
                <ul class="form-list">
                  <li *ngFor="let member of householdsService.householdMembers(household).controls"><span>{{ mainService.optionLabel(options('memberType'), member.get('type').value) }}</span><span>{{ member.get('age').value }} let</span></li>
                </ul>
              </ng-container>
            </ng-template>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="detail-card-title"><span>Pojištění</span></div>
      <div class="detail-card">
        <div class="card" *ngIf="offersService.offerForm.get('insuranceApplicantsId')?.value?.length">
          <ul class="form-list">
            <ng-container *ngFor="let applicant of offersService._applicants; let aIndex = index;">
              <li *ngIf="offersService.offerForm.get('insuranceApplicantsId').value.includes(applicant.get('_id').value)"><span>{{ applicant.get('titleBefore').value }} {{ applicant.get('firstName').value }} {{ applicant.get('lastName').value }} {{ applicant.get('titleAfter').value }} (Žadatel {{ aIndex + 1 }})</span></li>
            </ng-container>
          </ul>
        </div>
      </div>
      <div class="detail-card-title" *ngIf="offersService.isStageBetween(offer.stage, 'approved')"><span>Čerpání ({{ offersService.drawdownPercent() }}%)</span></div>
      <div class="detail-card" *ngIf="offersService.isStageBetween(offer.stage, 'approved')">
        <div class="card">
          <div class="form-row">
            <mat-form-field class="readonly">
              <input matInput placeholder="Den splátky" [value]="offersService.offerForm.get('repaymentDay').value ? offersService.offerForm.get('repaymentDay').value : null" readonly/>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field class="readonly">
              <input matInput placeholder="Datum prvního čerpání" [value]="offer._firstDrawdownDate | date:'shortDate':undefined:'cs-CS'" readonly/>
            </mat-form-field>
            <mat-form-field class="readonly">
              <input matInput placeholder="Datum posledního čerpání" [value]="offer._lastDrawdownDate | date:'shortDate':undefined:'cs-CS'" readonly/>
            </mat-form-field>
          </div>
        </div>
      </div>
<!--      <div class="detail-card-title"><span>Hromadné zadání zvýhodnění</span></div>-->
<!--      <div class="detail-card">-->
<!--        <div class="card">-->
<!--          <form (submit)="applyBenefits(benefitsForm)" #benefitsForm="ngForm">-->
<!--            <div class="form-row" *ngFor="let modelation of offersService.modelations.controls; let mIndex = index; trackBy: trackByFn;">-->
<!--              <mat-checkbox class="flex-0-0-auto" #m [name]="'check:' + mIndex" ngModel>M{{mIndex + 1}}</mat-checkbox>-->
<!--              <div class="benefit-variants" *ngIf="!m.checked">-->
<!--                <mat-checkbox *ngFor="let variant of modelation.get('variants').value; let vIndex = index; trackBy: trackByFn;" [name]="'check:' + mIndex + ':' + vIndex" ngModel>{{ variantIdentifier(mIndex, vIndex)}}</mat-checkbox>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="benefits-row">-->
<!--            <ng-container *ngFor="let option of (options('benefit')); let even = even">-->
<!--              <mat-form-field>-->
<!--                <input matInput [placeholder]="option.label" [name]="option.value" [textMask]="{mask: mainService.currencyMask, guide: false}" ngModel/>-->
<!--              </mat-form-field>-->
<!--            </ng-container>-->
<!--            </div>-->
<!--            <div class="form-row flex flex-row justify-around items-center">-->
<!--              <button type="button" mat-raised-button color="primary" (click)="resetForm(benefitsForm)">Vyčistit</button>-->
<!--              <button type="submit" mat-raised-button color="accent">Aplikovat</button>-->
<!--            </div>-->
<!--          </form>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <div class="form-row" *ngIf="deal.isPlatformChange && leadInfo.length">
      <mat-form-field class="readonly">
        <mat-label>Informace o leadu</mat-label>
        <mat-chip-list>
          <mat-chip *ngFor="let lead of leadInfo" [selectable]="false" [removable]="false" [value]="lead" color="default">{{lead}}</mat-chip>
        </mat-chip-list>
      </mat-form-field>
    </div>
  </ng-container>
  <ng-container *ngIf="showComments">
    <div class="comments-wrap">
      <div class="comments" *ngFor="let comment of offer.comments; let i = index;" [ngClass]="{'comments-operator': comment.role === 'operator','comments-approved': comment.stage === 'approved','comments-confirmed': comment.stage === 'confirmed','comments-canceled': comment.stage === 'canceled'}">
            <span>
              <b *ngIf="!comment.author && comment.role === 'operator'">Operátor/ka</b>
              <b *ngIf="!comment.author && comment.role !== 'operator'">Specialista</b>
              <b *ngIf="comment.author">{{ comment.author }}</b>
              {{ comment.createdAt | date:'shortDate':undefined:'cs-CS' }}</span>
        <span>{{ settingsService.settings.stages[comment.stage] }}</span>
        <p>{{ comment.text }}</p>
      </div>
    </div>
  </ng-container>
</div>
<div class="offer-comment" [formGroup]="offersService.offerForm" *ngIf="offersService.view === 'offer' && offer.stage && !offer.isCanceled && deal.stage !== 'canceled' && deal.stage !== 'postponed' && deal.stage !== 'drawdown'">
  <div class="text-input">
    <textarea placeholder="Komentář" matInput cdkTextareaAutosize formControlName="tempComment"></textarea>
  </div>
</div>
<div class="offer-comment" [formGroup]="offersService.documentsForm" *ngIf="offersService.view === 'documents' && offer.stage && !offer.isCanceled && deal.stage !== 'canceled' && deal.stage !== 'postponed' && deal.stage !== 'drawdown'">
  <div class="text-input">
    <textarea placeholder="Komentář" matInput cdkTextareaAutosize formControlName="tempComment"></textarea>
  </div>
</div>
<div class="offer-actions" *ngIf="deal.stage !== 'canceled' && deal.stage !== 'postponed' && !offer.isCanceled">
  <ng-container *ngIf="!offer.stage">
    <button mat-raised-button color="accent" [disabled]="isPristine()" (click)="save()"><mat-icon>save</mat-icon></button>
    <button mat-raised-button color="primary" [disabled]="!(isPristine() && mainService.getFormValidationErrors(offersService.offerForm).length === 0)" (click)="confirmChangeOfferStage('create', true)"><mat-icon>navigate_next</mat-icon></button>
  </ng-container>
  <ng-container *ngIf="offer.stage === 'create'">
    <button mat-raised-button color="warn" title="Přenabídkovat" [disabled]="!canGoNext(true) || !usersService.isPrivileged('offers/recreate:create')" (click)="recreateOffer()"><mat-icon>settings_backup_restore</mat-icon></button>
    <button mat-raised-button color="accent" [disabled]="isPristine()" (click)="save()"><mat-icon>save</mat-icon></button>
    <button mat-raised-button color="primary" [disabled]="!(offersService.offerForm.pristine && offersService.canSendForControl())" (click)="confirmChangeOfferStage('created')"><mat-icon>navigate_next</mat-icon></button>
  </ng-container>
  <ng-container *ngIf="offer.stage === 'created'">
    <button mat-raised-button color="warn" title="Přenabídkovat" [disabled]="!canGoNext(true) || !usersService.isPrivileged('offers/recreate:create')" (click)="recreateOffer()"><mat-icon>settings_backup_restore</mat-icon></button>
    <button mat-raised-button color="accent" [disabled]="isPristine()" (click)="save()"><mat-icon>save</mat-icon></button>
    <button mat-raised-button color="primary" [disabled]="!canGoNext()" (click)="confirmChangeOfferStage('sent')"><mat-icon>navigate_next</mat-icon></button>
  </ng-container>
  <ng-container *ngIf="offer.stage === 'sent'">
    <button mat-raised-button color="warn" title="Přenabídkovat" [disabled]="!canGoNext(true) || !usersService.isPrivileged('offers/recreate:create')" (click)="recreateOffer()"><mat-icon>settings_backup_restore</mat-icon></button>
    <button mat-raised-button color="accent" [disabled]="isPristine()" (click)="save()"><mat-icon>save</mat-icon></button>
    <button mat-raised-button color="primary" [disabled]="!canGoNext()" (click)="confirmChangeOfferStage('applicationDocuments')"><mat-icon>navigate_next</mat-icon></button>
  </ng-container>
  <ng-container *ngIf="offer.stage === 'applicationDocuments'">
    <button mat-raised-button color="warn" title="Přenabídkovat" [disabled]="!canGoNext(true) || !usersService.isPrivileged('offers/recreate:create')" (click)="recreateOffer()"><mat-icon>settings_backup_restore</mat-icon></button>
    <button mat-raised-button color="accent" [disabled]="isPristine()" (click)="save()"><mat-icon>save</mat-icon></button>
    <button mat-raised-button color="primary" [disabled]="!canGoNext()" (click)="confirmChangeOfferStage('applicationFilling')"><mat-icon>navigate_next</mat-icon></button>
  </ng-container>
  <ng-container *ngIf="offer.stage === 'applicationFilling'">
    <button mat-raised-button color="warn" title="Přenabídkovat" [disabled]="!canGoNext(true) || !usersService.isPrivileged('offers/recreate:create')" (click)="recreateOffer()"><mat-icon>settings_backup_restore</mat-icon></button>
    <button mat-raised-button color="accent" [disabled]="isPristine()" (click)="save()"><mat-icon>save</mat-icon></button>
    <button mat-raised-button color="primary" [disabled]="!canGoNext()" (click)="confirmChangeOfferStage('applicationSent')"><mat-icon>navigate_next</mat-icon></button>
  </ng-container>
  <ng-container *ngIf="offer.stage === 'applicationSent'">
    <button mat-raised-button color="warn" title="Přenabídkovat" [disabled]="!canGoNext(true) || !usersService.isPrivileged('offers/recreate:create')" (click)="recreateOffer()"><mat-icon>settings_backup_restore</mat-icon></button>
    <button mat-raised-button color="accent" [disabled]="isPristine()" (click)="save()"><mat-icon>save</mat-icon></button>
    <button mat-raised-button color="primary" [disabled]="!canGoNext()" (click)="confirmChangeOfferStage('application')"><mat-icon>navigate_next</mat-icon></button>
  </ng-container>
  <ng-container *ngIf="offer.stage === 'application'">
    <button mat-raised-button color="warn" title="Přenabídkovat" [disabled]="!canGoNext(true) || !usersService.isPrivileged('offers/recreate:create')" (click)="recreateOffer()"><mat-icon>settings_backup_restore</mat-icon></button>
    <button mat-raised-button color="accent" [disabled]="isPristine()" (click)="save()"><mat-icon>save</mat-icon></button>
    <button mat-raised-button color="primary" [disabled]="!canGoNext()"  [matMenuTriggerFor]="scoringMenu"><mat-icon>navigate_next</mat-icon></button>
  </ng-container>
  <ng-container *ngIf="offer.stage === 'scoringKO'">
    <button mat-raised-button color="warn" title="Přenabídkovat" [disabled]="!canGoNext(true) || !usersService.isPrivileged('offers/recreate:create')" (click)="recreateOffer()"><mat-icon>settings_backup_restore</mat-icon></button>
    <button mat-raised-button color="accent" [disabled]="isPristine()" (click)="save()"><mat-icon>save</mat-icon></button>
    <button mat-raised-button color="primary" [disabled]="!canGoNext()" (click)="confirmChangeOfferStage('approvalDocuments')"><mat-icon>navigate_next</mat-icon></button>
  </ng-container>
  <ng-container *ngIf="offer.stage === 'approvalDocuments'">
    <button mat-raised-button color="warn" title="Přenabídkovat" [disabled]="!canGoNext(true) || !usersService.isPrivileged('offers/recreate:create')" (click)="recreateOffer()"><mat-icon>settings_backup_restore</mat-icon></button>
    <button mat-raised-button color="accent" [disabled]="isPristine()" (click)="save()"><mat-icon>save</mat-icon></button>
    <button mat-raised-button color="primary" [disabled]="!canGoNext()" (click)="confirmChangeOfferStage('approval')"><mat-icon>navigate_next</mat-icon></button>
  </ng-container>
  <ng-container *ngIf="offer.stage === 'approval'">
    <button mat-raised-button color="warn" title="Přenabídkovat" [disabled]="!canGoNext(true) || !usersService.isPrivileged('offers/recreate:create')" (click)="recreateOffer()"><mat-icon>settings_backup_restore</mat-icon></button>
    <button mat-raised-button color="accent" [disabled]="isPristine()" (click)="save()"><mat-icon>save</mat-icon></button>
    <button mat-raised-button color="primary" [disabled]="!canGoNext()" (click)="confirmChangeOfferStage('approved')"><mat-icon>navigate_next</mat-icon></button>
  </ng-container>
  <ng-container *ngIf="offer.stage === 'approved'">
    <button mat-raised-button color="warn" title="Přenabídkovat" [disabled]="!canGoNext(true) || !usersService.isPrivileged('offers/recreate:create')" (click)="recreateOffer()"><mat-icon>settings_backup_restore</mat-icon></button>
    <button mat-raised-button color="accent" [disabled]="isPristine()" (click)="save()"><mat-icon>save</mat-icon></button>
    <button mat-raised-button color="primary" [disabled]="!canGoNext()" (click)="confirmChangeOfferStage('signed', true)"><mat-icon>navigate_next</mat-icon></button>
  </ng-container>
  <ng-container *ngIf="offer.stage === 'signed'">
    <button mat-raised-button color="warn" title="Přenabídkovat" [disabled]="!canGoNext(true) || !usersService.isPrivileged('offers/recreate:create')" (click)="recreateOffer()"><mat-icon>settings_backup_restore</mat-icon></button>
    <button mat-raised-button color="accent" [disabled]="isPristine()" (click)="save()"><mat-icon>save</mat-icon></button>
    <button mat-raised-button color="primary" [disabled]="!canGoNext()" (click)="confirmChangeOfferStage('drawing')"><mat-icon>navigate_next</mat-icon></button>
  </ng-container>
  <ng-container *ngIf="offer.stage === 'drawing'">
    <span></span>
    <button mat-raised-button color="accent" [disabled]="isPristine()" (click)="save()"><mat-icon>save</mat-icon></button>
    <button mat-raised-button color="primary" [disabled]="!canGoNext()" (click)="confirmChangeOfferStage('drawdown')"><mat-icon>navigate_next</mat-icon></button>
  </ng-container>
  <ng-container *ngIf="offer.stage === 'drawdown'">
    <span></span>
    <button mat-raised-button color="accent" [disabled]="isPristine()" (click)="save()"><mat-icon>save</mat-icon></button>
    <span></span>
  </ng-container>
</div>
<div class="offer-actions" *ngIf="(deal.stage === 'canceled' || deal.stage === 'postponed')">
  <ng-container>
    <span></span>
    <button mat-raised-button color="accent" [disabled]="isPristine()" (click)="save()"><mat-icon>save</mat-icon></button>
    <span></span>
  </ng-container>
</div>
<mat-menu #dealMenu="matMenu">
  <button mat-menu-item (click)="bankingService.openBanking(deal)" *ngIf="usersService.isPrivileged('banking:get')"><mat-icon>visibility</mat-icon>Zobrazit bankovnictví</button>
  <button mat-menu-item (click)="dealsService.videoCall(deal)"><mat-icon>videocam</mat-icon>Zaslané video/videohovor</button>
  <app-dial-button [menuItem]="true" [phoneNumber]="deal.phones?.[0]?.number" [countryCode]="deal.phones?.[0]?.countryCode" [contactName]="deal.name" (clickDial)="dialPhone($event, deal,true)"></app-dial-button>
  <button mat-menu-item (click)="postponeDeal()" *ngIf="usersService.isPrivileged('deals/postpone:patch')"><mat-icon>pause_circle_outline</mat-icon>Odložit případ</button>
  <button mat-menu-item (click)="dealsService.changeStage()" *ngIf="usersService.isPrivileged('deals/change-stage:patch')"><mat-icon>speed</mat-icon>Změnit stav případu</button>
  <button mat-menu-item (click)="changePriority()" *ngIf="usersService.isPrivileged('deals/change-priority:patch')"><mat-icon>height</mat-icon>Změnit prioritu</button>
  <button mat-menu-item (click)="changeDrawer()" *ngIf="usersService.isPrivileged('deals/change-drawer:patch') || deal.ownerId === usersService.user._id"><mat-icon>forward</mat-icon>Změnit čerpatele</button>
  <button mat-menu-item (click)="dealsService.changeOwner([deal])" *ngIf="usersService.isPrivileged('deals/change-owner:patch') || deal.ownerId === usersService.user._id"><mat-icon>forward</mat-icon>Změnit vlastníka</button>
  <button mat-menu-item (click)="dealsService.watchdog(deal)"><mat-icon>alarm</mat-icon>Hlídač hypotéky</button>
  <button mat-menu-item *ngIf="['admin', 'specialist'].includes(usersService.user.role)" [matMenuTriggerFor]="newDealMenu"><mat-icon>add</mat-icon>Nový případ</button>
  <button mat-menu-item (click)="cancelDeal()" *ngIf="usersService.isPrivileged('deals/cancel:patch') && deal.stage !== 'canceled'"><mat-icon>delete</mat-icon>Zamítnout případ</button>
  <button mat-menu-item (click)="restoreDeal()" *ngIf="usersService.isPrivileged('deals/restore:patch') && ['canceled','postponed'].includes(deal.stage)"><mat-icon>refresh</mat-icon>Obnovit případ</button>
</mat-menu>
<mat-menu #selectOfferMenu="matMenu">
  <button mat-menu-item *ngFor="let offer of deal._offers" [routerLink]="['/deals', deal._id,'offers',offer._id, 'modelations']" [ngClass]="{canceled: offer.isCanceled}"><mat-icon *ngIf="offersService.hasCandidate(offer) && !offersService.hasConfirmed(offer)">how_to_reg</mat-icon><mat-icon *ngIf="offersService.hasConfirmed(offer) && !offersService.hasFinal(offer)">done</mat-icon><mat-icon *ngIf="offersService.hasFinal(offer)">done_all</mat-icon><mat-icon *ngIf="!offersService.hasCandidate(offer) && !offersService.hasFinal(offer) && !offersService.hasConfirmed(offer)">notes</mat-icon>N{{ offer.numberId }} ({{ offer.fillingScore }}) - {{ offersService.offerStageText(offer) }}</button>
</mat-menu>
<mat-menu #offerMenu="matMenu">
  <button mat-menu-item (click)="missedCall()" *ngIf="usersService.isPrivileged('offers/missed-call:create') && ((!offer.stage || [1000,2000,2200,2450,2700,2800].includes(offer.fillingScore) || offer.stage === 'approvalDocuments' || (offer.isCanceled && ['canceled','postponed'].includes(deal.stage))) && (!offer.missedCalls || offer.missedCalls < 2))"><mat-icon>phone_missed</mat-icon>{{offer.missedCalls ? offer.missedCalls + 1 : 1}}. nedovolání</button>
  <app-dial-button [menuItem]="true" [phoneNumber]="deal.phones?.[0]?.number" [countryCode]="deal.phones?.[0]?.countryCode" [contactName]="deal.name" (clickDial)="dialPhone($event, offer, false)"></app-dial-button>
  <button *ngIf="usersService.isPrivileged('banking:patch')" mat-menu-item (click)="offersService.changeScore()"><mat-icon>speed</mat-icon>Nastavit bankovnictví na krok</button>
  <button *ngIf="usersService.isPrivileged('tasks')" mat-menu-item (click)="tasksService.addTask(deal, offer)"><mat-icon>task</mat-icon>Vytvořit úkol</button>
  <button *ngIf="usersService.isPrivileged('offers/comments:get') && offer.stage" mat-menu-item (click)="toggleComments()"><mat-icon>history</mat-icon>Zobrazit historii komentářů</button>
  <button *ngIf="usersService.isPrivileged('documents:get')" [disabled]="offersService.offerForm.dirty || !offersService.hasDocuments(offer)" mat-menu-item (click)="offersService.getDocuments(deal, offer)"><mat-icon>get_app</mat-icon>Stáhnout dokumenty</button>
  <button *ngIf="usersService.isPrivileged('offers/pdf:get')" [disabled]="offersService.offerForm.dirty" mat-menu-item (click)="offersService.getPdf(deal, offer)"><mat-icon>remove_red_eye</mat-icon>Zobrazit PDF nabídku</button>
  <button *ngIf="usersService.isPrivileged('offers/pdf:get')" [disabled]="offersService.offerForm.dirty" mat-menu-item (click)="offersService.getPdf(deal, offer, true)"><mat-icon>cloud_download</mat-icon>Stáhnout PDF nabídku</button>
  <button mat-menu-item *ngIf="usersService.isPrivileged('offers:create') && offer.stage && !['canceled', 'postponed', 'signed', 'drawing', 'drawdown'].includes(deal.stage)" [disabled]="offersService.offerForm.dirty" (click)="confirmNewOffer()"><mat-icon>insert_drive_file</mat-icon>Přidat novou nabídku</button>
</mat-menu>
<mat-menu #scoringMenu="matMenu">
  <button mat-menu-item (click)="confirmChangeOfferStage('approvalDocuments')"><mat-icon>thumb_up</mat-icon>Dokumenty ke schválení</button>
  <button mat-menu-item (click)="confirmChangeOfferStage('scoringKO')"><mat-icon>thumb_down</mat-icon>Scoring KO</button>
</mat-menu>
<mat-menu #newDealMenu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item (click)="dealsService.newDeal(deal, offer._id)"><mat-icon color="accent">home</mat-icon>
      <span>Hypotéka</span>
    </button>
    <button mat-menu-item (click)="casesService.addCase(deal, 'mortgage')"><mat-icon color="accent">personal_injury</mat-icon>
      <span>Pojištění</span>
    </button>
  </ng-template>
</mat-menu>
