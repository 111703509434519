import { Injectable } from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, Subject} from 'rxjs';
import {UsersService} from '@app/services/users.service';
import {map} from 'rxjs/operators';
import {CallsService} from '@app/services/calls.service';

@Injectable({providedIn: 'root'})
export class CallingWidgetService {

  $openConversation: Subject<string> = new Subject<string>();
  openConversation$: Observable<string> = this.$openConversation.asObservable();
  $leaveConversation: Subject<void> = new Subject<void>();
  leaveConversation$: Observable<void> = this.$leaveConversation.asObservable();
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loading$: Observable<boolean> = this.$loading.asObservable();
  callsDisabled$: Observable<boolean>;
  smsDisabled$: Observable<boolean>;
  constructor(
    private usersService: UsersService,
    private callsService: CallsService,
  ) {
    this.callsDisabled$ = combineLatest([
      this.usersService.user$,
      this.callsService.activeCall$,
      this.callsService.incomingCall$,
      this.callsService.callInitiating$
    ]).pipe(
      map(([user, activeCall, incomingCall, callInitiating]) => {
        if (user.asteriskExt && !activeCall && !incomingCall && !callInitiating) {
          return false;
        }
        return true;
      })
    );
    this.smsDisabled$ = combineLatest([
      this.usersService.user$,
    ]).pipe(
      map(([user]) => {
        if (user.asteriskExt) {
          return false;
        }
        return true;
      })
    );
  }

  addConversation(phoneNumber: string) {
    this.$openConversation.next(phoneNumber);
  }
  leaveConversation() {
    this.$leaveConversation.next();
  }
  setLoading(loading: boolean) {
    this.$loading.next(loading);
  }
}
