<mat-accordion [formGroup]="document | formGroup">
  <mat-expansion-panel [ngClass]="{blue: document.get('_template').value?.isDeliveredByUs}" (opened)="panelOpenened()">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <!-- HEADER CASE DOCUMENTS -->
        <ng-container *ngIf="!isInternal">
          <div class="rows">
            <div class="elipsis">{{ document.get('_template').value.name }} ({{ document.get('files').value.length }})</div>
            <div *ngIf="document.get('_template').value.isRelatedTo === 'contact'">
              <div><span *ngIf="document.get('type').value">{{ document.get('type').value }}&nbsp;-&nbsp;</span>{{document.get('contactId').value | getContact:deal._contacts | contactName}}</div>
            </div>
          </div>
          <span class="status" [ngClass]="document.get('status').value">
            <span *ngIf="document.get('isHidden').value"><mat-icon color="warn">visibility_off</mat-icon>&nbsp;</span>
            {{ mainService.optionLabel(document.get('_template').value?.statuses, document.get('status').value, true) }}
            <button [disabled]="document.get('_template').value.isRelatedTo === 'contact'" mat-icon-button color="warn"
                    (click)="$event.stopPropagation();removeDocument.emit(documentIndex);">
              <mat-icon>remove_circle_outline</mat-icon>
            </button>
          </span>
        </ng-container>

        <!-- HEADER INTERNAL DOCUMENTS -->
        <ng-container *ngIf="isInternal">
          <span class="flex flex-col truncate">
            <div>
              {{ document.get('_template').value.name }} ({{ document.get('files').value.length }})
            </div>
            <div class="text-ellipsis font-semibold">
              {{ document.get('note').value ?? '' }}
            </div>
          </span>
          <span class="flex flex-row w-64">
            <span class="waiting flex grow flex-col justify-end items-end">
              <span *ngIf="document.get('userId')?.value">
                {{ usersService.getUserFullName(document.get('userId')?.value) }}
              </span>
              <span *ngIf="document.get('createdAt')?.value">
                {{ document.get('createdAt').value | dateWord}}
              </span>
              <span *ngIf="!document.get('createdAt')?.value">
                nyní
              </span>
            </span>
            <button mat-icon-button color="warn" (click)="$event.stopPropagation();removeClick()">
              <mat-icon>remove_circle_outline</mat-icon>
            </button>
          </span>
        </ng-container>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>
      <!-- DETAIL CASE DOCUMENTS -->
      <ng-container *ngIf="!isInternal">
        <div class="form-section">
          <div class="form-section-title">Nastavení</div>
          <div class="form-section-content">
            <div class="form-row">
              <mat-form-field class="w50">
                <input matInput placeholder="Doručit do" [matDatepicker]="deliverUntilDate" formControlName="deliverUntil" />
                <mat-datepicker-toggle matSuffix [for]="deliverUntilDate"></mat-datepicker-toggle>
                <mat-datepicker #deliverUntilDate></mat-datepicker>
              </mat-form-field>
              <mat-form-field class="w50">
                <input matInput placeholder="Doručeno" [matDatepicker]="deliveredAtDate" formControlName="deliveredAt" />
                <mat-datepicker-toggle matSuffix [for]="deliveredAtDate"></mat-datepicker-toggle>
                <mat-datepicker #deliveredAtDate></mat-datepicker>
              </mat-form-field>
              <mat-form-field class="w50">
                <input matInput placeholder="Podepsat do" [matDatepicker]="signatureUntilDate" formControlName="signatureUntil" />
                <mat-datepicker-toggle matSuffix [for]="signatureUntilDate"></mat-datepicker-toggle>
                <mat-datepicker #signatureUntilDate></mat-datepicker>
              </mat-form-field>
              <mat-form-field class="w50">
                <input matInput placeholder="Podepsáno" [matDatepicker]="signedAtDate" formControlName="signedAt" />
                <mat-datepicker-toggle matSuffix [for]="signedAtDate"></mat-datepicker-toggle>
                <mat-datepicker #signedAtDate></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="form-row">
              <mat-form-field>
                <textarea placeholder="Interní poznámka" matInput cdkTextareaAutosize formControlName="note"></textarea>
              </mat-form-field>
              <mat-form-field class="w50">
                <mat-select placeholder="Stav" formControlName="status" >
                  <mat-option *ngFor="let option of (document.get('_template').value?.statuses)" [value]="option.value">{{ option.internalLabel }}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-slide-toggle class="w30" color="warn" formControlName="isHidden">Skrytý</mat-slide-toggle>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- DETAIL INTERNAL DOCUMENTS -->
      <ng-container *ngIf="isInternal">
        <div class="form-section">
          Poznámka
        </div>
        <div class="form-section">
          <div class="grow w-full">
            <p-inplace #inpNote
                       styleClass="grow w-full"
                       (onActivate)="noteEditing()">
              <ng-template pTemplate="display">
                <div class="grow w-full ql-editor rich-text note text-normal"
                     [ngClass]="{ 'text-slate-400': !note?.text }"
                     [innerHTML]="note?.text ?? 'Poznámka'">
                </div>
              </ng-template>
              <ng-template pTemplate="content">
                <p-editor [(ngModel)]="note.tmpText"
                          [ngModelOptions]="{standalone: true}"
                          (onInit)="initEditor($event)">
                  <ng-template pTemplate="header">
                <span class="ql-formats">
                  <select class="ql-size"></select>
                </span>
                    <span class="ql-formats">
                  <button type="button" class="ql-bold"></button>
                  <button type="button" class="ql-italic"></button>
                  <button type="button" class="ql-underline"></button>
                      <!--select class="ql-color"></select-->
                </span>
                    <span class="ql-formats">
                  <button type="button" class="ql-list" value="ordered"></button>
                  <button type="button" class="ql-list" value="bullet"></button>
                  <button type="button" class="ql-indent" value="-1"></button>
                  <button type="button" class="ql-indent" value="+1"></button>
                </span>
                    <span class="ql-formats">
                  <button type="button" class="ql-image"></button>
                </span>
                  </ng-template>
                </p-editor>

                <button mat-raised-button color="accent" class="btn-save"
                        (click)="saveNote(this.note.tmpText, inpNote)"
                        [disabled]="note.text === note.tmpText">
                  Uložit
                </button>

                <button mat-raised-button class="btn-close" (click)="discardNote(inpNote)">
                  Zrušit
                </button>
              </ng-template>
            </p-inplace>
          </div>
        </div>
      </ng-container>

      <div class="form-section-content">
        <app-file-list [documentControl]="document"
                       [refresh]="refresh"
                       [isInternal]="isInternal"
                       class="w-full"
                       (selectFiles)="changeFiles.emit($event)"
                       (moveFiles)="moveFiles.emit($event)"
                       (refreshFiles)="refreshFiles.emit($event)">
        </app-file-list>
      </div>

    </ng-template>
  </mat-expansion-panel>
</mat-accordion>
