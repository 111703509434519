import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'caseStage'})
export class CaseStagePipe implements PipeTransform {
  transform(stageId: string, stages: any[]) {
    const a = stages.find(s => s.id === stageId);
    if (a) {
      return a?.label || stageId;
    } else if (stageId === 'canceled') {
      return 'Zamítnuto';
    } else if (stageId === 'postponed') {
      return 'Odloženo';
    }
  }
}
