<ng-container *ngIf="!selectedCall">
  <mat-list>
    <ng-container *ngFor="let group of groups$ | async">
      <mat-divider></mat-divider>
      <div class="px-4 py-2 h-8 text-sm bg-gray-200" *ngIf="group | dateDesc">{{group | dateDesc:true }}</div>
      <mat-list-item *ngFor="let call of ((groupedCalls$ | async)?.[group])"
                     [ngClass]="{missed: call.direction === 'in' && !call.answeredAt, hasdeal: call._deals?.[0], hasprovider: call._provider, disabled: (callingWidgetService.callsDisabled$ | async)}">

        <div class="flex flex-row items-center justify-stretch w-full flex-grow">
          <div class="flex flex-shrink-0 w-12">
            <app-call-icon [call]="call" mat-list-icon></app-call-icon>
          </div>

          <div class="flex flex-col flex-grow">
            <div class="flex text-base leading-none">
              <a *ngIf="call._contact" class="cursor-pointer" (click)="selectCall(call)">{{ call | callsContactName }}</a>
              <a *ngIf="!call._contact" class="cursor-pointer" (click)="selectCall(call)">{{ call | callsPhoneNumber }}</a>
            </div>
            <div class="flex flex-col" *ngIf="call._provider as _provider">
              <div class="flex flex-row items-center gap-1">
                <mat-icon class="provider-icon">account_balance</mat-icon>
                <span>{{ _provider.name }}</span>
              </div>
            </div>
            <div class="flex flex-col" *ngIf="call._deals?.[0] as _deal">
              <div class="flex flex-row">
                <a (click)="$event.stopPropagation();" [routerLink]="_deal._pipelineUrl === 'mortgage' ? ['/deals', _deal._id,'offers'] : ['/cases', _deal._pipelineUrl, _deal._id]">{{ _deal | caseNumber }}</a>
                <span *ngIf="_deal.amount">&nbsp;-&nbsp;{{mainService.maskCurrency(_deal.amount)}} Kč</span>
              </div>
              <div class="flex flex-row font-semibold text-gray-600 gap-2">
                {{_deal.stage | caseStage:pipelinesService.getPipeline(_deal._pipelineUrl)?.stages}}
                <span *ngIf="_deal.cancelReason">{{_deal.cancelReason | caseCancelReasonLabel: _deal._pipelineUrl }}</span>
                <span *ngIf="_deal.postponeReason">{{_deal.postponeReason | casePostponeReasonLabel: _deal._pipelineUrl}}</span>
              </div>
            </div>

            <div class="flex flex-col sm:flex-row sm:gap-4">
              <div class="call-info">
                <span *ngIf="call.direction === 'in' && call.finishedAt !== undefined && !call.answeredAt">zmeškaný hovor</span>
                <span *ngIf="call.direction === 'out' && call.finishedAt !== undefined && !call.answeredAt">volaný nezvedl</span>
                <span *ngIf="call.direction === 'in' && call.answeredAt">příchozí hovor</span>
                <span *ngIf="call.direction === 'out' && call.answeredAt">odchozí hovor</span>
                <span>&nbsp;-&nbsp;<b>{{ call.createdAt | date:'HH:mm:ss'}}</b></span>
              </div>
              <div class="call-info">
                <span *ngIf="!call.answeredAt">zvonilo <b>{{ call.ringingTime }} sekund</b></span>
                <span *ngIf="call.answeredAt">délka hovoru <b>{{ call.talkingTime | secondsToMinutes }}</b></span>
              </div>
            </div>
          </div>
        </div>

        <div class="flex">
          <app-dial-button class="flex flex-row"
                           [widget]="true"
                           [compact]="true"
                           [phoneNumber]="call | callsPhoneNumber"
                           [contactId]="call.contactId"
                           (clickDial)="dialPhone($event)">
          </app-dial-button>
        </div>

      </mat-list-item>
    </ng-container>
  <app-infinite-scroll *ngIf="callsService.canLoadMore$ | async" (endReached)="endReached()"></app-infinite-scroll>
</mat-list>
</ng-container>


<ng-container *ngIf="selectedCall">
  <div class="header sticky top-0 bg-gray-100 z-10 py-2 px-4 flex flex-row justify-stretch">
    <div class="flex-none w-12">
      <button mat-icon-button (click)="selectCall(null)" class="chevron-left"><mat-icon>chevron_left</mat-icon></button>
    </div>

    <div class="flex-grow">
      <div class="flex-row flex gap-1 items-center">
        <span *ngIf="selectedCall._contact?._id">{{ selectedCall | callsContactName }}</span>
        <span>{{selectedCall | callsPhoneNumber}}</span>
        <mat-icon *ngIf="selectedCall._provider" class="provider-icon">account_balance</mat-icon>
      </div>
      <div class="flex flex-col" *ngFor="let _deal of selectedCall._deals">
        <span>
          <a (click)="$event.stopPropagation();" [routerLink]="_deal._pipelineUrl === 'mortgage' ? ['/deals', _deal._id,'offers'] : ['/cases', _deal._pipelineUrl, _deal._id]">{{ _deal | caseNumber }}</a>
          &nbsp;-&nbsp;<b>{{_deal.stage | caseStage:pipelinesService.getPipeline(_deal._pipelineUrl)?.stages}}</b>
          <span *ngIf="_deal.amount">&nbsp;-&nbsp;{{mainService.maskCurrency(_deal.amount)}} Kč</span>
        </span>
      </div>
      <div *ngIf="selectedCall._provider as _provider" class="flex flex-col">
        {{ _provider.name }}
      </div>
    </div>

    <div class="flex">
      <button *ngIf="selectedCall._contact?._id" mat-icon-button (click)="$event.stopPropagation();" [routerLink]="['/clients', selectedCall._contact._id]">
        <mat-icon>contacts</mat-icon>
      </button>

      <app-dial-button class="flex flex-row"
                       [widget]="true"
                       [compact]="true"
                       [phoneNumber]="selectedCall | callsPhoneNumber"
                       [contactId]="selectedCall.contactId"
                       (clickDial)="dialPhone($event)">
      </app-dial-button>
    </div>
  </div>

  <mat-list>
    <ng-container *ngFor="let group of groups$ | async">
      <mat-divider></mat-divider>
      <div class="px-4 py-2 h-8 text-sm bg-gray-200" *ngIf="group | dateDesc">{{group | dateDesc:true }}</div>
      <mat-list-item *ngFor="let call of ((groupedCalls$ | async)?.[group])"
                     [ngClass]="{ missed: call.direction === 'in' && !call.answeredAt }">

        <div class="flex flex-row items-center justify-stretch w-full flex-grow">
          <div class="flex flex-shrink-0 w-12">
            <app-call-icon [call]="call" mat-list-icon></app-call-icon>
          </div>

          <div class="flex flex-col flex-grow">
            <div class="call-info">
              <span *ngIf="call.direction === 'in' && call.finishedAt !== undefined && !call.answeredAt">zmeškaný hovor</span>
              <span *ngIf="call.direction === 'out' && call.finishedAt !== undefined && !call.answeredAt">volaný nezvedl</span>
              <span *ngIf="call.direction === 'in' && call.answeredAt">příchozí hovor</span>
              <span *ngIf="call.direction === 'out' && call.answeredAt">odchozí hovor</span>
              <span>&nbsp;-&nbsp;<b>{{ call.createdAt | date:'HH:mm:ss'}}</b></span>
            </div>
            <div class="call-info">
              <span *ngIf="!call.answeredAt">zvonilo <b>{{ call.ringingTime }} sekund</b></span>
              <span *ngIf="call.answeredAt">délka hovoru <b>{{ call.talkingTime | secondsToMinutes }}</b></span>
            </div>
          </div>
        </div>
      </mat-list-item>
    </ng-container>
    <app-infinite-scroll *ngIf="callsService.canLoadMore$ | async" (endReached)="endReached()"></app-infinite-scroll>
  </mat-list>

</ng-container>
