<div class="content">
  <div class="sidenav" #sidenav [ngClass]="{'sidenav-full': mainService.sidenavFull}" (window:scroll)="onScroll()">
    <app-sidenav></app-sidenav>
  </div>
  <main [ngClass]="{'sidenav-full': mainService.sidenavFull}">
    <router-outlet></router-outlet>
  </main>
</div>
<div class="status-bar">
  <ng-container *ngIf="usersService.user.cases?.mortgage">
  <div class="status status-color"  [ngClass]="{warning: day > 14, done: (usersService.amountStats$ | async).signedThisMonthAmount >= usersService.user.cases.minSignedAmount}" *ngIf="usersService.user.role === 'specialist'">
    <span class="status-label">Objem: </span>
    <span class="status-value">{{ mainService.maskCurrency((usersService.amountStats$ | async).signedThisMonthAmount) }} / {{ mainService.maskCurrency(usersService.user.cases.minSignedAmount) }}</span>
  </div>
  <div class="status" *ngIf="usersService.user.role === 'specialist'">
    <span class="status-label">Provize: </span>
    <span class="status-value">{{ mainService.maskCurrency((usersService.amountStats$ | async).commissionsThisMonthAmount) }} Kč</span>
  </div>
  <div class="status status-color"  [ngClass]="{warning: day > 14, done: (usersService.amountStats$ | async).applicationsThisMonthAmount >= usersService.user.cases.minApplicationAmount}" *ngIf="usersService.user.role === 'specialist'">
    <span class="status-label">Žádosti: </span>
    <span class="status-value">{{ mainService.maskCurrency((usersService.amountStats$ | async).applicationsThisMonthAmount) }} / {{ mainService.maskCurrency(usersService.user.cases.minApplicationAmount) }}</span>
  </div>
  <div class="status status-color" [ngClass]="{warning: usersService.user.cases.yearBudget - (usersService.amountStats$ | async).personalBudget <= 50000, done: usersService.user.cases.yearBudget - (usersService.amountStats$ | async).personalBudget > 100000}" *ngIf="usersService.user.role === 'specialist' && (usersService.amountStats$ | async).personalBudget !== undefined">
    <span class="status-label">Budget: </span>
    <span class="status-value">{{ mainService.maskCurrency(usersService.user.cases.yearBudget - (usersService.amountStats$ | async).personalBudget) }}</span>
  </div>
  <div class="status" *ngIf="usersService.user.role === 'specialist' && (usersService.amountStats$ | async).teamBudget !== undefined">
    <span class="status-label">Budget: </span>
    <span class="status-value">{{ mainService.maskCurrency(settingsService.settings.teamBudget - (usersService.amountStats$ | async).teamBudget) }}</span>
  </div>
    <div class="status">
      <mat-icon *ngIf="!usersService.user.cases.mortgage.accept" class="status-cancel">close</mat-icon>
      <mat-icon *ngIf="usersService.user.cases.mortgage.accept" class="status-check">check</mat-icon>
    </div>
  </ng-container>
  <app-calling-widget *ngIf="usersService.user.asteriskExt" [opened]="false"></app-calling-widget>
</div>
<div class="loading" *ngIf="mainService.isLoading"><mat-spinner class="white"></mat-spinner></div>
