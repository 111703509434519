import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UsersService } from '@app/services/users.service';
import { UserInterface } from '@app/interfaces/user.interface';
import { AffiliatesService } from '@app/services/affiliates.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Pipeline } from '@app/models/pipeline.model';
import { ContactsService } from '@app/services/contacts.service';
import { map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { SettingsService } from '@app/services/settings.service';
import { ProvidersService } from '@app/services/providers.service';
import { TipsterCommisionService } from '@app/services/tipster-commision.service';
import { MatSelectChange } from '@angular/material/select';
import { constPipelineLifeInsurance, constPipelineRealEstateInsurance } from '@app/app.constants';

@Component({
  selector: 'app-dialog',
  templateUrl: './new-demand-dialog.component.html',
})
export class NewDemandDialogComponent implements OnInit {

  public user: UserInterface;
  public priorities: any[];
  public deal: any;
  public copyContactsId: string[] = [];
  public ownerId: string;
  public pipelineId: string;
  public owners: any[];
  public tipsters: any[];
  public pipelineOwners: any[];
  public pipelines: Pipeline[];
  public affiliates: any[];
  public options: any;
  public demand: any;
  public providers: Observable<any>;
  public filteredUsers: BehaviorSubject<any> = new BehaviorSubject([]);
  public filteredContacts: Observable<any> = new Observable<any>();
  public contactSelectionDone = false;
  public selectedContact = null;

  public pipelineLifeInsurance = constPipelineLifeInsurance;
  public pipelineRealEstateInsurance = constPipelineRealEstateInsurance;

  constructor(
    public dialogRef: MatDialogRef<NewDemandDialogComponent>,
    public affiliatesService: AffiliatesService,
    private usersService: UsersService,
    public contactsService: ContactsService,
    public settingService: SettingsService,
    public providersService: ProvidersService,
    public tipsterCommisionService: TipsterCommisionService,
    private datePipe: DatePipe
  ) {
    this.options = this.settingService.options;
    this.filteredContacts = this.contactsService.items$.pipe(
      map((contacts: any[]) => contacts.map((contact: any) => {
        contact.phoneVerified = contact.phone.some((p: any) => p.isVerified);
        return contact;
      }).sort((a: any, b: any) => {
        if (a.emailVerified && a.phoneVerified && !b.emailVerified && !b.phoneVerified) {
          return -1;
        }
        if (!a.emailVerified && !a.phoneVerified && b.emailVerified && b.phoneVerified) {
          return 1;
        }

        // Secondary sorting: At least one of them is true
        if ((a.emailVerified || a.phoneVerified) && !(b.emailVerified || b.phoneVerified)) {
          return -1;
        }
        if (!(a.emailVerified || a.phoneVerified) && (b.emailVerified || b.phoneVerified)) {
          return 1;
        }
      }).map((contact: any) => {
        let fields = [];
        let subfields = [];
        fields.push(((contact.firstName || '') + ' ' + (contact.lastName || '')).trim());
        fields.push(contact.birthDate ? (new Date (contact.birthDate)).getFullYear() : '');
        fields.push(contact.email || '');
        subfields.push(contact.address?.permanent?.city || '');
        subfields.push(`(${this.datePipe.transform(contact.createdAt, 'shortDate')})`);
        fields = fields.filter((field: string) => field);
        subfields = subfields.filter((field: string) => field);
        return {
          _id: contact._id,
          label: fields.join(', ') || `K${contact.numberId}`,
          sublabel: subfields.join(', '),
          emailVerified: contact.emailVerified,
          phoneVerified: contact.phoneVerified
        };
      }).filter((contact: any) => contact.label || contact.sublabel))
    );
    this.filteredContacts.subscribe((contacts: any[]) => {
      if (contacts.length) {
        this.selectedContact = contacts[0]?._id;
      } else {
        this.selectedContact = null;
      }
    });
  }

  ngOnInit() {
    this.selectedContact = null;
    this.contactSelectionDone = false;
    this.contactsService.$items.next([]);
    this.providers = this.providersService.items$.pipe(
      map((providers) => providers.filter((p: any) => p.type === 'insurance_company'))
    );
    this.filterPipelineOwners(this.pipelines?.[0]?.url);
    if (this.deal) {
      this.ownerId = this.deal.ownerId;
      this.copyContactsId = this.pipelineId !== 'mortgage' ? this.deal.participants?.map(p => p?.contactId) : this.deal._offers[0].applicants;
    } else {
      if (this.owners.find(o => o._id === this.user._id)) {
        this.ownerId = this.user._id;
      }
    }
    if (this.ownerId && !this.pipelineOwners.find(o => o._id === this.ownerId)) {
      this.ownerId = '';
    }
    this.demand = {
      source: 'portal',
      pipelineId: null,
      providerId: '',
      pipelineUrl: this.pipelines[0].url,
      affiliateId: null,
      authorId: null,
      priority: 0,
      ownerId: this.ownerId || '',
      solveOnline: true,
      note: '',
      contacts: {
        name: null,
        zipCode: null,
        phoneCountryCode: '+420',
        phoneNumber: null,
        email: null,
      },
      sourceType: 'hnm',
      tipsterId: null,
      tipsterRole: '',
      tipsterCommission: 0,
    };
    if (this.deal) {
      this.demand.sourceDeal = {
        pipelineId: this.deal?._pipelineId,
        dealId: this.deal?._id,
        contactsId: this.copyContactsId,
        stage: this.deal?.stage
      };
    }
  }
  async filterUsers(search: string) {
    if (search.length > 0) {
      const users = await this.usersService.find({
        query: {
          $limit: 20,
          role: { $ne: 'user' },
          isBlocked: false,
          email: {$regex: search, $options: 'i'},
          $select: ['_id', 'email', 'cases'],
        }
      });
      this.filteredUsers.next(users);
    } else {
      this.filteredUsers.next([]);
    }
    this.demand.tipsterId = null;
  }

  priorityText(priority) {
    return this.priorities.find(o => o.value === priority)?.label;
  }

  filterPipelineOwners(pipelineUrl) {
    this.pipelineOwners = this.owners.filter(o => o.cases?.[pipelineUrl]);
  }

  public filterContacts(control: any) {
    if (!this.contactSelectionDone) {
      const countryCode = control.get('contactPhoneCountryCode')?.value;
      const phoneNumber = control.get('contactPhoneNumber')?.value;
      const email = control.get('contactEmail')?.value;
      if (phoneNumber) {
        this.contactsService.filter = {
          phone: {
            $elemMatch: {
              countryCode,
              number: phoneNumber
            }
          },
        };
        if (email) {
          this.contactsService.filter.email = email;
        }
        this.contactsService.load(0);
      }
    }
  }

  public selectContact(fill = false) {
    if (fill) {
      this.copyContactsId.push(this.selectedContact);
      this.demand.sourceDeal = {
        contactsId: this.selectedContact ? [this.selectedContact] : [],
      };
    }
    this.contactSelectionDone = true;
  }

  public filterProviders() {
    const pipeline = this.pipelines.find(p => p.url === this.demand.pipelineUrl);
    this.providersService.filter = {
      pipelines: pipeline._id,
    };
    this.providersService.load(0);
  }

  backToContactSelection() {
    this.copyContactsId = [];
    this.demand.sourceDeal = {
      contactsId: [],
    };
    this.contactSelectionDone = false;
  }

  confirmDialog() {
    if (!this.demand.authorId) {
      this.demand.authorId = this.user._id;
    }
    if (!this.demand.ownerId) {
      this.demand.ownerId = null;
    }
    if (this.demand.pipelineUrl !== 'car-insurance' && this.demand.providerId) {
      const provName = this.providersService.getById(this.demand.providerId)?.name;
      this.demand.note = 'Povinná pojišťovna: ' +  provName + '  ' + this.demand.note;
    }
    this.dialogRef.close(this.demand);
  }

  public async sourceTypeChanged(event: MatSelectChange) {
    let recalc = false;
    switch (event.value) {
      case 'hnm':
        this.demand = {
          ...this.demand,
          sourceType: 'hnm',
          affiliateId: null,
          isOwnDeal: false,
          tipsterId: null,
          tipsterRole: null,
          tipsterCommission: null
        };
        break;
      case 'affiliate':
        this.demand = {
          ...this.demand,
          sourceType: 'affiliate',
          tipsterId: null,
          tipsterRole: null,
          tipsterCommission: null,
          isOwnDeal: false
        };
        break;
      case 'hnmrecommendation':
        this.demand = {...this.demand, sourceType: 'hnmrecommendation', affiliateId: null, isOwnDeal: false};
        recalc = true;
        break;
      case 'personal':
        this.demand = {...this.demand, sourceType: 'personal', affiliateId: null, isOwnDeal: false};
        recalc = true;
        break;
      case 'employee':
        this.demand = {...this.demand, sourceType: 'employee', affiliateId: null, isOwnDeal: true};
        recalc = true;
        break;
    }

    if (recalc) {
      this.demand.tipsterCommission = await this.getCommission();
    }
  }

  public async tipsterChanged(event: MatSelectChange) {
    const tipster = this.tipsters.find(t => t._id === event.value);
    if (tipster.cases?.mortgage) {
      this.demand.tipsterRole = 'mortgage';
    } else if (tipster.cases?.['car-insurance'] || tipster.cases?.['life-insurance'] || tipster.cases?.['real-estate-insurance']) {
      this.demand.tipsterRole = 'insurance';
    } else {
      this.demand.tipsterRole = 'other';
    }
    this.demand.tipsterId = tipster._id;
    this.demand.tipsterCommission = await this.getCommission();
  }

  public async getCommission(): Promise<number | null> {
    return await this.tipsterCommisionService.getCommision(this.demand.pipelineUrl, this.demand.sourceType, this.demand.tipsterRole);
  }

}
