import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Call } from '@app/models/calls.model';
import { Message } from '@app/models/message.model';
import { Task } from '@app/models/task.model';
import { Activity } from '@app/models/activity.model';
import { PipelinesService } from '@app/services/pipelines.service';
import { interval, Observable, of } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { MessagesService } from '@app/services/messages.service';
import { DialogsService } from '@app/services/dialogs.service';
import { UsersService } from '@app/services/users.service';
import { UserGroupsService } from '@app/services/user-groups.service';

export enum TimeLineItemType {
  email = 'email',
  sms = 'sms',
  call = 'call',
  task = 'task',
  activity = 'activity',
}

export interface TimelineItem {
  type: TimeLineItemType;
  date: Date;
  data: any;
  dealId?: string;
  isFutureActivity?: boolean;
  isChecked?: boolean;
}

@Component({
  selector: 'app-timeline-item',
  templateUrl: './timeline-item.component.html',
  styleUrls: ['./timeline.component.scss']
})

export class TimelineItemComponent implements OnInit {

  email?: Message;
  sms?: Message;
  call?: Call;
  task?: Task;
  activity?: Activity;
  assignedToName?: string;
  assignedToType?: string;
  deal?: any;

  timer$: Observable<number>;

  @Input() item?: TimelineItem;
  @Input() deals?: any[];

  @Output() readonly itemChange: EventEmitter<TimelineItem> = new EventEmitter<TimelineItem>();
  @Output() readonly viewMessage: EventEmitter<Message> = new EventEmitter<Message>();

  constructor(
    public pipelinesService: PipelinesService,
    private messagesService: MessagesService,
    private dialogsService: DialogsService,
    private usersService: UsersService,
    private userGroupsService: UserGroupsService,
  ) {
  }

  public ngOnInit() {
    if (this.item?.data && this.item?.date) {
      if ((this.item.dealId) && (this.deals)) {
        this.deal = this.deals.find(deal => deal._id === this.item.dealId);
      }
      if (this.item.type === TimeLineItemType.task) {
        this.task = this.item.data;
        const assignedTo = this.task.assignedToId;
        if (assignedTo) {
          const g = this.userGroupsService.getGroupInfo(assignedTo);
          if (g) {
            this.assignedToType = 'Oddělení';
            this.assignedToName = g.name;
          } else {
            const r = this.usersService.getUserInfo(assignedTo);
            if (r) {
              this.assignedToType = 'Osoba';
              this.assignedToName = (r.firstName ?? '') + ' ' + (r.lastName ?? '');
            }
          }
        }
      } else if (this.item.type === TimeLineItemType.email) {
        this.email = this.item.data;
      } else if (this.item.type === TimeLineItemType.sms) {
        this.sms = this.item.data;
      } else if (this.item.type === TimeLineItemType.call) {
        this.call = this.item.data;
      } else if (this.item.type === TimeLineItemType.activity) {
        this.activity = this.item.data;
      } else {
        throw new Error('Invalid timeline item type');
      }
    } else {
      throw new Error('Invalid timeline data');
    }
    if (this.item.isFutureActivity) {
      this.timer$ = interval(1000)
        .pipe(
          startWith(0),
          switchMap(() => this.getTimer())
        );
    }
  }

  getTimer() {
    if (this.item.data.sendAt) {
      const b = new Date(this.item.data.sendAt).getTime() - new Date().getTime();
      const m = Math.round(b / 1000);
      if (m > 0 && m < 7200) {
        return of(m);
      }
    }
    return of(0);
  }

  public archiveItem(item: TimelineItem) {
    if (item.type === 'sms' || item.type === 'email') {
      this.dialogsService.confirm('Zrušení odeslání zprávy', 'Chcete skutečně zrušit odeslání této zprávy?')
        .subscribe(async (confirm) => {
          if (confirm) {
            try {
              await this.messagesService.patch(item.data._id, {
                sendAt: null, isArchived: true
              }, {
                query: {isArchived: {$ne: true}, sentAt: null}
              });
            } catch {
            }
            this.itemChange.emit(item);
          }
        });
    }
  }

  public displayMessage() {
    this.viewMessage.emit(this.email);
  }
}
