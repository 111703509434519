import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormArray} from '@angular/forms';
import {DocumentTemplatesService} from '@app/services/document-templates.service';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {DocumentTemplateInterface} from '@app/interfaces/document-template.interface';
import {ProvidersService} from '@app/services/providers.service';
import {DealsService} from '@app/services/deals.service';
import {ContactsService} from '@app/services/contacts.service';
import {OffersService} from '@app/services/offers.service';
import {DialogsService} from '@app/services/dialogs.service';
import {SettingsService} from '@app/services/settings.service';
import {MainService} from '@app/services/main.service';
import {FilesService} from '@app/services/files.service';
import {DocumentInterface} from '@app/interfaces/document.interface';
import {OptionInterface} from '@app/interfaces/option.interface';
import {UsersService} from '@app/services/users.service';
import {Contact} from '@app/models/contact.model';
import {Provider} from '@app/models/provider.model';
import {Deal} from '@app/models/deal.model';
import {Offer} from '@app/models/offer.model';
import {FileListMoveEvent, FileListSelectEvent} from '@app/components/file-list/file-list.component';
import {constPipelineMortgage} from '@app/app.constants';

interface SelectedTemplateInterface {
  templateId: string;
  applicants: string[];
  providers: string[];
  drawdowns: number[];
}

@Component({
  selector: 'app-deal-documents',
  templateUrl: './offer-documents.component.html',
  styleUrls: ['./offer-documents.component.scss']
})

export class OfferDocumentsComponent implements OnInit, OnDestroy {
  private subs$: Subscription[] = [];
  public deal: Deal = null;
  public offer: Offer = null;
  public selectingTemplates = false;
  public selectingDocumentsGroup = null;
  public selectingDocumentsGroupTypes = [];
  public selectedTemplates: SelectedTemplateInterface[] = [];
  private templates: DocumentTemplateInterface[] = [];
  public filter: { name: string, providerId: string, type: string, isInternal: boolean } = { name: null, providerId: null, type: null, isInternal: false };

  private $templates: BehaviorSubject<DocumentTemplateInterface[]> = new BehaviorSubject([]);
  public templates$: Observable<DocumentTemplateInterface[]> = this.$templates.asObservable();

  public groups: string[] = [
    'Doklady totožnosti',
    'Žádost o hypotéku',
    'Dokumenty ke schválení',
    'Úvěrová dokumentace',
    'Podmínky pro čerpání',
    'Podmínky po čerpání'
  ];

  public refresh = 0;

  constructor(
    private documentTemplatesService: DocumentTemplatesService,
    public providersService: ProvidersService,
    private dealsService: DealsService,
    public offersService: OffersService,
    private contactsService: ContactsService,
    private dialogsService: DialogsService,
    private settingsService: SettingsService,
    public mainService: MainService,
    private filesService: FilesService,
    public usersService: UsersService
  ) {
    this.offersService.view = 'documents';
  }

  public canDeactivate() {
    return new Promise( (resolve) => {
      if (this.offersService.documentsForm.pristine) {
        resolve(true);
      } else {
        this.dialogsService.deactivate().subscribe((confirm) => {
          if (!confirm) {
            this.mainService.hideLoading();
          }
          resolve(confirm);
        });
      }
    });
  }

  ngOnInit(): void {
    this.subs$.push(this.dealsService.deal$.subscribe((deal: Deal) => {
      this.deal = deal;
    }));
    this.subs$.push(this.offersService.offer$.subscribe((offer: Offer) => {
      this.offer = offer;
      for (const document of this.offer._documents || []) {
        const dIndex = this.offersService.documents.value.findIndex((d: DocumentInterface) => d._id === document._id);
        const documentControl = this.offersService.documents.at(dIndex);
        if (documentControl) {
          documentControl.get('status').patchValue(document.status);
          documentControl.get('type').patchValue(document.type);
          documentControl.get('deliveredAt').patchValue(document.deliveredAt);
          if (!documentControl.get('_template').value?.isDeliveredByUs) {
            documentControl.get('files').patchValue(document.files);
          }
        }
      }
    }));
    this.subs$.push(this.contactsService.updated$.subscribe((contact: Contact) => {
      const isOfferContact = !!this.offer.applicants.find((a: string) => a === contact._id);
      if (isOfferContact) {
        for (const document of contact._documents || []) {
          const dIndex = this.offersService.documents.value.findIndex((d: DocumentInterface) => d._id === document._id);
          const documentControl = this.offersService.documents.at(dIndex);
          if (documentControl) {
            documentControl.get('status').patchValue(document.status);
            documentControl.get('type').patchValue(document.type);
            documentControl.get('deliveredAt').patchValue(document.deliveredAt);
            if (!documentControl.get('_template').value?.isDeliveredByUs) {
              documentControl.get('files').patchValue(document.files);
            }
          }
        }
      }
    }));
  }

  ngOnDestroy(): void {
    this.subs$.forEach((sub) => sub.unsubscribe());
    this.subs$ = [];
  }

  public async filterTemplates() {
    let query: any;
    if (this.filter.isInternal) {
      query = {
        isInternal: true,
        pipelines: constPipelineMortgage,
      };
    } else {
      query = {
        group: this.selectingDocumentsGroup,
        pipelines: constPipelineMortgage,
      };
      if (this.filter.providerId) {
        query['$and'] = [{
          $or: [
            {
              'providers.providerId': null
            },
            {
              'providers.providerId': this.filter.providerId
            }
          ]
        }];
      }
      if (this.filter.type) {
        query['type'] = this.filter.type;
      }
      if (this.filter.name) {
        query['name'] = {$regex: this.filter.name, $options: 'i'};
      }
    }
    const templates = await this.documentTemplatesService.find({query});
    templates.sort((a: DocumentTemplateInterface, b: DocumentTemplateInterface) => a.order > b.order ? 1 : -1);
    this.templates = templates;
    this.$templates.next(templates);
  }

  public async selectTemplates(group: string, isInternal: boolean, isTemplateVisible: boolean) {
    this.selectingDocumentsGroup = group;
    this.selectedTemplates = [];
    this.filter.isInternal = isInternal;
    this.selectingTemplates = isTemplateVisible;
    await this.filterTemplates();
    this.selectingDocumentsGroupTypes = [];
    if (isInternal) {
      for (const types of this.templates.filter(t => t.isInternal === true).map(t => t.type)) {
        this.selectingDocumentsGroupTypes = [...new Set([...this.selectingDocumentsGroupTypes, ...types])];
      }
    } else {
      for (const types of this.templates.filter(t => t.group === group).map(t => t.type)) {
        this.selectingDocumentsGroupTypes = [...new Set([...this.selectingDocumentsGroupTypes, ...types])];
      }
    }
  }

  public selectTemplate(template: DocumentTemplateInterface) {
    const templateIndex = this.selectedTemplates.findIndex((st: SelectedTemplateInterface) => st.templateId === template._id);
    if (templateIndex > -1) {
      this.selectedTemplates.splice(templateIndex, 1);
    } else {
      const selectedTemplate: SelectedTemplateInterface = {
        templateId: template._id,
        providers: [],
        applicants: [],
        drawdowns: []
      };
      if (template.isDrawdownRelated) {
        for (const dIndex in this.offer.drawdowns) {
          if (this.offer.drawdowns[dIndex] !== undefined) {
            selectedTemplate.drawdowns.push(parseInt(dIndex, null));
          }
        }
      }
      if (template.isApplicantRelated) {
        for (const applicant of this.offer.applicants) {
          selectedTemplate.applicants.push(applicant);
        }
      }
      if (template.isProviderRelated) {
        for (const provider of this.confirmedProviders()) {
          selectedTemplate.providers.push(provider._id);
        }
      }
      this.selectedTemplates.push(selectedTemplate);
    }
  }

  public selectedTemplate(templateId: string): SelectedTemplateInterface {
    return this.selectedTemplates.find((st: SelectedTemplateInterface) => st.templateId === templateId) || {templateId: null, providers: null, applicants: null, drawdowns: null};
  }

  public async addDocuments() {
    for (const selectedTemplate of this.selectedTemplates) {
      const templates = await this.documentTemplatesService.find({});
      const template = templates.find((t: DocumentTemplateInterface) => t._id === selectedTemplate.templateId);

      if (this.filter.isInternal) {
        const documentForm = this.offersService.createDocumentForm({
          templateId: template._id,
          _template: template,
          type: template.type.length > 1 ? null : template.type[0],
          deliverUntil: template.defaultDelivery ? new Date((new Date()).setDate((new Date()).getDate() + template.defaultDelivery)) : null,
          signatureUntil: template.defaultSignature ? new Date((new Date()).setDate((new Date()).getDate() + template.defaultSignature)) : null,
          isInternal: true,
          userId: this.usersService.user._id,
        });
        documentForm.markAsDirty();
        this.offersService.documents.push(documentForm);
      } else if (template?.isProviderRelated && selectedTemplate.providers.length && template?.isApplicantRelated && selectedTemplate.applicants.length) {
        for (const providerId of selectedTemplate.providers) {
          for (const contactId of selectedTemplate.applicants) {
            if (!this.offersService.documents.value.find((d: DocumentInterface) => d.templateId === template?._id && d.providerId === providerId && d.contactId === contactId && d.status !== 'denied')) {
              const documentForm = this.offersService.createDocumentForm({
                providerId,
                contactId,
                templateId: template._id,
                _template: template,
                type: template.type.length > 1 ? null : template.type[0],
                deliverUntil: template.defaultDelivery ? new Date((new Date()).setDate((new Date()).getDate() + template.defaultDelivery)) : null,
                signatureUntil: template.defaultSignature ? new Date((new Date()).setDate((new Date()).getDate() + template.defaultSignature)) : null,
                isInternal: false
              });
              documentForm.markAsDirty();
              this.offersService.documents.push(documentForm);
            }
          }
        }
      } else if (template?.isProviderRelated && selectedTemplate.providers.length && template?.isDrawdownRelated && selectedTemplate.drawdowns.length) {
        for (const providerId of selectedTemplate.providers) {
          for (const drawdownIndex of selectedTemplate.drawdowns) {
            if (!this.offersService.documents.value.find((d: DocumentInterface) => d.templateId === template?._id && d.providerId === providerId && d.drawdownIndex === drawdownIndex && d.status !== 'denied')) {
              const documentForm = this.offersService.createDocumentForm({
                providerId,
                drawdownIndex,
                templateId: template._id,
                _template: template,
                type: template.type.length > 1 ? null : template.type[0],
                deliverUntil: template.defaultDelivery ? new Date((new Date()).setDate((new Date()).getDate() + template.defaultDelivery)) : null,
                signatureUntil: template.defaultSignature ? new Date((new Date()).setDate((new Date()).getDate() + template.defaultSignature)) : null,
                isInternal: false
              });
              documentForm.markAsDirty();
              this.offersService.documents.push(documentForm);
            }
          }
        }
      } else if (template?.isApplicantRelated && selectedTemplate.applicants.length && template?.isDrawdownRelated && selectedTemplate.drawdowns.length) {
        for (const contactId of selectedTemplate.applicants) {
          for (const drawdownIndex of selectedTemplate.drawdowns) {
            if (!this.offersService.documents.value.find((d: DocumentInterface) => d.templateId === template?._id && d.contactId === contactId && d.drawdownIndex === drawdownIndex && d.status !== 'denied')) {
              const documentForm = this.offersService.createDocumentForm({
                contactId,
                drawdownIndex,
                templateId: template._id,
                _template: template,
                type: template.type.length > 1 ? null : template.type[0],
                deliverUntil: template.defaultDelivery ? new Date((new Date()).setDate((new Date()).getDate() + template.defaultDelivery)) : null,
                signatureUntil: template.defaultSignature ? new Date((new Date()).setDate((new Date()).getDate() + template.defaultSignature)) : null,
                isInternal: false
              });
              documentForm.markAsDirty();
              this.offersService.documents.push(documentForm);
            }
          }
        }
      } else if (template?.isProviderRelated && selectedTemplate.providers.length) {
        for (const providerId of selectedTemplate.providers) {
          if (!this.offersService.documents.value.find((d: DocumentInterface) => d.templateId === template?._id && d.providerId === providerId && d.status !== 'denied')) {
            const documentForm = this.offersService.createDocumentForm({
              providerId,
              templateId: template._id,
              _template: template,
              type: template.type.length > 1 ? null : template.type[0],
              deliverUntil: template.defaultDelivery ? new Date((new Date()).setDate((new Date()).getDate() + template.defaultDelivery)) : null,
              signatureUntil: template.defaultSignature ? new Date((new Date()).setDate((new Date()).getDate() + template.defaultSignature)) : null,
              isInternal: false
            });
            documentForm.markAsDirty();
            this.offersService.documents.push(documentForm);
          }
        }
      } else if (template?.isApplicantRelated && selectedTemplate.applicants.length) {
        for (const contactId of selectedTemplate.applicants) {
          if (!this.offersService.documents.value.find((d: DocumentInterface) => d.templateId === template?._id && d.contactId === contactId && d.status !== 'denied')) {
            const documentForm = this.offersService.createDocumentForm({
              contactId,
              templateId: template._id,
              _template: template,
              type: template.type.length > 1 ? null : template.type[0],
              deliverUntil: template.defaultDelivery ? new Date((new Date()).setDate((new Date()).getDate() + template.defaultDelivery)) : null,
              signatureUntil: template.defaultSignature ? new Date((new Date()).setDate((new Date()).getDate() + template.defaultSignature)) : null,
              isInternal: false
            });
            documentForm.markAsDirty();
            this.offersService.documents.push(documentForm);
          }
        }
      } else if (template?.isDrawdownRelated && selectedTemplate.drawdowns.length) {
        for (const drawdownIndex of selectedTemplate.drawdowns) {
          if (!this.offersService.documents.value.find((d: DocumentInterface) => d.templateId === template?._id && d.drawdownIndex === drawdownIndex && d.status !== 'denied')) {
            const documentForm = this.offersService.createDocumentForm({
              drawdownIndex,
              templateId: template._id,
              _template: template,
              type: template.type.length > 1 ? null : template.type[0],
              deliverUntil: template.defaultDelivery ? new Date((new Date()).setDate((new Date()).getDate() + template.defaultDelivery)) : null,
              signatureUntil: template.defaultSignature ? new Date((new Date()).setDate((new Date()).getDate() + template.defaultSignature)) : null,
              isInternal: false
            });
            documentForm.markAsDirty();
            this.offersService.documents.push(documentForm);
          }
        }
      } else {
        if (!this.offersService.documents.value.find((d: DocumentInterface) => d.templateId === template?._id && d.status !== 'denied')) {
          const documentForm = this.offersService.createDocumentForm({
            templateId: template._id,
            _template: template,
            type: template.type.length > 1 ? null : template.type[0],
            deliverUntil: template.defaultDelivery ? new Date((new Date()).setDate((new Date()).getDate() + template.defaultDelivery)) : null,
            signatureUntil: template.defaultSignature ? new Date((new Date()).setDate((new Date()).getDate() + template.defaultSignature)) : null,
            isInternal: false
          });
          documentForm.markAsDirty();
          this.offersService.documents.push(documentForm);
        }
      }
      this.offersService.documentsForm.markAllAsTouched();
      this.offersService.documentsForm.markAsDirty();
    }
    this.selectTemplates(null, false, false);
  }

  public applicantIndex(contactId: string) {
    return this.offer.applicants.findIndex((cId: any) => cId === contactId);
  }

  public options(type: string): OptionInterface[] {
    return this.settingsService.options[type];
  }

  public removeDocument(index: any) {
    const removeAt = this.offersService.documents.value.findIndex(d => d._index === index);
    if (removeAt >= 0) {
      this.dialogsService.confirm('Smazat dokument', 'Chcete opravdu smazat tento dokument?').subscribe((confirm) => {
        if (confirm) {
          this.offersService.documents.removeAt(removeAt);
          this.offersService.documentsForm.markAsDirty();
        }
      });
    }
  }

  public confirmedProviders(): Provider[] {
    const confirmedModelations = this.offer.modelations.filter((m: any) => m.variants.some((v: any) => v.isConfirmed));
    let confirmedVariants = [];
    for (const modelation of confirmedModelations) {
      confirmedVariants = [...confirmedVariants, ...modelation.variants.filter((v: any) => v.isConfirmed)];
    }
    return [...confirmedVariants.map((v: any) => v.products[0].source.provider), ...confirmedVariants.map((v: any) => v.products[1]?.source.provider)].filter((provider, index, self) => provider && self.findIndex((p: Provider) => p?._id === provider._id) === index);
  }

  public trackByFn(index, item) {
    return item._id;
  }

  public trackByFnCtrl(index, control) {
    return control.value._id;
  }

  public changeFiles(event: FileListSelectEvent) {
  }

  public moveFiles(event: FileListMoveEvent) {
    this.dialogsService.fileChooseGroupDialog(
      'Vyberte kam chcete soubor přesunout',
      event.files,
      this.groups,
      this.offersService.documents,
      event.sourceGroup)
      .subscribe((result) => {
        if (result) {
          this.filesService.moveFileInDocuments(result.files, this.offersService.documents as FormArray, result.sourceGroup, result.destGroup);
          this.refresh++;
        }
      });
  }

  public refreshFiles(event: boolean) {
    this.refresh++;
  }

  protected readonly document = document;
}
