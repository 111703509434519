import {Component, OnDestroy, OnInit} from '@angular/core';
import {OffersService} from '@app/services/offers.service';
import {SettingsService} from '@app/services/settings.service';
import {UsersService} from '@app/services/users.service';
import {MainService} from '@app/services/main.service';
import {DialogsService} from '@app/services/dialogs.service';
import {AffiliatesService} from '@app/services/affiliates.service';
import {Subscription} from 'rxjs';
import {DealsService} from '@app/services/deals.service';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {SnackbarService} from '@app/services/snackbar.service';
import {Deal} from '@app/models/deal.model';
import {Affiliate} from '@app/models/affiliate.model';
import {ActivatedRoute} from '@angular/router';
import {PipelinesService} from '@app/services/pipelines.service';
import {ProvidersService} from '@app/services/providers.service';

@Component({
  selector: 'app-offer-commissions',
  templateUrl: './offer-commissions.component.html',
  styleUrls: ['./offer-commissions.component.scss']
})

export class OfferCommissionsComponent implements OnInit, OnDestroy {

  public deal: Deal = null;
  private subs$: Subscription[] = [];
  private save$: Subscription;
  public form?: FormGroup;
  public affiliate: Affiliate = null;
  public contractNumbers: string[] = [];
  public entitlementsEnabled = false;
  public commissionsEnabled = false;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public mainService: MainService,
    public settingsService: SettingsService,
    private dialogsService: DialogsService,
    public dealsService: DealsService,
    private snackbarService: SnackbarService,
    public usersService: UsersService,
    public pipelinesService: PipelinesService,
    public providersService: ProvidersService,
    private affiliatesService: AffiliatesService,
    public offersService: OffersService
  ) {
    this.offersService.view = 'commissions';
  }

  public canDeactivate() {
    return new Promise( (resolve) => {
      if (this.form.pristine) {
        resolve(true);
      } else {
        this.dialogsService.deactivate().subscribe((confirm) => {
          if (!confirm) {
            this.mainService.hideLoading();
          }
          resolve(confirm);
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.subs$.forEach((sub) => sub.unsubscribe());
    this.subs$ = [];
    if (this.save$) {
      this.save$.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.entitlementsEnabled = this.usersService.isPrivileged('cases/commissions:patch');
    this.commissionsEnabled = this.usersService.isPrivileged('cases/commissions:patch');

    this.subs$.push(this.dealsService.deal$.subscribe(async (deal: Deal) => {
      this.deal = deal;
      this.createForm(this.deal);
      this.mainService.hideLoading();
      this.contractNumbers = this.deal._offers.flatMap(offer =>
        offer.modelations.flatMap(modelation =>
          modelation.variants
            .filter(variant => variant.isFinal)
            .flatMap(variant =>
              variant.products.map(product => product.contractNumber)
            )
        )
      );
      if (this.deal.affiliateId) {
        this.affiliate = await this.affiliatesService.get(this.deal.affiliateId);
      }
    }));
    this.save$ = this.offersService.save$.subscribe((type: string) => {
      if (type === 'commissions') {
        this.saveCommissions();
      }
    });
  }

  private createForm(data?: Deal) {
    this.form = this.fb.group({
      commissions: this.fb.array([]),
      commissionsEntitlements: this.fb.array([])
    });
    if (data) {
      if (!Array.isArray(data.commissions)) {
        data.commissions = [];
      }
      this.form.patchValue(data);
      for (const commission of data.commissions || []) {
        (this.form.get('commissions') as FormArray).push(this.createCommissionsForm(commission));
      }
      for (const entitlement of data.commissionsEntitlements || []) {
        (this.form.get('commissionsEntitlements') as FormArray).push(this.createEntitlementForm(entitlement));
      }
    }
    this.offersService.commissionsForm = this.form;
  }

  private createCommissionsForm(data?: any) {
    const form = this.fb.group({
      contractNumber: [null],
      amount: [0],
      type: ['original'],
      settleAt: [null],
      settledAt: [null],
      canceledAt: [null],
      isCancellation: [false],
      note: [null],
      recipients: this.fb.array([])
    });
    if (data) {
      form.patchValue(data);
      for (const recipient of data.recipients || []) {
        (form.get('recipients') as FormArray).push(this.createRecipientForm(form, recipient));
      }
    }
    form.get('amount').valueChanges.subscribe((value) => {
      (form.get('recipients') as FormArray).controls.forEach((control) => {
        control.get('commissionShare').updateValueAndValidity();
      });
    });
    return form;
  }

  private createRecipientForm(parent: any, data?: any) {
    const form = this.fb.group({
      userId: [null],
      affiliateId: [null],
      paymentAt: [null],
      isPaid: [false],
      note: [null],
      commissionShare: [0],
      amount: [0]
    });
    if (data) {
      form.patchValue(data);
    }
    form.get('commissionShare').valueChanges.subscribe((value) => {
      form.get('amount').patchValue(Math.round(value / 100 * this.mainService.unmaskCurrency(parent.get('amount').value)));
    });
    form.get('userId').valueChanges.subscribe((value) => {
      const entitlement = this.form.value.commissionsEntitlements.find(e => e.userId === value);
      if (entitlement) {
        form.get('commissionShare').patchValue(entitlement.commissionShare);
      }
    });
    if (!this.commissionsEnabled) {
      form.disable();
    }
    return form;
  }

  private createEntitlementForm(data?: any) {
    const form = this.fb.group({
      userId: [null],
      commissionShare: [0],
      note: [null]
    });
    if (data) {
      form.patchValue(data);
    }
    if (!this.entitlementsEnabled) {
      form.disable();
    }
    return form;
  }

  unmask(data) {
    for (const commission of data.commissions) {
      commission.amount = this.mainService.unmaskCurrency(commission.amount);
      for (const recipient of commission.recipients) {
        recipient.amount = this.mainService.unmaskCurrency(recipient.amount);
      }
    }
    return data;
  }

  public async saveCommissions() {
    if (this.form.valid) {
      this.mainService.showLoading();
      const data = this.unmask(this.form.getRawValue());
      await this.dealsService.patch(this.deal._id, data);
      this.snackbarService.showSuccess('Úspěšně uloženo.');
      return true;
    } else {
      this.snackbarService.showError('Chyba v provizích.');
      return false;
    }
  }

  public async addCommission() {
    const recipients = [];
    if (this.form.get('commissionsEntitlements').value.length) {
      for (const entitlement of this.form.get('commissionsEntitlements').value) {
        recipients.push({userId: entitlement.userId, commissionShare: entitlement.commissionShare});
      }
    }
    (this.form.get('commissions') as FormArray).push(this.createCommissionsForm({recipients}));
    this.form.get('commissions').markAsDirty();
  }

  public async addEntitlement() {
    (this.form.get('commissionsEntitlements') as FormArray).push(this.createEntitlementForm({userId: this.usersService.user._id}));
    this.form.get('commissionsEntitlements').markAsDirty();
  }

  public async addRecipient(control) {
    (control.get('recipients') as FormArray).push(this.createRecipientForm(control));
    control.get('recipients') .markAsDirty();
  }

  public async removeEntitlement(eIndex: number) {
    this.dialogsService.confirm('Odebrat nárok' , 'Chcete opravdu odebrat tento nárok?').subscribe(async (confirm) => {
      if (confirm) {
        (this.form.get('commissionsEntitlements') as FormArray).removeAt(eIndex);
        this.form.get('commissionsEntitlements').markAsDirty();
      }
    });
  }
  public async removeCommission(cIndex: number) {
    this.dialogsService.confirm('Odebrat provizi / storno' , 'Chcete opravdu odebrat tuto provizi / storno?').subscribe(async (confirm) => {
      if (confirm) {
        (this.form.get('commissions') as FormArray).removeAt(cIndex);
        this.form.get('commissions').markAsDirty();
      }
    });
  }

  public async removeRecipient(control: any, rIndex: number) {
    this.dialogsService.confirm('Odebrat příjemce' , 'Chcete opravdu odebrat tohoto příjemce?').subscribe(async (confirm) => {
      if (confirm) {
        (control.get('recipients') as FormArray).removeAt(rIndex);
        control.get('recipients').markAsDirty();
      }
    });
  }

  public findUser(control: any) {
    if (this.commissionsEnabled) {
      this.dialogsService.findUser().subscribe((event: any) => {
        if (event?.option?.value) {
          control.get('userId').patchValue(event?.option?.value);
          control.get('userId').markAsDirty();
        }
      });
    }
  }
}
