import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UsersService } from '@app/services/users.service';
import { UserGroupsService } from '@app/services/user-groups.service';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent implements OnChanges {
  @Input() id: string;

  public name: string;
  public imageUrl?: string;
  public icon?: string;

  constructor(
    public usersService: UsersService,
    public userGroupsService: UserGroupsService
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    const g = this.userGroupsService.getGroupInfo(this.id);
    if (g) {
      this.name = g.name;
      this.icon = 'groups';
      this.imageUrl = null;
    } else {
      const r = this.usersService.getUserInfo(this.id);
      if (r) {
        this.name = (r.firstName ?? '') + ' ' + (r.lastName ?? '');
        this.imageUrl = this.usersService.getAvatar(r);
        this.icon = null;
      } else {
        this.name = '???';
        this.imageUrl = null;
        this.icon = 'question_mark';
      }
    }
  }
}
