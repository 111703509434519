import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Task } from '@app/models/task.model';
import { TasksService } from '@app/services/tasks.service';
import { UsersService } from '@app/services/users.service';
import { UserGroupsService } from '@app/services/user-groups.service';
import { DialNumberParam } from '@app/models/calls.model';
import { CallsService } from '@app/services/calls.service';
import { MainService } from '@app/services/main.service';
import { AffiliatesService } from '@app/services/affiliates.service';
import { TasksListOptions } from '@app/components/tasks-list/tasks-list.interface';
import { NotesService } from '@app/services/notes.service';
import { Note } from '@app/models/note.model';
import { Inplace } from 'primeng/inplace';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogsService } from '@app/services/dialogs.service';
import { DealsService } from '@app/services/deals.service';
import { CasesService } from '@app/services/cases.service';
import Quill from 'quill';

@Component({
  selector: 'app-tasks-list-item',
  templateUrl: './tasks-list-item.component.html',
  styleUrls: ['./tasks-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TasksListItemComponent implements OnChanges, AfterViewInit {
  @Input() task: Task;
  @Input() options: TasksListOptions;
  @Input() first = false;
  @Output() readonly moveTask: EventEmitter<Task> = new EventEmitter();
  @Output() readonly noteChange: EventEmitter<{ task: Task; note: Note }> = new EventEmitter<{
    task: Task;
    note: Note;
  }>();
  @Output() readonly taskComplete: EventEmitter<Task> = new EventEmitter();
  @ViewChild('htmlNote', { static: false }) htmlNote: ElementRef;
  @ViewChild('inpTitle', { static: false }) inpTitle: Inplace;
  @ViewChild('inpText', { static: false }) inpText: Inplace;
  @ViewChild('inpNote', { static: false }) inpNote: Inplace;

  public assignedAvatar: string;
  public assignedName: string;
  public assignedIcon: string;
  public isReadOnly: boolean;
  public ngClass: any;
  public noteDisplay = false;
  public noteLabel = '';
  public quill: Quill;
  public noteUserId?: string;
  public noteReadOnly: boolean;

  constructor(
    public mainService: MainService,
    public tasksService: TasksService,
    public usersService: UsersService,
    private userGroupsService: UserGroupsService,
    public affiliatesService: AffiliatesService,
    private changeDetectorRef: ChangeDetectorRef,
    private callsService: CallsService,
    private dealsService: DealsService,
    private casesService: CasesService,
    private notesService: NotesService,
    private dialogsService: DialogsService,
    private snackBar: MatSnackBar,
    private cd: ChangeDetectorRef,
  ) {}

  public ngAfterViewInit(): void {
    this.scrollNote();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.isReadOnly =
      !!this.task.completedAt ||
      !(
        [this.usersService.user._id, this.usersService.user.groupId].includes(this.task.assignedToId) ||
        this.usersService.isPrivileged('tasks/all')
      );
    this.changeStyle();

    this.assignedName = '';
    this.assignedAvatar = null;
    this.assignedIcon = null;
    this.noteUserId = null;
    this.noteReadOnly = true;

    const assignedTo = this.task.assignedToId;
    if (assignedTo) {
      const g = this.userGroupsService.getGroupInfo(assignedTo);
      if (g) {
        this.assignedName = g.name;
        this.assignedIcon = 'groups';
        this.noteUserId = assignedTo;
        if (this.usersService.user.groupId === assignedTo) {
          this.noteReadOnly = false;
        }
        this.noteLabel = `Poznámka k případu skupiny ${this.assignedName} ${this.noteReadOnly ? ' - jen čtení' : ''}`;
      } else {
        const r = this.usersService.getUserInfo(assignedTo);
        if (r) {
          this.assignedName = (r.firstName ?? '') + ' ' + (r.lastName ?? '');
          this.assignedAvatar = this.usersService.getAvatar(r);
          this.noteUserId = assignedTo;
          if (this.usersService.user._id === assignedTo) {
            this.noteReadOnly = false;
          }
          this.noteLabel = `Poznámka k případu uživatele ${this.assignedName} ${this.noteReadOnly ? ' - jen čtení' : ''}`;
        }
      }
    }
    this.updateNotes();
  }

  public updateNotes(): void {
    const index = this.findNote(this.noteUserId);
    if (index !== null) {
      this.task.note = this.task._notes[index].text;
    } else {
      this.task.note = '';
    }
    this.task.tmpNote = this.task.note;
  }

  public changeStyle(): void {
    this.ngClass = {
      'task-pinned': this.task.isPinned,
      'bg-white': !this.task.color && !this.task.completedAt,
      'bg-gray-50': !this.task.color && this.task.completedAt,
      ['bg-light-' + this.task.color]: this.task.color && !this.task.completedAt,
      ['bg-ultra-light-' + this.task.color]: this.task.color && this.task.completedAt,
      'task-completed': this.task.completedAt,
      'task-pinned-start': this.task.state === 'pinned',
      'task-normal-start': this.task.state === 'normal',
      'task-completed-start': this.task.state === 'completed',
    };
  }

  public changeColor(color: string | null): void {
    this.task.color = color;
    this.changeStyle();
    this.tasksService.changeColor(this.task, color);
  }

  public changePriority(priority: number) {
    this.tasksService.changePriority(this.task, priority);
  }

  public async changePin(task: Task, pinned: boolean) {
    task.state = pinned ? 'pinned' : 'normal';
    await this.tasksService.pinTask(task, pinned);
    this.moveTask.emit(task);
  }

  public async saveNote(event: string, inplace?: Inplace) {
    if (inplace) {
      inplace.deactivate();
    }
    this.mainService.isLoading = true;
    try {
      const note: Note = {
        userId: this.noteUserId,
        dealId: this.task.dealId,
        text: event,
      };
      const index = this.findNote(this.noteUserId);
      let result: Note;
      if (!this.task._notes) {
        this.task._notes = [];
      }
      if (index === null) {
        result = await this.notesService.create(note);
        this.task._notes.push(result);
      } else {
        result = await this.notesService.patch(this.task._notes[index]._id, note);
        this.task._notes[index] = result;
      }
      this.task.note = event;
      this.task.tmpNote = undefined;
      this.noteChange.emit({ task: this.task, note: result });
      this.changeDetectorRef.detectChanges();
      this.scrollNote();
    } finally {
      this.mainService.isLoading = false;
    }
  }

  public async dialPhone(event: any, task: Task) {
    if (task._pipeline.url === 'mortgage') {
      const deal = await this.dealsService.get(task.dealId);
      const param: DialNumberParam = {
        countryCode: event.countryCode,
        number: event.phoneNumber,
        contactName: event.contactName,
        contactId: deal._offers[deal._offers.length - 1].applicants[0],
        origin: 'DealTasksComponent',
        dealId: deal._id,
        pipelineId: task.pipelineId,
        dealOwnerId: deal.ownerId,
        stage: deal.stage,
      };
      await this.callsService.initiateCall(param);
    } else {
      const deal = await this.casesService.getCaseByPipelineId(task.dealId, task.pipelineId);
      const param: DialNumberParam = {
        countryCode: event.countryCode,
        number: event.phoneNumber,
        contactName: event.contactName,
        contactId: deal._contacts[0]?.contactId,
        origin: 'DealTasksComponent',
        dealId: deal._id,
        pipelineId: task.pipelineId,
        dealOwnerId: deal.ownerId,
        stage: deal.stage,
      };
      await this.callsService.initiateCall(param);
    }
  }

  public findNote(userId: string): number | null {
    if (this.task._notes) {
      const index = this.task._notes.findIndex(item => item.userId === userId);
      if (index !== -1) {
        return index;
      }
    }
    return null;
  }

  public animationEnd(event: AnimationEvent): void {
    this.task.state = '';
    this.changeStyle();
  }

  public scrollNote(): void {
    if (this.htmlNote?.nativeElement) {
      this.htmlNote.nativeElement.scrollTop = this.htmlNote.nativeElement.scrollHeight;
    }
  }

  public titleEditing(): void {
    this.task.tmpTitle = this.task.title;
  }

  public saveTitle(inplace: Inplace, title: string): void {
    inplace.deactivate();
    this.task.tmpTitle = undefined;
    this.tasksService.changeTitle(this.task, title);
  }

  public discardTitle(inplace: Inplace): void {
    if (inplace) {
      inplace.deactivate();
    }
    this.task.tmpTitle = undefined;
  }

  public textEditing(): void {
    this.task.tmpText = this.task.text ?? '';
  }

  public saveText(inplace: Inplace, text: string): void {
    inplace.deactivate();
    this.task.tmpText = undefined;
    this.tasksService.changeText(this.task, text);
  }

  public discardText(inplace: Inplace): void {
    if (inplace) {
      inplace.deactivate();
    }
    this.task.tmpText = undefined;
  }

  public async displayNote() {
    if (!this.noteReadOnly) {
      await this.tasksService.refreshNote(this.task.dealId);
      this.noteDisplay = true;
    }
  }

  public async inpNoteClicked() {
    if (this.noteReadOnly) {
      return;
    }
    await this.tasksService.refreshNote(this.task.dealId);
    this.updateNotes();
    this.task.tmpNote = this.task.note;
    this.cd.markForCheck();
    this.inpNote.activate();
  }

  public initEditor($event: any): void {
    this.quill = $event.editor;
    setTimeout(() => {
      this.quill.focus();
      this.quill.setSelection(this.quill.getLength(), 0);
    }, 100);
  }

  public discardNote(inplace: Inplace): void {
    if (inplace) {
      inplace.deactivate();
    }
    this.task.tmpNote = undefined;
  }

  public discardInplaceEdits(): void {
    this.discardTitle(this.inpTitle);
    this.discardText(this.inpText);
    this.discardNote(this.inpNote);
  }

  public checkEditing(): boolean {
    const isPristine = this.tasksService.isTaskPristine(this.task);
    if (isPristine === true) {
      this.discardInplaceEdits();
      return true;
    } else {
      return false;
    }
  }

  public checkTask(task: Task) {
    if (this.checkEditing()) {
      this.doCheckTask(task);
    } else {
      this.dialogsService.deactivate().subscribe((value) => {
        if (value) {
          this.doCheckTask(task);
        }
      });
    }
  }

  public async doCheckTask(task: Task) {
    if (task.completedAt) {
      await this.tasksService.restoreTask(task);
    } else {
      this.task.state = 'completed';
      this.changeStyle();
      setTimeout(async () => {
        const tempTask = {_id: task._id};
        await this.tasksService.finishTask(task);
        const snackBarRef = this.snackBar.open(
          'Úkol byl označen jako splněný.',
          'Vrátit zpět', {duration: 10000, panelClass: ['bg-green-100', 'text-black']}
        );
        snackBarRef.onAction().subscribe(() => {
          this.tasksService.restoreTask(tempTask);
        });
        this.taskComplete.emit(task);
      }, 500);
    }
  }

  public editTask(task: Task) {
    if (this.checkEditing()) {
      this.doEditTask(task);
    } else {
      this.dialogsService.deactivate().subscribe((value) => {
        if (value) {
          this.doEditTask(task);
        }
      });
    }
  }

  public async doEditTask(task: Task) {
    this.tasksService.editTask(task);
  }

  public changeOwner() {
    const users = this.usersService.items.filter(
      u => ['admin', 'operator', 'specialist', 'support', 'tester'].includes(u.role) && !u.isBlocked
    );
    this.dialogsService.chooseOwner(this.task.assignedToId, users, this.userGroupsService.items, { title: 'Zvolte vlastníka úkolu', assignToMe: true })
      .subscribe((value) => {
        if (value) {
          this.tasksService.changeAssignedTo(this.task._id, value);
        }
      });
  }

}
