import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {TimelineComponent} from '@app/components/timeline/timeline.component';
import {TimelineItemComponent} from '@app/components/timeline/timeline-item.component';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule} from '@angular/forms';
import {DateTimelinePipe} from '@app/pipes/date-timeline.pipe';
import {CoreModule} from '@app/modules/core.module';
import { TimelineIsFuturePipe } from './timeline-is-future.pipe';
import { PipesModule } from '@app/modules/pipes.module';
import { CallingWidgetModule } from '@app/components/callingwidget/callingwidget.module';
import { TimelinePermissionPipe } from '@app/components/timeline/timeline-permission-pipe';
import { MessagePreviewModule } from '@app/components/message-preview/message-preview.module';
import { EmailStatusModule } from '@app/components/email-status/email-status.module';

@NgModule({
  declarations: [
    TimelineComponent,
    TimelineItemComponent,
    DateTimelinePipe,
    TimelineIsFuturePipe,
    TimelinePermissionPipe,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    CoreModule,
    PipesModule,
    CallingWidgetModule,
    MessagePreviewModule,
    EmailStatusModule,
  ],
  exports: [TimelineComponent, TimelineItemComponent, TimelinePermissionPipe, DateTimelinePipe],
})
export class TimelineModule {}
