import { Injectable } from '@angular/core';
import {DataService} from './data.service';
import {AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {DialogsService} from '@app/services/dialogs.service';
import {MainService} from '@app/services/main.service';
import {Property} from '@app/models/property.model';

@Injectable({providedIn: 'root'})
export class PropertiesService extends DataService<Property> {

  constructor(
    private fb: FormBuilder,
    private dialogsService: DialogsService,
    private mainService: MainService
  ) {
    super('properties');
  }

  public createForm(property?, stage?, parentForm?): FormGroup {
    const form = this.fb.group({
      _id: [],
      type: [null],
      value: [null],
      housingUnitsCount: [null],
      usableArea: [null],
      commercialAreaPercentage: [null],
      cadastreRegisterSeparately: [null],
      realEstateTax: [null],
      isDeveloperProject: [null],
      isUnderConstruction: [null],
      developerProject: [null],
      housingType: [null],
      usedBy: [null],
      goodCondition: [null],
      lastApprovalAt: [null],
      lowestFloor: [null],
      isIsolated: [null],
      livingArea: [null],
      isGroundFloor: [null],
      isTopFloor: [null],
      isUpstairs: [null],
      hasMoreFloors: [null],
      isAtypical: [null],
      floodCountLast20: [null],
      floodCountLast10: [null],
      damageCountLast10: [null],
      damageCountLast5: [null],
      constructionType: [null],
      roofType: [null],
      lessThenHalfAtticForLiving: [null],
      floorsAbove: [null],
      floor1Area: [null],
      floor2Area: [null],
      totalFloorArea: [null],
      residentialAtticArea: [null],
      cellarArea: [null],
      garageValue: [null],
      isSecludedArea: [false],
      isRestricted: [true],
      restrictedFor: [null],
      note: [null],
      insured: [null],
      address: this.fb.group({
        street: [null],
        orientationNumber: [null],
        houseNumber: [null],
        zipCode: [null],
        city: [null],
        district: [null],
        region: [null], // this.mainService.requireSelectionValidator('region')],
        country: ['CZE', [this.mainService.requireSelectionValidator('country', null)]],
        cadastralTerritory: [null],
        unitNumber: [null],
        parcelNumber: [null],
        ownershipCertificate: [null],
        sameAsContact: [false],
        noAddressYet: [false]
      })
    });
    form.get('isDeveloperProject').valueChanges.subscribe((value: any) => {
      if (value) {
        form.get('developerProject').setValidators([Validators.required]);
      } else {
        form.get('developerProject').clearValidators();
      }
      form.get('developerProject').updateValueAndValidity();
    });
    form.get('type').valueChanges.subscribe((value: any) => {
      if (value === 'apartmentBuilding') {
        form.get('housingUnitsCount').setValidators([Validators.required]);
        form.get('commercialAreaPercentage').setValidators([Validators.required, Validators.max(100), Validators.min(0)]);
        form.get('cadastreRegisterSeparately').setValidators([Validators.required]);
      } else {
        form.get('housingUnitsCount').clearValidators();
        form.get('commercialAreaPercentage').clearValidators();
        form.get('cadastreRegisterSeparately').clearValidators();
      }
      form.get('housingUnitsCount').updateValueAndValidity();
      form.get('commercialAreaPercentage').updateValueAndValidity();
      form.get('cadastreRegisterSeparately').updateValueAndValidity();
    });
    if (property) {
      form.patchValue(property);
      form.get('commercialAreaPercentage').patchValue(this.mainService.maskPercent(form.get('commercialAreaPercentage').value));
    }
    const propertyId = form.get('_id').value;
    if (parentForm && parentForm.get('properties')) {
      const propertyParent = parentForm.get('properties').controls.find(p => p.get('_id').value === propertyId);
      if (propertyParent) {
        this.updatePropertyValidators(form, propertyParent.get('status')?.value);
        propertyParent.get('status')?.valueChanges.subscribe((value: any) => {
          this.updatePropertyValidators(form, value);
        });
      }
    }
    this.propertyAddressUpdateValidator(form, stage, parentForm);
    return form;
  }

  public propertyAddressUpdateValidator(control, stage?, parentForm?) {
    // Povinně adresu všech nemovitostí kromě adresy zastavované nemovitosti, pokud neni zaškrtnuto, že má konkrétní nemovitosdt.
    const parentPropertyForm = parentForm?.get('properties')?.controls.find(p => p.get('_id').value === control.get('_id').value);
    if (this.mainService.isStageBetween(stage, 'applicationDocuments') && !(parentPropertyForm?.get('status').value.includes('pledged') && !parentForm.get('hasSpecificProperty').value)) {
      control.get('address.street').setValidators([Validators.required]);
      control.get('address.houseNumber').setValidators([Validators.required]);
      control.get('address.zipCode').setValidators([Validators.required]);
      control.get('address.city').setValidators([Validators.required]);
      control.get('address.country').setValidators([Validators.required, this.mainService.requireSelectionValidator('country', null)]);
    } else {
      control.get('address.street').clearValidators();
      control.get('address.houseNumber').clearValidators();
      control.get('address.zipCode').clearValidators();
      control.get('address.city').clearValidators();
      control.get('address.country').clearValidators();
      control.get('address.country').setValidators([this.mainService.requireSelectionValidator('country', null)]);
    }
    control.get('address.street').updateValueAndValidity();
    control.get('address.houseNumber').updateValueAndValidity();
    control.get('address.zipCode').updateValueAndValidity();
    control.get('address.city').updateValueAndValidity();
    control.get('address.country').updateValueAndValidity();
  }

  public updatePropertyValidators(form, propertyStatusValue): void {
    // Pokud je typ nemovitosti none - nevstupuje do úvěru, nejsou třeba validace na typ a hodnotu nemovitosti
    if (propertyStatusValue && propertyStatusValue.includes('none')) {
      form.get('type').clearValidators();
      form.get('value').clearValidators();
    } else {
      form.get('type').setValidators([Validators.required]);
      form.get('value').setValidators([Validators.required, Validators.min(1)]);
    }
    form.get('type').updateValueAndValidity();
    form.get('value').updateValueAndValidity();
  }

  public unmask(property) {
    property.value = this.mainService.unmaskCurrency(property.value);
    property.commercialAreaPercentage = this.mainService.unmaskPercent(property.commercialAreaPercentage);
    return property;
  }
}
