import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@app/modules/core.module';
import { AvatarComponent } from '@app/components/avatar/avatar.component';

@NgModule({
  declarations: [AvatarComponent],
  imports: [
    CommonModule,
    CoreModule,
  ],
  exports: [AvatarComponent],
})
export class AvatarModule {}
