import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CallingWidgetComponent} from '@app/components/callingwidget/callingwidget.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTabsModule} from '@angular/material/tabs';
import { HistoryComponent } from './history/history.component';
import { ContactsComponent } from './contacts/contacts.component';
import {MatListModule} from '@angular/material/list';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatBadgeModule} from '@angular/material/badge';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {InfiniteScrollComponent} from '@app/components/callingwidget/infinite-scroll.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DialButtonModule} from '@app/components/dialbutton/dial-button.module';
import {MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {CoreModule} from '@app/modules/core.module';
import {IncomingComponent} from '@app/components/callingwidget/incoming/incoming.component';
import {ContactNamePipe} from '@app/pipes/contact-name.pipe';
import {SmsComponent} from '@app/components/callingwidget/sms/sms.component';
import {ChatItemDirective} from '@app/components/callingwidget/sms/chat-item.directive';
import {CasePostponeReasonLabelPipe} from '@app/pipes/case-postpone-reason-label.pipe';
import {CaseCancelReasonLabelPipe} from '@app/pipes/case-cancel-reason-label.pipe';
import {CallsContactNamePipe} from '@app/pipes/calls-contact-name.pipe';
import {PipesModule} from '@app/modules/pipes.module';
import { CallsPhoneNumberPipe } from '@app/pipes/calls-phone-number.pipe';
import { CallIconComponent } from '@app/components/callingwidget/call-icon/call-icon.component';

@NgModule({
  declarations: [
    CallingWidgetComponent,
    HistoryComponent,
    ContactsComponent,
    IncomingComponent,
    SmsComponent,
    InfiniteScrollComponent,
    ChatItemDirective,
    CallsContactNamePipe,
    CaseCancelReasonLabelPipe,
    CasePostponeReasonLabelPipe,
    CallsPhoneNumberPipe,
    CallIconComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTabsModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    MatBadgeModule,
    ScrollingModule,
    FormsModule,
    DialButtonModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    CoreModule,
    PipesModule,
  ],
  providers: [ContactNamePipe, CallsPhoneNumberPipe],
  exports: [CallingWidgetComponent, CallsPhoneNumberPipe],
})
export class CallingWidgetModule {}
