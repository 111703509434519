import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Task } from '../models/task.model';
import { UsersService } from './users.service';
import { Router } from '@angular/router';
import { TasksService } from '@app/services/tasks.service';

export interface IncompleteOptions {
  assignedToId?: string;
  hideOverdued?: boolean;
  due?: string;
}

@Injectable({providedIn: 'root'})
export class IncompleteTasksService extends DataService<Task> {

  private options: IncompleteOptions = {};

  public totalCounts = '';
  public taskCount = [
    { type: 0, count: 0, name: 'Splnit dnes', due1: 'yesterday', due2: 'today', klass: 'bg-black text-white' },
    { type: 1, count: 0, name: 'Zítra', due1: 'today', due2: 'tomorrow', klass: 'bg-solid-red text-white' },
    { type: 2, count: 0, name: 'Do konce týdne', due1: 'yesterday', due2: 'week', klass: 'bg-solid-orange text-white' }, // tomorrow
    { type: 3, count: 0, name: 'Do konce měsíce', due1: 'yesterday', due2: 'month', klass: 'bg-solid-green text-white' }, // week
    { type: 4, count: 0, name: 'Po termínu', day1: -1, day2: -1, klass: 'bg-solid-gray text-white' },
  ];

  constructor(
    private usersService: UsersService,
    private tasksService: TasksService,
    private router: Router
  ) {
    super('tasks');
    this.pagination = 0;

    const date1 = new Date();
    date1.setHours(0, 0, 0);

    const date2 = new Date();
    date2.setHours(23, 59, 59);

    this.setOptions({ assignedToId: this.usersService.user._id, hideOverdued: false });

    this.query = {
      $or: [ { startAt: { $lte: Date.now() }}, { startAt: null } ],
      completedAt: null,
      deletedAt: {$exists: false},
      dueAt: {$gte: date1, $lt: date2},
      $limit: 0,
    };
  }

  public setOptions(options: IncompleteOptions): boolean {
    let change = false;
    if (options.assignedToId !== this.options.assignedToId) {
      this.options.assignedToId = options.assignedToId === '' ? null : options.assignedToId;
      this.options.assignedToId = options.assignedToId;
      change = true;
    }
    if (options.hideOverdued !== this.options.hideOverdued) {
      this.options.hideOverdued = options.hideOverdued;
      change = true;
    }
    if (options.due !== this.options.due) {
      this.options.due = options.due;
      change = true;
    }
    if (change) {
      for (const item of this.taskCount) {
        item.count = 0;
      }
    }
    return change;
  }

  public async loadCounts(): Promise<boolean> {
    const tmp: string[] = [];
    this.filter = {
      assignedToId: {$in: [this.usersService.user._id]}
    };
    if (this.usersService.user.groupId) {
      this.filter.assignedToId.$in.push(this.usersService.user.groupId);
    }
    this.load(0);

    if (this.router.url === '/tasks') {
      for (const item of this.taskCount) {
        item.count = 0;
      }
      for (const item of this.taskCount) {
        if (item.due1 && item.due2) {
          const date1 = this.tasksService.getDueDate(item.due1);
          date1.setDate(date1.getDate() + 1);
          if (!((item.due1 === 'yesterday') && (item.due2 === 'today') && (this.options.due !== 'justtoday') && (this.options.hideOverdued))) {
            date1.setHours(0, 0, 0);
          }
          const date2 = this.tasksService.getDueDate(item.due2);
          date2.setDate(date2.getDate() + 1);
          date2.setHours(0, 0, 0);
          this.filter = {
            dueAt: {
              $gte: date1,
              $lt: date2,
            }
          };
        } else if (item.day1 === -1 && item.day2 === -1) {
          const date = new Date();
          date.setHours(0, 0, 0);
          this.filter = {
            dueAt: {$lt: date},
          };
        } else {
          const date1 = new Date();
          date1.setHours(0, 0, 0);
          date1.setDate(date1.getDate() + item.day1);

          const date2 = new Date();
          date2.setHours(23, 59, 59);
          date2.setDate(date2.getDate() + item.day2);
          this.filter = {
            dueAt: {$gte: date1.toUTCString(), $lt: date2.toUTCString()}
          };
        }
        if (this.options.assignedToId) {
          this.filter.assignedToId = {$in: [this.options.assignedToId]};
        }
        if ((this.usersService.user.groupId) && (this.usersService.user._id === this.options.assignedToId)) {
          this.filter.assignedToId.$in.push(this.usersService.user.groupId);
        }
        const query = this.buildQuery();
        const res = await this.find({query});
        item.count = res.total;
        tmp.push(`${item.name}: ${item.count}`);
      }
      this.totalCounts = tmp.join(', \n');
    }

    return true;
  }

  protected created(item: Task): void {
    this.loadCounts();
  }

  protected updated(item: Task): void {
    this.loadCounts();
  }

  protected removed(item: Task): void {
    this.loadCounts();
  }
}
