import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@app/modules/core.module';
import { MessagePreviewComponent } from '@app/components/message-preview/message-preview.component';
import { SidebarModule } from 'primeng/sidebar';
import { EmailStatusModule } from '@app/components/email-status/email-status.module';

@NgModule({
  declarations: [MessagePreviewComponent],
  imports: [CommonModule, CoreModule, SidebarModule, EmailStatusModule],
  exports: [MessagePreviewComponent],
})
export class MessagePreviewModule {}
