<h2 mat-dialog-title><span>Nová hypotéka</span></h2>
<form #newDealForm="ngForm">
  <mat-dialog-content>
    <ng-container *ngIf="!existingContact">
      <div class="form-row">
        <mat-form-field>
          <mat-select placeholder="Předvolba" [(ngModel)]="newDeal.contacts.phoneCountryCode" name="contactPhoneCountryCode" (ngModelChange)="filterContacts(newDealForm.control)">
            <mat-option *ngFor="let option of options['phoneCountryCode']" [value]="option.value">{{ option.label }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <input matInput type="number" placeholder="Telefon" [(ngModel)]="newDeal.contacts.phoneNumber" name="contactPhoneNumber" required (ngModelChange)="filterContacts(newDealForm.control)"/>
        </mat-form-field>
      </div>
      <div class="form-row">
        <mat-form-field>
          <input matInput placeholder="Email" [(ngModel)]="newDeal.contacts.email" name="contactEmail" email (ngModelChange)="filterContacts(newDealForm.control)"/>
        </mat-form-field>
      </div>
      <div class="form-row" *ngIf="(filteredContacts | async).length && !contactSelectionDone">
        <mat-form-field>
          <mat-select placeholder="Existující kontakty" [(ngModel)]="selectedContact" name="existingContacts">
            <ng-container *ngFor="let contact of filteredContacts | async">
              <mat-option [value]="contact._id">
                <div class="flex flex-col">
                        <span style="line-height: 1.5em;">{{contact.label}}&nbsp;
                        </span>
                  <small style="line-height: 1.5em;">{{contact.sublabel}}
                  </small>
                </div>
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>
    <ng-container *ngIf="existingContact || contactSelectionDone">
      <div class="form-row" *ngIf="!existingContact">
        <mat-form-field>
          <input matInput placeholder="Jméno" [(ngModel)]="newDeal.contacts.name" name="contactName" required/>
        </mat-form-field>
        <mat-form-field>
          <app-input-mask placeholder="PSČ" [(ngModel)]="newDeal.contacts.zipCode" name="contactZipCode" mask="999 99" inputmode="numeric"></app-input-mask>
        </mat-form-field>
      </div>
      <div class="form-row">
        <mat-form-field>
          <mat-select placeholder="Vlastník případu" [(ngModel)]="newDeal.ownerId" name="ownerId">
            <mat-option [value]="''">Přiřadit automaticky</mat-option>
            <mat-option [value]="owner._id" *ngFor="let owner of owners">{{ owner.firstName }} {{ owner.lastName }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-select placeholder="Typ" [(ngModel)]="newDeal.type" name="type" (ngModelChange)="onTypeChange($event)">
            <mat-option [value]="'new'">Nová hypotéka</mat-option>
            <mat-option [value]="'refinancing'">Refinancování</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <ng-container *ngIf="newDeal.type === 'new'">
        <div class="form-row">
          <mat-form-field>
            <app-input-number placeholder="Výše úvěru" [(ngModel)]="newDeal.amount" name="amount" required></app-input-number>
          </mat-form-field>
          <mat-form-field>
            <app-input-number placeholder="Hodnota nemovitosti" [(ngModel)]="newDeal.value" name="value" required></app-input-number>
          </mat-form-field>
        </div>
        <div class="form-row">
          <mat-form-field class="maturity">
            <input matInput type="number" placeholder="Doba splácení" [(ngModel)]="newDeal.maturityYears" (ngModelChange)="updateMaturity()" name="maturityYears"/><span>r</span>
            <span class="space">, </span>
            <input matInput type="number" [(ngModel)]="newDeal.maturityMonths" (click)="$event.stopPropagation()" #maturityMonths (ngModelChange)="updateMaturity()" name="maturityMonths" /><span (click)="maturityMonths.focus();$event.stopPropagation()">m</span>
          </mat-form-field>
          <mat-form-field>
            <app-input-number placeholder="Fixace (roky)" [(ngModel)]="newDeal.fixation" name="fixation" required></app-input-number>
          </mat-form-field>
        </div>
      </ng-container>
      <ng-container *ngIf="newDeal.type === 'refinancing'">
        <div class="form-row">
          <mat-form-field>
            <mat-select placeholder="Banka" [(ngModel)]="newDeal.refinancedProduct.bankCode" name="refinancedProductBankCode" required>
              <mat-option [value]="option.value" *ngFor="let option of options['bank']">{{ option.label }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <app-input-number placeholder="Úroková sazba (%)" [(ngModel)]="newDeal.refinancedProduct.interestRate" name="refinancedProductInterestRate" required step=".01" minFractionDigits="2"></app-input-number>
          </mat-form-field>
        </div>
        <div class="form-row">
          <mat-form-field>
            <app-input-number placeholder="Výše úvěru" [(ngModel)]="newDeal.refinancedProduct.amount" name="refinancedProductAmount" required></app-input-number>
          </mat-form-field>
          <mat-form-field>
            <app-input-number placeholder="Splátka" [(ngModel)]="newDeal.refinancedProduct.repayment" name="refinancedProductRepayment" required></app-input-number>
          </mat-form-field>
          <mat-form-field>
            <input matInput placeholder="Datum podpisu" [matDatepicker]="signatureDate" [(ngModel)]="newDeal.refinancedProduct.signatureDate" name="refinancedProductSignatureDate" required />
            <mat-datepicker-toggle matSuffix [for]="signatureDate"></mat-datepicker-toggle>
            <mat-datepicker #signatureDate></mat-datepicker>
          </mat-form-field>
        </div>
      </ng-container>
      <ng-container *ngIf="deal?._offers?.length">
        <div class="form-row">
          <mat-form-field>
            <mat-select placeholder="Z nabídky" [(ngModel)]="newDeal.copyOfferId" name="copyOfferId">
              <mat-option [value]="offer._id" *ngFor="let offer of deal._offers">N{{ offer.numberId }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </ng-container>
      <div class="form-row">
        <mat-form-field *ngIf="newDeal.type === 'refinancing'">
          <app-input-number placeholder="Fixace (roky)" [(ngModel)]="newDeal.refinancedProduct.fixation" name="refinancedProductFixation" required></app-input-number>
        </mat-form-field>
        <mat-form-field *ngIf="newDeal.type === 'new'">
          <mat-select placeholder="Účel" [(ngModel)]="newDeal.purpose" name="purpose" required>
            <ng-container *ngFor="let option of options['purpose']">
              <mat-option [value]="option.value" *ngIf="option.value !== 'refinancing'">{{ option.label }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-row">
        <mat-form-field>
          <mat-select placeholder="Kdy potřebuje" [(ngModel)]="newDeal.timeHorizon" name="timeHorizon" required>
            <mat-option [value]="option.value" *ngFor="let option of options['timeHorizon']">{{ option.label }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <ng-container *ngIf="!deal">
        <div class="form-row">
          <mat-form-field>
            <mat-select placeholder="Původ obchodu"
                        [(ngModel)]="newDeal.sourceType"
                        (selectionChange)="sourceTypeChanged($event)"
                        name="sourceType">
              <mat-option [value]="'hnm'">Firemní klient</mat-option>
              <mat-option [value]="'hnmrecommendation'">Doporučení od firemního klienta</mat-option>
              <mat-option [value]="'affiliate'">Affiliate partner</mat-option>
              <mat-option [value]="'personal'">Osobní</mat-option>
              <mat-option [value]="'employee'">Hypo zaměstnanec</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="newDeal.sourceType === 'affiliate'">
            <mat-select placeholder="Partner" [(ngModel)]="newDeal.affiliateId" name="affiliateId" required>
              <mat-option [value]="''">Není vybrán</mat-option>
              <mat-option [value]="affiliate._id" *ngFor="let affiliate of affiliatesService.items$ | async">{{ affiliate.name }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="['hnmrecommendation', 'personal', 'employee'].includes(newDeal.sourceType)">
            <mat-select placeholder="Tipař" [(ngModel)]="newDeal.tipsterId" name="tipsterId" (selectionChange)="tipsterChanged($event)" required>
              <mat-option [value]="tipster._id" *ngFor="let tipster of tipsters">{{ tipster.firstName }} {{ tipster.lastName }}</mat-option>
            </mat-select>
<!--            <input matInput placeholder="Email tipaře"-->
<!--                   [(ngModel)]="newDeal.authorEmail"-->
<!--                   [matAutocomplete]="autoUser"-->
<!--                   (ngModelChange)="filterUsers($event)"-->
<!--                   name="tipsterEmail" required/>-->
<!--            <mat-autocomplete #autoUser="matAutocomplete" panelWidth="auto">-->
<!--              <mat-option *ngFor="let user of filteredUsers | async" [value]="user.email" (onSelectionChange)="tipsterChanged(user)">-->
<!--                {{ user.email }}-->
<!--              </mat-option>-->
<!--            </mat-autocomplete>-->
          </mat-form-field>
        </div>
        <div *ngIf="newDeal.tipsterCommission" class="flex flex-row justify-end gap-2 pb-3 text-sm">
          <div>Tipařská provize</div>
          <div class="font-semibold">{{newDeal.tipsterCommission | number}}%</div>
        </div>
        <div class="form-row">
          <mat-form-field>
          <textarea placeholder="Poznámka"
                    matInput cdkTextareaAutosize cdkAutosizeMinRows="1"
                    [(ngModel)]="newDeal.note"
                    name="note">
          </textarea>
          </mat-form-field>
        </div>
      </ng-container>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button type="button" mat-button color="warn" (click)="dialogRef.close(null)">Zrušit</button>
    <ng-container *ngIf="!existingContact && !contactSelectionDone">
      <button type="button" mat-raised-button color="accent" (click)="selectContact(true)" [disabled]="!selectedContact">Použít kontakt</button>
      <button type="button" mat-raised-button color="accent" (click)="selectContact(false)" [disabled]="newDealForm.control.get('contactPhoneNumber')?.invalid">Nový kontakt</button>
    </ng-container>
    <ng-container *ngIf="existingContact || contactSelectionDone">
      <button type="button" mat-raised-button color="primary" (click)="backToContactSelection()">Zpět</button>
      <button type="button" mat-raised-button color="accent" (click)="confirmDialog()" [disabled]="newDealForm.invalid">Vytvořit</button>
    </ng-container>
  </mat-dialog-actions>
</form>
