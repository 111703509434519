import { Pipe, PipeTransform } from '@angular/core';
import {UserInterface} from '@app/interfaces/user.interface';

@Pipe({ name: 'phoneNumber' })
export class PhoneNumberPipe implements PipeTransform {
  constructor(
  ) {}
  transform(input?: string) {
    if (!input) {
      return '';
    }
    if (!input.startsWith('+')) {
      return input;
    }
    const cleanedInput = input?.replace(/[^0-9+]/g, '');
    const countryCode = cleanedInput.match(/\+(\d+)\d{9}/)?.[1];
    const phoneNumber = cleanedInput.match(/\+\d+(\d{9})/)?.[1];
    return `+${countryCode} ${phoneNumber?.slice(0, 3)} ${phoneNumber?.slice(3, 6)} ${phoneNumber?.slice(6, 9)}`;
  }
}
